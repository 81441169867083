<template>
  <!-- Masthead -->
  <section id="masthead" class="section masthead-cover masthead-invest s-bg-light-07 background"
           data-stellar-background-ratio="0.5">
    <div class="masthead-middle">
      <div class="container">
        <div class="container-inner">
          <h2>Высокотехнологичный бизнес XXI века</h2>
          <h1 class="text-danger">Universal Trading Platform</h1>
          <h2>Инвестируйте в своё будущее</h2>
          <p>Станьте участником проекта и сразу начните зарабатывать</p>
          <div class="masthead-action">
            <a href="#section-3" class="btn btn-el btn-danger btn-scroll">Стать акционером!</a>
          </div><!-- .masthead-action -->
        </div><!-- .container-inner -->
      </div><!-- .container -->
    </div><!-- .s-table-middle -->
    <!-- <a href="#section-1" class="mouse-scroll mouse-inverse"></a> -->
    <span class="delimiter delimiter-bottom">
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 251.774 512 42.159">
			<path fill="rgba(255, 63, 58,.5)" d="M378.667,277.138c-70.456,12.097-102.683,5.783-238.667-16.391c-54.79-8.934-102.525-10.063-140-8.228v40.413h512v-32.258
				C470.233,262.663,426.317,268.957,378.667,277.138z"/>
			<path fill="#FFFFFF" d="M345.067,277.594C194.933,256.28,56.038,268.906,0,275.856v18.077h512v-4.62
				C482.921,291.471,432.465,290.002,345.067,277.594z"/>
			</svg>
		</span>
  </section>
  <!-- End Masthead -->

  <!-- Section -->
  <invest-section-1/>
  <!-- End Section -->

  <!-- Section -->
  <invest-section-2/>
  <!-- End Section -->

  <!-- Section -->
  <join-section-3/>
  <!-- End Section -->

  <!-- Section -->
  <invest-section-4/>
  <!-- End Section -->

  <!-- Section -->
  <invest-section-5/>
  <!-- End Section -->

  <!-- Section -->
  <invest-section-6/>
  <!-- End Section -->

  <!-- Section -->
  <invest-section-7/>
  <!-- End Section -->

  <!-- Section -->
  <invest-section-8/>
  <!-- End Section -->

  <!-- Section -->
  <invest-section-9/>
  <!-- End Section -->

  <!-- Section -->
  <invest-section-10/>
  <!-- End Section -->

  <!-- Section  -->
  <invest-section-11/>
  <!-- End Section -->

  <!-- Section -->
  <invest-section-12/>
  <!-- End Section -->

  <!-- Section  -->
  <invest-section-13/>
  <!-- End Section -->
</template>

<script>
import InvestSection1 from "@/components/Pages/Invest/InvestSection1";
import InvestSection2 from "@/components/Pages/Invest/InvestSection2";
import InvestSection3 from "@/components/Pages/Invest/InvestSection3";
import InvestSection5 from "@/components/Pages/Invest/InvestSection5";
import InvestSection6 from "@/components/Pages/Invest/InvestSection6";
import InvestSection7 from "@/components/Pages/Invest/InvestSection7";
import InvestSection8 from "@/components/Pages/Invest/InvestSection8";
import InvestSection9 from "@/components/Pages/Invest/InvestSection9";
import InvestSection10 from "@/components/Pages/Invest/InvestSection10";
import InvestSection11 from "@/components/Pages/Invest/InvestSection11";
import InvestSection12 from "@/components/Pages/Invest/InvestSection12";
import InvestSection13 from "@/components/Pages/Invest/InvestSection13";
import InvestSection4 from "@/components/Pages/Invest/InvestSection4";
import JoinSection3 from "@/components/Pages/Invest/JoinSection3";

export default {
  name: "join",
  components: {
    JoinSection3,
    InvestSection4,
    InvestSection13,
    InvestSection12,
    InvestSection11,
    InvestSection10,
    InvestSection9,
    InvestSection8,
    InvestSection7,
    InvestSection6,
    InvestSection5,
    InvestSection3,
    InvestSection2,
    InvestSection1
  }
}
</script>

<style scoped>
.background {
  background-image: url(../assets/images/invest/bg/bg.jpg);
}
</style>