<template>
  <section class="section s-bg-light-09 bg-cover background-bg7-jpg" id="section-last">
    <div class="container text-center">
      <h2 class="text-danger">И, это только верхушка айсберга нашего сервиса, по которой можно судить о потенциале его
        функционала. И чтобы получить более полное представление, лучше один раз увидеть и даже можно «пощупать».</h2>
      <h3>Специально подготовили демонстрационную версию сервиса:</h3>
      <p class="pt-20 pb-20"><a @click="$router.push('')" class="btn btn-danger btn-lg">Начать
        использовать
        бесплатно!</a></p>
      <div class="container-lg">
        <h4>Да, обращаем внимание на то, что многие разделы в настоящий момент скрыты, так как это наши уникальные
          разработки, не имеющие аналогов, и мы, неся ответственность перед акционерами, не имеем право публиковать
          информацию, представляющую коммерческую тайну.</h4>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "section-14"
}
</script>

<style scoped>
.background-bg7-jpg {
  background-image: url(../../../../assets/images/utp1/bg/bg7.jpg);
}
</style>