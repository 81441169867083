import {createStore} from 'vuex'
import {utm} from "./utm";
import {auth} from "./auth";
import {avatar} from "./avatar";

export default createStore({
    modules: {
        utm: utm,
        auth: auth,
        avatar: avatar
    }
})
