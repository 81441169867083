<template>
  <header-utp1>Документация</header-utp1>
  <main class="utp-main">
    <div class="utp-docs">

      <div class="utp-doc-category">
        <div class="container">
          <h2 class="utp-doc-header">
            <span class="first">Платформа</span>
            <span class="second">Акционерное общество<br> "Универсальная трейдинговая платформа"</span>
            <hr>
          </h2>

          <div class="utp-doc-list row">
            <div class="utp-doc-col col-xs-12 col-md-6 col-sm-6">
              <div class="utp-doc-item">
                <a href="https://files.utp1.com/docs/utp1.com/docs/utp1/privacy.pdf" class="utp-doc-link" target="_blank">
                  <img src="../assets/images/docs/privacy.jpg" alt="" class="img-responsive">
                  <span class="utp-doc-name">Политика конфиденциальности</span>
                </a>
              </div>
            </div>
            <div class="utp-doc-col col-xs-12 col-md-6 col-sm-6">
              <div class="utp-doc-item">
                <a href="https://files.utp1.com/docs/utp1.com/docs/utp1/agency.pdf" class="utp-doc-link" target="_blank">
                  <img src="../assets/images/docs/agency.jpg" alt="" class="img-responsive">
                  <span class="utp-doc-name">Агентский договор</span>
                </a>
              </div>
            </div>
          </div>
          <div class="utp-doc-list row">
            <div class="utp-doc-col col-xs-12 col-md-6 col-sm-6">
              <div class="utp-doc-item">
                <a href="https://files.utp1.com/docs/utp1.com/docs/utp1/sv-ogr.pdf" class="utp-doc-link" target="_blank">
                  <img src="../assets/images/docs/sv-ogr.jpg" alt="" class="img-responsive">
                  <span class="utp-doc-name">Свидетельство о государственной регистрации</span>
                </a>
              </div>
            </div>
            <div class="utp-doc-col col-xs-12 col-md-6 col-sm-6">
              <div class="utp-doc-item">
                <a href="https://files.utp1.com/docs/utp1.com/docs/utp1/decision.pdf" class="utp-doc-link" target="_blank">
                  <img src="../assets/images/docs/decision.jpg" alt="" class="img-responsive">
                  <span class="utp-doc-name">Решение ЦБ РФ о выпуске акций</span>
                </a>
              </div>
            </div>
          </div>
          <div class="utp-doc-list row">
            <div class="utp-doc-col col-xs-12 col-md-6 col-sm-6">
              <div class="utp-doc-item">
                <a href="https://files.utp1.com/docs/utp1.com/docs/utp1/eod.pdf" class="utp-doc-link" target="_blank">
                  <img src="../assets/images/docs/eod.jpeg" alt="" class="img-responsive">
                  <span class="utp-doc-name">Свидетельство на товарный знак (знак обслуживания) UTP1.com</span>
                </a>
              </div>
            </div>
            <div class="utp-doc-col col-xs-12 col-md-6 col-sm-6">
              <div class="utp-doc-item">
                <a href="https://files.utp1.com/docs/utp1.com/docs/utp1/egrul.pdf" class="utp-doc-link" target="_blank">
                  <img src="../assets/images/docs/egrul.jpg" alt="" class="img-responsive">
                  <span class="utp-doc-name">Выписка из ЕГРЮЛ</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <docs-section-1/>

      <docs-section-2/>

      <docs-section-3/>

      <docs-section-4/>

      <docs-section-5/>

    </div>
  </main>
</template>

<script>
import HeaderUtp1 from "@/components/HeaderUtp1";
import DocsSection1 from "@/components/Pages/Docs/DocsSection1";
import DocsSection2 from "@/components/Pages/Docs/DocsSection2";
import DocsSection3 from "@/components/Pages/Docs/DocsSection3";
import DocsSection4 from "@/components/Pages/Docs/DocsSection4";
import DocsSection5 from "@/components/Pages/Docs/DocsSection5";

export default {
  name: "docs",
  components: {DocsSection5, DocsSection4, DocsSection3, DocsSection2, DocsSection1, HeaderUtp1}
}
</script>

<style scoped>

</style>