<template>
  <div class="utp-doc-category">
    <div class="container">
      <h2 class="utp-doc-header">
        <span class="first">Петр Громов</span>
        <span class="second">Горшков Петр Вениаминович</span>
        <hr>
      </h2>
      <div class="utp-doc-list row">
        <div class="utp-doc-col col-xs-12 col-md-3 col-sm-6">
          <div class="utp-doc-item">
            <a href="https://files.utp1.com/docs/utp1.com/docs/gromov/ogrnip.pdf" class="utp-doc-link" target="_blank">
              <img src="../../../assets/images/docs/ogrnip.jpg" alt="" class="img-responsive">
              <span class="utp-doc-name">ОГРНИП</span>
            </a>
          </div>
        </div>
        <div class="utp-doc-col col-xs-12 col-md-3 col-sm-6">
          <div class="utp-doc-item">
            <a href="https://files.utp1.com/docs/utp1.com/docs/gromov/inn.pdf" class="utp-doc-link" target="_blank">
              <img src="../../../assets/images/docs/inn.jpg" alt="" class="img-responsive">
              <span class="utp-doc-name">ИНН</span>
            </a>
          </div>
        </div>
        <div class="utp-doc-col col-xs-12 col-md-3 col-sm-6">
          <div class="utp-doc-item">
            <a href="https://files.utp1.com/docs/utp1.com/docs/gromov/egrip.pdf" class="utp-doc-link" target="_blank">
              <img src="../../../assets/images/docs/egrip.jpg" alt="" class="img-responsive">
              <span class="utp-doc-name">Выписка из ЕГРИП</span>
            </a>
          </div>
        </div>
      </div>
      <div class="utp-doc-list row">
        <div class="utp-doc-col col-xs-12 col-md-3 col-sm-6">
          <div class="utp-doc-item">
            <a href="https://files.utp1.com/docs/utp1.com/docs/gromov/dogovor-oferty.pdf" class="utp-doc-link" target="_blank">
              <img src="../../../assets/images/docs/dogovor-oferty.jpg" alt="" class="img-responsive">
              <span class="utp-doc-name">Договор публичной Оферты</span>
            </a>
          </div>
        </div>
        <div class="utp-doc-col col-xs-12 col-md-3 col-sm-6">
          <div class="utp-doc-item">
            <a href="https://files.utp1.com/docs/utp1.com/docs/gromov/privacy.pdf" class="utp-doc-link" target="_blank">
              <img src="../../../assets/images/docs/privacy_pg.jpg" alt="" class="img-responsive">
              <span class="utp-doc-name">Политика конфиденциальности</span>
            </a>
          </div>
        </div>
        <div class="utp-doc-col col-xs-12 col-md-3 col-sm-6">
          <div class="utp-doc-item">
            <a href="https://files.utp1.com/docs/utp1.com/docs/gromov/affiliate-agreement.pdf" class="utp-doc-link" target="_blank">
              <img src="../../../assets/images/docs/affiliate-agreement.jpg" alt="" class="img-responsive">
              <span class="utp-doc-name">Партнёрское соглашение</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "docs-section-3"
}
</script>

<style scoped>

</style>