<template>
  <!-- Masthead -->
  <section id="masthead" class="section masthead-cover masthead-home">
    <div class="video-background s-bg-light-08">
      <video autoplay muted loop>
        <source src="../assets/media/typing.mp4" type="video/mp4">
      </video>
    </div>
    <div class="shapes">
      <div class="home-shape"></div>
    </div>
    <div class="masthead-middle">
      <div class="container">
        <div class="container-inner d-flex">
          <div class="masthead-left">
            <h2><strong>Лучшая ЭКО система</strong> автоматизации ВСЕХ<br> бизнес-процессов<br> в Интернете</h2>
            <div class="masthead-tags">
              <div class="masthead-tags-inner"><strong>Для:</strong> инфобизнесменов, тренеров, онлайн-школ,
                реализаторов услуг, сетевого маркетинга, интернет-магазинов, инфопродюсеров и репетиторов
              </div>
            </div>
          </div>
          <div class="masthead-right">
            <form-utp1/>
          </div>
        </div><!-- .container-inner -->
      </div><!-- .container -->
    </div><!-- .s-table-middle -->
    <span class="delimiter delimiter-bottom">
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 197.02" preserveAspectRatio="none"
           style="max-height:170px;">
				<path
            d="M1231,109.07C974.2,70.21,708.37,12,477,1.12,258.44-9.18,54.57,54.45,0,78.08V197H1920V31.1S1603.21,165.38,1231,109.07Z"
            fill="#e53935"></path>
				<path
            d="M1920,131.06s-316.79,72.31-689,16C974.2,108.19,706.37,41,475,30.1,256.44,19.81,54.57,70.55,0,94.18V197H1920Z"
            fill="#fff"></path>
			</svg>
		</span>
  </section>
  <!-- End Masthead -->
  <!-- Sections -->
  <section-1/>
  <section-2/>
  <section-3/>
  <section-4/>
  <section-5/>
  <section-6/>
  <section-7/>
  <section-8/>
  <section-9/>
  <section-10/>
  <section-11/>
  <section-12/>
  <section-13/>
  <section-14/>
  <!-- End Sections -->
</template>

<script>
import Section1 from "@/components/Pages/Home/Sections/Section1";
import Section2 from "@/components/Pages/Home/Sections/Section2";
import Section3 from "@/components/Pages/Home/Sections/Section3";
import Section4 from "@/components/Pages/Home/Sections/Section4";
import Section5 from "@/components/Pages/Home/Sections/Section5";
import Section6 from "@/components/Pages/Home/Sections/Section6";
import Section7 from "@/components/Pages/Home/Sections/Section7";
import Section8 from "@/components/Pages/Home/Sections/Section8";
import Section9 from "@/components/Pages/Home/Sections/Section9";
import Section10 from "@/components/Pages/Home/Sections/Section10";
import Section11 from "@/components/Pages/Home/Sections/Section11";
import Section12 from "@/components/Pages/Home/Sections/Section12";
import Section13 from "@/components/Pages/Home/Sections/Section13";
import Section14 from "@/components/Pages/Home/Sections/Section14";
import FormUtp1 from "@/components/UI/Form/FormUtp1";

export default {
  name: "home",
  components: {
    FormUtp1,
    Section14, Section13, Section12, Section11, Section10, Section9, Section8,
    Section7, Section6, Section5, Section4, Section3, Section2, Section1
  }
}
</script>

<style scoped>

</style>