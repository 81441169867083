<template>
  <navbar-utp1/>
  <router-view/>
  <footer-utp1/>
</template>

<style>
@import "assets/libs/bootstrap/css/bootstrap.min.css";
@import "assets/css/jquery.fancybox.min.css";
@import "assets/css/bootbiz.css";
@import "assets/css/style.css";
@import "assets/css/my.css";
</style>

<script>
import NavbarUtp1 from "@/components/NavbarUtp1";
import FooterUtp1 from "@/components/FooterUtp1";
import useUser from "@/hooks/rest/useUser";
import {onMounted} from "vue";

export default {
  components: {FooterUtp1, NavbarUtp1},
  setup() {
    const {getUser} = useUser()
    onMounted(getUser)
  }
}
</script>
