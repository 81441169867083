<template>
  <div class="modal fade utp-modal-4 in dialog-style modal-open" v-if="show" @click="modalClose">
    <div @click.stop class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <button @click="modalClose" type="button" class="close" data-dismiss="modal">
            <span>&times;</span>
          </button>
          <h3 class="modal-title text-danger">UTP1 для инфобизнесменов, продюсеров и коучей</h3>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-4 col-sm-12">
              <div class="block-icon">
                <div class="block-icon-image">
                  <img src="../../../../assets/images/utp1/ic/4_1.png" alt="">
                </div>
                <h5 class="block-icon-title">Готовая система</h5>
                <p class="block-icon-desc">Создайте лендинг и блог, настройте сбор контактов и подключите систему
                  касаний</p>
              </div>
            </div><!-- .col-xx-n -->
            <div class="col-md-4 col-sm-12">
              <div class="block-icon">
                <div class="block-icon-image">
                  <img src="../../../../assets/images/utp1/ic/4_2.png" alt="">
                </div>
                <h5 class="block-icon-title">Рассылка e-mail и SMS</h5>
                <p class="block-icon-desc">Автоматическая и разовая рассылка e-mail и SMS с доставляемостью почти
                  100%</p>
              </div>
            </div><!-- .col-xx-n -->
            <div class="col-md-4 col-sm-12">
              <div class="block-icon">
                <div class="block-icon-image">
                  <img src="../../../../assets/images/utp1/ic/4_3.png" alt="">
                </div>
                <h5 class="block-icon-title">Приём заказов</h5>
                <p class="block-icon-desc">Подключайте любые способы оплаты и пользуйтесь нашими специалистами
                  Call-центра</p>
              </div>
            </div><!-- .col-xx-n -->
          </div><!-- .row -->
          <div class="row">
            <div class="col-md-4 col-sm-12">
              <div class="block-icon">
                <div class="block-icon-image">
                  <img src="../../../../assets/images/utp1/ic/4_4.png" alt="">
                </div>
                <h5 class="block-icon-title">Курсы и тренинги</h5>
                <p class="block-icon-desc">Автоматические курсы и тренинги, проверка знаний и отправка сертификатов</p>
              </div>
            </div><!-- .col-xx-n -->
            <div class="col-md-4 col-sm-12">
              <div class="block-icon">
                <div class="block-icon-image">
                  <img src="../../../../assets/images/utp1/ic/4_5.png" alt="">
                </div>
                <h5 class="block-icon-title">Сквозная аналитика</h5>
                <p class="block-icon-desc">Зная все цифры своего бизнеса Вы сможете им эффективно управлять увеличивая
                  доходы</p>
              </div>
            </div><!-- .col-xx-n -->
            <div class="col-md-4 col-sm-12">
              <div class="block-icon">
                <div class="block-icon-image">
                  <img src="../../../../assets/images/utp1/ic/4_6.png" alt="">
                </div>
                <h5 class="block-icon-title">Партнёрская сеть</h5>
                <p class="block-icon-desc">Огромная сеть людей, которая будет рада продвигать качественные партнёрки</p>
              </div>
            </div><!-- .col-xx-n -->
          </div><!-- .row -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "modal-4",
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  setup(_, {emit}) {
    const modalClose = () => {
      emit('update:show', false)
    }
    return {modalClose}
  }
}
</script>

<style scoped>

</style>