<template>
  <section class="section s-bg-light-09 bg-cover pt-30 pb-45 background-bg1-jpg" id="section-2">
    <div class="container">
      <div class="container-el">
        <header class="section-header">
          <h2>Проблемы, которые мы сейчас видим на рынке ведения бизнеса через интернет</h2>
        </header><!-- .section-header -->
        <ul class="list-icon list-success">
          <li>техническая сложность систематизации ведения бизнеса;</li>
          <li>необходимость множества интеграций различных сервисов, которые ещё и не всегда интегрируются;</li>
          <li>оплата каждого сервиса по-отдельности и выделение крупных сумм на интеграции;</li>
          <li>большое количество постоянно повторяющейся рутинной работы, приводящей в результате к эмоциональному
            выгоранию;
          </li>
          <li>сильно упал отклик на e-mail – рассылки, в том числе письма попадают в «спам»;</li>
          <li>низкая конверсия в активацию, до 50% заинтересованной аудитории может не попасть в рассылку;</li>
          <li>невозможность гарантированной детальной сегментации при отсутствии сквозной базы («умной» базы, собирающей
            все виды контактов отдельно взятого пользователя в одну «карточку»);
          </li>
          <li>нечестность при организации взаимопиаров (как массовых, так и один-на-один) и продаже рекламы в рассылках,
            в том числе ввиду отсутствия единой системы оценки и проверки ценности базы;
          </li>
          <li>сложность создания качественных адаптивных страниц и сайтов с высокой конверсией, даже платные плагины
            генерации Landing Pages подразумевают базовые знания HTML и CSS.
          </li>
        </ul>
        <h3 class="text-center">И это только то, что лежит на самой поверхности...</h3>
        <h3 class="text-danger text-bold text-center">UTP1 – лучшая ЭКО система автоматизации ВСЕХ бизнес-процессов в
          интернете</h3>
      </div>
    </div><!-- .container -->
  </section>
</template>

<script>
export default {
  name: "section-2"
}
</script>

<style scoped>
.background-bg1-jpg {
  background-image: url(../../../../assets/images/utp1/bg/bg1.jpg);
}
</style>