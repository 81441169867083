<template>
  <section class="section section-petr-gromov-present">
    <div class="container">
      <div class="row row-flex-lg">
        <div class="col-lg-8 mb-80">
          <h2 class="header-special-xs text-center">Громов Пётр Вениаминович</h2>
          <ul class="list-icon list-circle list-danger">
            <li>Учредитель и держатель контрольного пакета акций <strong>АО «UTP1»</strong>, лучшей ЭКО системы
              автоматизации ВСЕХ бизнес-процессов в интернете;
            </li>
            <li>имеет <strong>несколько высших образований</strong>, в том числе «Красный» диплом (с отличем и средним
              баллом 5.0) магистра в области IT-технологий (Университет ИТМО, Мегафакультет компьютерных технологий и
              управления, Факультет программной инженерии и компьютерной техники);
            </li>
            <li>владелец интернет-телеканала <strong>InfoBiz1.TV</strong>;</li>
            <li>генеральный директор консалтингового агентства <strong>Phoenix Consult Agency</strong>;</li>
            <li>руководитель профессиональной <strong>видеостудии</strong>;</li>
            <li>разработчик инновационной вебинарной площадки нового поколения;</li>
            <li>создатель первой в Мире бизнес-школы, обучающий материал которой <strong>научно доказан</strong>
              математическими методами;
            </li>
            <li>идейный вдохновитель проекта по созданию своего дела в интернете и трансформации стиля жизни, <strong>LifeRestart.Ru</strong>;
            </li>
            <li>главный <strong>герой ряда фильмов</strong> про бизнес и мотивацию;</li>
            <li>один из успешных Интернет-бизнесменов России и СНГ, опыт ведения предпринимательской деятельности с 2007
              года;
            </li>
            <li>военный, <strong>Участник боевых действий</strong> на территории Северного Кавказа;</li>
            <li>также был владельцем и генеральным директором Детективно-охранного предприятия и художественным
              руководителем музыкального проекта;
            </li>
            <li>женат, <strong>воспитывает сына и дочь</strong>.</li>
          </ul>
        </div>
        <div class="col-lg-4">
          <img src="../../../../assets/images/utp1/other/04_00.png" alt="Петр Громов"
               class="img-responsive ml-auto mr-auto">
        </div>
      </div><!-- .row -->
    </div><!-- .container -->
  </section>
</template>

<script>
export default {
  name: "section-11"
}
</script>

<style scoped>

</style>