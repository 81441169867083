<template>
  <section class="section bg-cover s-bg-light-08 background" id="section-10">
    <div class="container">
      <header class="section-header">
        <h2>Как работают инвестиции?</h2>
      </header><!-- .section-header -->
      <div class="row">
        <div class="col-md-3 col-sm-6">
          <div class="block-icon">
            <div class="block-icon-image">
              <img src="../../../assets/images/invest/ic/ic5.png" alt="">
            </div>
            <h5 class="block-icon-title">Выбираете программу</h5>
            <p class="block-icon-desc">Вы можете стать как Прямым Инвестором, так и Непрямым Инвестором</p>
          </div>
        </div><!-- .col-xx-n -->
        <div class="col-md-3 col-sm-6">
          <div class="block-icon">
            <div class="block-icon-image">
              <img src="../../../assets/images/invest/ic/ic6.png" alt="">
            </div>
            <h5 class="block-icon-title">Оформляете заказ</h5>
            <p class="block-icon-desc">Нажимаете на кнопку, вводите своё имя, e-mail и телефон (в международном
              формате)</p>
          </div>
        </div><!-- .col-xx-n -->
        <div class="col-md-3 col-sm-6">
          <div class="block-icon">
            <div class="block-icon-image">
              <img src="../../../assets/images/invest/ic/ic7.png" alt="">
            </div>
            <h5 class="block-icon-title">Вносите инвестиции</h5>
            <p class="block-icon-desc">После оформления заказа у Вас будет 4 дня на его оплату любыми удобными
              способами</p>
          </div>
        </div><!-- .col-xx-n -->
        <div class="col-md-3 col-sm-6">
          <div class="block-icon">
            <div class="block-icon-image">
              <img src="../../../assets/images/invest/ic/ic8.png" alt="">
            </div>
            <h5 class="block-icon-title">Получаете бонусы</h5>
            <p class="block-icon-desc">Сразу после оплаты получаете обещанные бонусы, которые мы отправим Вам на
              почту</p>
          </div>
        </div><!-- .col-xx-n -->
      </div><!-- .row -->

      <div class="row">
        <div class="col-md-3 col-sm-6">
          <div class="block-icon">
            <div class="block-icon-image">
              <img src="../../../assets/images/invest/ic/ic9.png" alt="">
            </div>
            <h5 class="block-icon-title">Проходите обучение</h5>
            <p class="block-icon-desc">Обучение и настройка занимает в среднем две недели (максимум месяц)</p>
          </div>
        </div><!-- .col-xx-n -->
        <div class="col-md-3 col-sm-6">
          <div class="block-icon">
            <div class="block-icon-image">
              <img src="../../../assets/images/invest/ic/ic10.png" alt="">
            </div>
            <h5 class="block-icon-title">Выходите на прибыль</h5>
            <p class="block-icon-desc">Уже за первый месяц после обучения Вы можете заработать до 300&nbsp;000&nbsp;<i
                class="fa fa-ruble-sign"></i></p>
          </div>
        </div><!-- .col-xx-n -->
        <div class="col-md-3 col-sm-6">
          <div class="block-icon">
            <div class="block-icon-image">
              <img src="../../../assets/images/invest/ic/ic11.png" alt="">
            </div>
            <h5 class="block-icon-title">Вы, - соучредитель</h5>
            <p class="block-icon-desc">По факту оплаты Вы получаете полный пакет документов для получения акций</p>
          </div>
        </div><!-- .col-xx-n -->
        <div class="col-md-3 col-sm-6">
          <div class="block-icon">
            <div class="block-icon-image">
              <img src="../../../assets/images/invest/ic/ic12.png" alt="">
            </div>
            <h5 class="block-icon-title">Вы, - акционер</h5>
            <p class="block-icon-desc">После заполнения документов и подтверждения личности мы передаём Вам акции</p>
          </div>
        </div><!-- .col-xx-n -->
      </div><!-- .row -->
    </div><!-- .container -->
  </section>
</template>

<script>
export default {
  name: "invest-section-10"
}
</script>

<style scoped>
.background {
  background-image: url(../../../assets/images/invest/bg/bg3.jpg);
}
</style>