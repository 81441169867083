import axios from "axios";
import {ref} from 'vue';
import {useStore} from "vuex";
import useTokens from "@/hooks/useTokens";

export default function useUser() {

    const store = useStore()
    const basic = ref({
        id: null,
        login: "",
        name: "",
        email: "",
        phone: ""
    })

    const getUser = async () => {
        const header = await authHeader()
        if (header) {
            axios.get(`https://api.utp1.com/users/admin/settings`, {
                headers: header
            })
                .then(response => {
                    basic.value = response.data
                    store.commit('auth/auth', basic.value)
                })
                .catch(async () => {
                    await updateTokens()
                    await getUser()
                })
        }
    }

    const {authHeader, updateTokens} = useTokens()

    return {getUser}

}
