<template>
  <!-- Modals -->
  <div class="modal fade modal-login in" v-if="show"  @click="modalClose">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span>&times;</span>
          </button>
        </div>
        <div class="modal-body" @click.stop>
          <form-utp1/>
        </div><!-- .utp-home-form -->
      </div>
    </div><!-- .modal-content -->
  </div><!-- .modal-dialog -->
</template>

<script>
import FormUtp1 from "@/components/UI/Form/FormUtp1";

export default {
  name: "login-modal",
  components: {FormUtp1},
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  setup(_, {emit}) {
    const modalClose = () => {
      emit('update:show', false)

    }
    return {modalClose}
  }
}
</script>

<style scoped>

</style>
