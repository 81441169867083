<template>
  <div class="checkbox">
    <label class="custom-checkbox"><slot></slot>
      <input type="checkbox" :checked=modelValue @input="checkedInput">
      <span></span>
    </label>
  </div>
</template>

<script>
export default {
  name: "checkbox-utp1",
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
  },
  setup(_, { emit }) {
    const checkedInput = (event) => {
      emit("update:modelValue", event.target.checked)
    }
    return {checkedInput}
  }
}
</script>

<style scoped>

</style>
