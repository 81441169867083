<template>
  <div class="form-group">
    <label>
      <slot></slot>
    </label>
    <input-home-utp1
        v-focus="label.focus"
        v-bind:model-value="modelValue"
        type="password"
        :placeholder="label.placeholder"
        @input="updateInput"
    />
  </div>
</template>

<script>
export default {
  name: "form-group-password-utp1",
  props: {
    modelValue: [String],
    label: [Object]
  },
  setup(_, {emit}) {
    const updateInput = (event) => {
      emit("update:modelValue", event.target.value)
    }
    return {updateInput}
  }
}
</script>

<style scoped>

</style>