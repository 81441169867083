import axios from "axios";
import {ref} from 'vue';
import useTokens from "@/hooks/useTokens";

export function useLogin(entry) {

    const loginLoader = ref(false)
    const loginException = ref("")
    const tokens = ref({
        accessToken: "",
        refreshToken: "",
        remember: false
    })

    const loginUtp1 = async () => {
        loginException.value = ""
        if (entry.login === "") loginException.value = "No login"
        else if (entry.password === "") loginException.value = "No password"
        else {
            loginLoader.value = true
            await logout()
            await axios.post(`https://api.utp1.com/users/login`, entry)
                .then(response => {
                    tokens.value = response.data
                    setTokens(tokens)
                    window.location.href = `https://admin.utp1.com`
                })
                .catch(e => {
                    try {
                        loginException.value = e.response.data.error
                    } catch {
                        loginException.value = "Exception"
                    }
                })
                .finally(() => loginLoader.value = false)
        }
    }

    const {setTokens, logout} = useTokens()

    return {
        loginUtp1,
        loginLoader,
        loginException
    }

}
