import {useCookies} from "vue3-cookies";
import axios from "axios";
import {ref} from "vue";
import {useStore} from "vuex";

export default function useTokens() {
    const store = useStore()

    const accessToken = () => {
        return useCookies().cookies.get("accessToken")
    }
    const refreshToken = () => {
        return useCookies().cookies.get("refreshToken")
    }
    const refresh = ref({
        token: refreshToken()
    })
    const getToken = () => {
        refresh.value.token = refreshToken()
        return refresh.value
    }

    const updateToken = ref(false)

    const authHeader = async () => {
        if (accessToken()) {
            return {Authorization: 'Bearer ' + accessToken()}
        } else if (refreshToken() && !updateToken.value) {
            await updateTokens()
            if (accessToken()) {
                return {Authorization: 'Bearer ' + accessToken()}
            } else return null
        } else return null
    }

    const tokens = ref({
        accessToken: "",
        refreshToken: "",
        remember: false
    })

    const updateTokens = async () => {
        const token = getToken()
        if (token && !updateToken.value) {
            await axios.put(`https://api.utp1.com/users/login`, token)
                .then(response => {
                    tokens.value = response.data
                    setTokens(tokens)
                    updateToken.value = true
                })
                .catch(() => {
                    delTokens()
                })
        } else delTokens()
    }

    const setTokens = (tokens) => {
        useCookies().cookies.remove("accessToken", "/", ".utp1.com")
        useCookies().cookies.remove("refreshToken", "/", ".utp1.com")
        if (tokens.value.remember) {
            useCookies().cookies.set("accessToken", tokens.value.accessToken, "24h", "/", ".utp1.com")
            useCookies().cookies.set("refreshToken", tokens.value.refreshToken, "61d", "/", ".utp1.com")
        } else {
            useCookies().cookies.set("accessToken", tokens.value.accessToken, 0, "/", ".utp1.com")
            useCookies().cookies.set("refreshToken", tokens.value.refreshToken, 0, "/", ".utp1.com")
        }
    }

    const logout = async () => {
        const header = await authHeader()
        const token = getToken()
        if (header && token) {
            await axios.put(`https://api.utp1.com/users/admin/settings/logout`, token, {
                headers: header
            })
                .then()
                .catch(async () => {
                    await updateTokens()
                    await logout()
                })
                .finally(delTokens)
        }
    }

    const delTokens = () => {
        useCookies().cookies.remove("accessToken", "/", ".utp1.com")
        useCookies().cookies.remove("refreshToken", "/", ".utp1.com")
        store.commit('avatar/deleteAvatar')
        store.commit('auth/unAuth')
    }
    //, ".utp1.com"

    return {authHeader, setTokens, updateTokens, updateToken, logout, delTokens}
}
