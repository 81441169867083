<template>
  <section class="section" id="section-11">
    <div class="container">
      <header class="section-header">
        <h2>Кому стоит инвестировать в систему UTP1?!</h2>
      </header><!-- .section-header -->
      <div class="row">
        <div class="col-md-4 col-sm-12">
          <div class="block-icon">
            <div class="text-center">
              <img src="../../../assets/images/invest/ic/ic13.jpg" class="img-responsive" alt="">
            </div>
            <h3 class="block-icon-title">Новичкам</h3>
            <p class="block-icon-desc">Это лучшая возможность быстро начать своё прибыльное дело в интернете не
              сталкиваясь с техническими трудностями</p>
          </div>
        </div><!-- .col-xx-n -->
        <div class="col-md-4 col-sm-12">
          <div class="block-icon">
            <div class="text-center">
              <img src="../../../assets/images/invest/ic/ic14.jpg" class="img-responsive" alt="">
            </div>
            <h3 class="block-icon-title">Предпринимателям</h3>
            <p class="block-icon-desc">Чем быстрее мы соберём необходимые инвестиции, тем быстрее система начнёт
              работать на Вас принося прибыль</p>
          </div>
        </div><!-- .col-xx-n -->
        <div class="col-md-4 col-sm-12">
          <div class="block-icon">
            <div class="text-center">
              <img src="../../../assets/images/invest/ic/ic15.jpg" class="img-responsive" alt="">
            </div>
            <h3 class="block-icon-title">Инвесторам</h3>
            <p class="block-icon-desc">Будущее за высокотехнологичным бизнесом, и если компания не представлена в
              интернете, её нет</p>
          </div>
        </div><!-- .col-xx-n -->
      </div><!-- .row -->
      <h2 class="pt-0">Инвестируя в систему UTP1 Вы:</h2>
      <ul class="list-icon list-success">
        <li>Ускоряете процесс создания мультифункциональной инновационной системы с интегрированной инфраструктурой для
          взаимодействия между предпринимателями, специалистами по продажам и покупателями;
        </li>
        <li>получаете готовую настроенную программу для ведения своего высокодоходного дела в интернете;</li>
        <li>осуществляете свой вклад в развитие бизнеса нового поколения, вносите своё имя в новейшую историю.</li>
      </ul>
      <p class="text-center pt-30">
        <a href="#section-3" class="btn btn-el btn-danger btn-scroll">Стать акционером!</a>
      </p>
    </div><!-- .container -->
  </section>
</template>

<script>
export default {
  name: "invest-section-11"
}
</script>

<style scoped>

</style>