<template>
  <div class="utp-doc-category">
    <div class="container">
      <h2 class="utp-doc-header">
        <span class="first">Передача акций</span>
        <span class="second">Полный пакет документов</span>
        <hr>
      </h2>
      <div class="utp-doc-list row">
        <div class="utp-doc-col col-xs-12 col-md-3 col-sm-6">
          <div class="utp-doc-item">
            <a href="https://files.utp1.com/docs/utp1.com/docs/contracts/contracts.pdf" class="utp-doc-link" target="_blank">
              <img src="../../../assets/images/docs/contracts.jpg" alt="" class="img-responsive">
              <span class="utp-doc-name">Договор купли-продажи акций</span>
            </a>
          </div>
        </div>
        <div class="utp-doc-col col-xs-12 col-md-3 col-sm-6">
          <div class="utp-doc-item">
            <a href="https://files.utp1.com/docs/utp1.com/docs/contracts/packages.pdf" class="utp-doc-link" target="_blank">
              <img src="../../../assets/images/docs/packages.jpg" alt="" class="img-responsive">
              <span class="utp-doc-name">Описание инвестиционных пакетов</span>
            </a>
          </div>
        </div>
        <div class="utp-doc-col col-xs-12 col-md-3 col-sm-6">
          <div class="utp-doc-item">
            <a href="https://files.utp1.com/docs/utp1.com/docs/contracts/rules.pdf" class="utp-doc-link" target="_blank">
              <img src="../../../assets/images/docs/rules.jpg" alt="" class="img-responsive">
              <span class="utp-doc-name">Правила, политика и процедура инвестирования
и управления рисками</span>
            </a>
          </div>
        </div>
      </div>
      <div class="utp-doc-list row">
        <div class="utp-doc-col col-xs-12 col-md-3 col-sm-6">
          <div class="utp-doc-item">
            <a href="https://files.utp1.com/docs/utp1.com/docs/contracts/certificate.jpg" class="utp-doc-link" target="_blank">
              <img src="../../../assets/images/docs/certificate.jpg" alt="" class="img-responsive">
              <span class="utp-doc-name">Сертификат инвестора</span>
            </a>
          </div>
        </div>
        <div class="utp-doc-col col-xs-12 col-md-3 col-sm-6">
          <div class="utp-doc-item">
            <a href="https://files.utp1.com/docs/utp1.com/docs/contracts/attachment.docx" class="utp-doc-link" target="_blank">
              <img src="../../../assets/images/docs/attachment.jpeg" alt="" class="img-responsive">
              <span class="utp-doc-name">Приложение</span>
            </a>
          </div>
        </div>
        <div class="utp-doc-col col-xs-12 col-md-3 col-sm-6">
          <div class="utp-doc-item">
            <a href="https://files.utp1.com/docs/utp1.com/docs/contracts/kvitanciya.xls" class="utp-doc-link" target="_blank">
              <img src="../../../assets/images/docs/kvitanciya.jpeg" alt="" class="img-responsive">
              <span class="utp-doc-name">Квитанция об оплате</span>
            </a>
          </div>
        </div>
      </div>
      <div class="utp-doc-list row">
        <div class="utp-doc-col col-xs-12 col-md-3 col-sm-6">
          <div class="utp-doc-item">
            <a href="https://files.utp1.com/docs/utp1.com/docs/contracts/profile.pdf" class="utp-doc-link" target="_blank">
              <img src="../../../assets/images/docs/profile.jpg" alt="" class="img-responsive">
              <span class="utp-doc-name">Анкета для регистрации физического лица в ВТБ-регистраторе (PDF)</span>
            </a>
          </div>
        </div>
        <div class="utp-doc-col col-xs-12 col-md-3 col-sm-6">
          <div class="utp-doc-item">
            <a href="https://files.utp1.com/docs/utp1.com/docs/contracts/profile.docx" class="utp-doc-link" target="_blank">
              <img src="../../../assets/images/docs/profile.jpg" alt="" class="img-responsive">
              <span class="utp-doc-name">Анкета для регистрации физического лица в ВТБ-регистраторе (DOCX)</span>
            </a>
          </div>
        </div>
        <div class="utp-doc-col col-xs-12 col-md-3 col-sm-6">
          <div class="utp-doc-item">
            <a href="https://files.utp1.com/docs/utp1.com/docs/contracts/profile_sample.pdf" class="utp-doc-link" target="_blank">
              <img src="../../../assets/images/docs/profile_sample.jpg" alt="" class="img-responsive">
              <span class="utp-doc-name">Образец заполнения анкеты для регистрации физического лица</span>
            </a>
          </div>
        </div>
      </div>
      <div class="utp-doc-list row">
        <div class="utp-doc-col col-xs-12 col-md-3 col-sm-6">
          <div class="utp-doc-item">
            <a href="https://files.utp1.com/docs/utp1.com/docs/contracts/profile_attachment.pdf" class="utp-doc-link" target="_blank">
              <img src="../../../assets/images/docs/profile_attachment.jpg" alt="" class="img-responsive">
              <span class="utp-doc-name">Обязательное Приложение к Анкете (PDF)</span>
            </a>
          </div>
        </div>
        <div class="utp-doc-col col-xs-12 col-md-3 col-sm-6">
          <div class="utp-doc-item">
            <a href="https://files.utp1.com/docs/utp1.com/docs/contracts/profile_attachment.docx" class="utp-doc-link" target="_blank">
              <img src="../../../assets/images/docs/profile_attachment.jpg" alt="" class="img-responsive">
              <span class="utp-doc-name">Обязательное Приложение к Анкете (DOCX)</span>
            </a>
          </div>
        </div>
        <div class="utp-doc-col col-xs-12 col-md-3 col-sm-6">
          <div class="utp-doc-item">
            <a href="https://files.utp1.com/docs/utp1.com/docs/contracts/profile_attachment_sample.pdf" class="utp-doc-link" target="_blank">
              <img src="../../../assets/images/docs/profile_attachment_sample.jpg" alt="" class="img-responsive">
              <span class="utp-doc-name">Образец заполнения Обязательного Приложения к Анкете</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "docs-section-1"
}
</script>

<style scoped>

</style>