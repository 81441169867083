<template>
  <label>Спасибо за регистрацию!</label>
  <div class="my-modal-div">Подробная информация направлена Вам на e-mail: <strong>{{ hideEmail }}</strong>
  </div>
  <div class="my-modal-div">Если письмо не пришло в течение нескольких минут, проверьте папку <i>«Спам»</i>,
    и нажмите<strong><i>«НЕ спам»</i></strong><br></div>
  <div v-if="registeredButton !== ''" class="form-group-buttons my-modal-buttons">
    <button type="button" @click="redirect"
            class="btn btn-primary btn-lg btn-block">{{ registeredButton }}
    </button>
  </div>
</template>

<script>
export default {
  name: "registered-form",
  props: {
    hideEmail: String,
    registeredButton: String
  },
  emits: ['redirect'],
  setup(_, {emit}) {
    const redirect = () => {
      emit('redirect')
    }
    return {redirect}
  }
}
</script>

<style scoped>
.my-modal-div {
  padding-top: 10px;
}

.my-modal-buttons {
  padding: 20px 0 10px 0;
}
</style>