<template>
  <header class="utp-header s-bg-dark-07 background-header-bg-jpg" data-stellar-background-ratio="0.5">
    <div class="container">
      <h1><slot></slot></h1>
    </div>
    <span class="delimiter delimiter-bottom">
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 251.774 512 42.159">
			<path fill="rgba(255, 63, 58,.5)" d="M378.667,277.138c-70.456,12.097-102.683,5.783-238.667-16.391c-54.79-8.934-102.525-10.063-140-8.228v40.413h512v-32.258
				C470.233,262.663,426.317,268.957,378.667,277.138z"/>
			<path fill="#eeeeee" d="M345.067,277.594C194.933,256.28,56.038,268.906,0,275.856v18.077h512v-4.62
				C482.921,291.471,432.465,290.002,345.067,277.594z"/>
			</svg>
		</span>
  </header>
</template>

<script>
export default {
  name: "header-utp1"
}
</script>

<style scoped>
.background-header-bg-jpg {
  background-image: url(../assets/images/header-bg.jpg);
}
</style>