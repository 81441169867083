<template>
  <header-utp1>Отзывы</header-utp1>
  <main class="utp-main">
    <div class="utp-reviews">

      <div class="utp-reviews-category">
        <div class="container">
          <h2 class="utp-reviews-header">
            <span class="first">Истории акционеров</span>
            <span class="second">Истории акционеров</span>
            <hr>
          </h2>

          <div class="utp-reviews-list row">

            <div class="utp-review-col col-xs-12 col-lg-5 col-sm-6">
              <div class="utp-review-item">
                <a @click="modalOn('e-dhuHGfSnM')" class="utp-review-video-item">
                  <span class="video-title"></span>
                  <span class="video-screen">
										<img src="../assets/images/reviews/1_1.jpg" alt="">
									</span>
                </a>
              </div>
            </div>

            <div class="utp-review-col col-xs-12 col-lg-5 col-sm-6">
              <div class="utp-review-item">
                <a @click="modalOn('AvqtcSQyqRU')" class="utp-review-video-item">
                  <span class="video-title"></span>
                  <span class="video-screen">
										<img src="../assets/images/reviews/1_2.jpg" alt="">
									</span>
                </a>
              </div>
            </div>

          </div>

        </div>
      </div>

      <div class="utp-reviews-category">
        <div class="container">
          <h2 class="utp-reviews-header">
            <span class="first">Партнёрка UTP1</span>
            <span class="second">Партнёрская программа</span>
            <hr>
          </h2>
          <div class="utp-reviews-list row">

            <div class="utp-review-col col-xs-12 col-lg-5 col-sm-6">
              <div class="utp-review-item">
                <a @click="modalOn('C2P9sqxgEPo')" class="utp-review-video-item">
                  <span class="video-title"></span>
                  <span class="video-screen">
										<img src="../assets/images/reviews/2_1.jpg" alt="">
									</span>
                </a>
              </div>
            </div>

            <div class="utp-review-col col-xs-12 col-lg-5 col-sm-6">
              <div class="utp-review-item">
                <a @click="modalOn('hdN36-dtLQ8')" class="utp-review-video-item">
                  <span class="video-title"></span>
                  <span class="video-screen">
										<img src="../assets/images/reviews/2_2.jpg" alt="">
									</span>
                </a>
              </div>
            </div>

          </div>

          <div class="utp-reviews-list row">

            <div class="utp-review-col col-xs-12 col-lg-5 col-sm-6">
              <div class="utp-review-item">
                <a @click="modalOn('XN5LmRUp8gc')" class="utp-review-video-item">
                  <span class="video-title"></span>
                  <span class="video-screen">
										<img src="../assets/images/reviews/2_3.jpg" alt="">
									</span>
                </a>
              </div>
            </div>

            <div class="utp-review-col col-xs-12 col-lg-5 col-sm-6">
              <div class="utp-review-item">
                <a @click="modalOn('QooJH5mGsY4')" class="utp-review-video-item">
                  <span class="video-title"></span>
                  <span class="video-screen">
										<img src="../assets/images/reviews/2_4.jpg" alt="">
									</span>
                </a>
              </div>
            </div>

          </div>

        </div>
      </div>

      <div class="utp-reviews-category">
        <div class="container">
          <h2 class="utp-reviews-header">
            <span class="first">Видео Отзывы</span>
            <span class="second">Видео Отзывы</span>
            <hr>
          </h2>
          <div class="utp-reviews-list row">

            <div class="utp-review-col col-xs-12 col-lg-5 col-sm-6">
              <div class="utp-review-item">
                <a @click="modalOn('pSS2-t5a6MI')" class="utp-review-video-item">
                  <span class="video-title"></span>
                  <span class="video-screen">
										<img src="../assets/images/reviews/3_1.jpg" alt="">
									</span>
                </a>
              </div>
            </div>

            <div class="utp-review-col col-xs-12 col-lg-5 col-sm-6">
              <div class="utp-review-item">
                <a @click="modalOn('nmEsyM2777w')" class="utp-review-video-item">
                  <span class="video-title"></span>
                  <span class="video-screen">
										<img src="../assets/images/reviews/3_2.jpg" alt="">
									</span>
                </a>
              </div>
            </div>

          </div>

          <div class="utp-reviews-list row">

            <div class="utp-review-col col-xs-12 col-lg-5 col-sm-6">
              <div class="utp-review-item">
                <a @click="modalOn('hkyLA8gd2pQ')" class="utp-review-video-item">
                  <span class="video-title"></span>
                  <span class="video-screen">
										<img src="../assets/images/reviews/3_3.jpg" alt="">
									</span>
                </a>
              </div>
            </div>

            <div class="utp-review-col col-xs-12 col-lg-5 col-sm-6">
              <div class="utp-review-item">
                <a @click="modalOn('5G8QxOEnyLk')" class="utp-review-video-item">
                  <span class="video-title"></span>
                  <span class="video-screen">
										<img src="../assets/images/reviews/3_4.jpg" alt="">
									</span>
                </a>
              </div>
            </div>

          </div>

        </div>
      </div>

    </div>
  </main>

  <video-modal
      :modal="modal"
      @closeModal="modalOff"
  />
</template>

<script>
import HeaderUtp1 from "@/components/HeaderUtp1";
import VideoModal from "@/components/UI/VideoModal";
import {ref} from "vue";

export default {
  name: "reviews",
  components: {VideoModal, HeaderUtp1},
  setup() {
    const modal = ref({
          show: false,
          url: ""
        }
    )

    function modalOn(url) {
      modal.value.show = true
      modal.value.url = url
    }

    const modalOff = () => {
      modal.value.show = false
      modal.value.url = ""
    }

    return {modal, modalOn, modalOff}
  }
}
</script>

<style scoped>

</style>