<template>
  <section class="section" id="section-2">
    <div class="container">
      <header class="section-header">
        <h2>Непрямые инвестиции в систему UTP1</h2>
      </header><!-- .section-header -->
      <div class="card card-p">
        <div class="row">
          <div class="col-sm-4 col-sm-push-8 text-center-xs">
            <img src="../../../assets/images/invest/products/utp1_franchise.png" alt="Product Image" class="img-responsive">
          </div>
          <div class="col-sm-8 col-sm-pull-4">
            <h2>Программа непрямого инвестирования<br> «Дистрибьютор»</h2>
            <ul class="list-ordered list-circle list-success">
              <li>3 (ТРИ) доли в компании (в FAQ Вы можете посмотреть формулу вычисления);</li>
              <li>40% дохода со всех Ваших продаж (партнёрская комиссия первого&nbsp;уровня);</li>
              <li>20% дохода со всех продаж привлечённых Вами инвесторов (второй&nbsp;уровень);</li>
              <li>Эксклюзивные права на франшизу;</li>
              <li>Необходимые материалы и обучение;</li>
              <li>Персональный менеджер</li>
            </ul>
          </div>
        </div><!-- .row -->
        <hr class="hr-special">
        <div class="row">
          <div class="col-sm-4 text-center-xs">
            <img src="../../../assets/images/invest/products/utp1_partner.png" alt="Product Image" class="img-responsive">
          </div>
          <div class="col-sm-8">
            <h2>Программа непрямого инвестирования<br> «Партнёр»</h2>
            <ul class="list-ordered list-circle list-success">
              <li>1 (ОДНА) доли в компании (в FAQ Вы можете посмотреть формулу вычисления);</li>
              <li>30% дохода со всех Ваших продаж (партнёрская комиссия первого&nbsp;уровня);</li>
              <li>5% дохода со всех продаж привлечённых Вами инвесторов (второй&nbsp;уровень);</li>
              <li>Эксклюзивные права на франшизу;</li>
              <li>Необходимые материалы и обучение;</li>
            </ul>
          </div>
        </div><!-- .row -->

      </div>
    </div><!-- .container -->
  </section>
</template>

<script>
export default {
  name: "invest-section-2"
}
</script>

<style scoped>

</style>