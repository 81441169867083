<template>
  <div class="utp-doc-category">
    <div class="container">
      <h2 class="utp-doc-header">
        <span class="first">Передача акций</span>
        <span class="second">Полезные ссылки и видеоинструкции</span>
        <hr>
      </h2>
      <div class="container-lg">
        <ul class="list-ordered list-circle list-lg list-danger">
          <li>
            Официальный сайт ВТБ-регистратора: <a href="https://www.vtbreg.ru"
                                                  target="_blank">https://www.vtbreg.ru</a>;
          </li>
          <li>
            инструкция: <a href="https://www.vtbreg.ru/shareholder/operations/operations-112/" target="_blank">vtbreg.ru/shareholder/operations/</a>;
          </li>
          <li>
            актуальные формы: <a href="https://www.vtbreg.ru/shareholder/forms-of-documents/" target="_blank">vtbreg.ru/shareholder/forms-of-documents/</a>;
          </li>
          <li>
            пейскурант: <a
              href="https://www.vtbreg.ru/upload/iblock/9c9/+%D0%9E%D1%81%D0%BD%D0%BE%D0%B2%D0%BD%D0%BE%D0%B9%20%E2%84%961%204748%20%D0%BF.%203%20%D1%81%2015.08.2020%20%D1%81%D0%B0%D0%B9%D1%82.pdf"
              target="_blank">vtbreg.ru/shareholder/registrar/</a>;
          </li>
          <li>
            контакты: <a href="https://www.vtbreg.ru/feedback/" target="_blank">vtbreg.ru/feedback/</a>;
          </li>
          <li>
            региональная сеть: <a href="https://www.vtbreg.ru/feedback/company/regional/" target="_blank">vtbreg.ru/feedback/company/regional/</a>;
          </li>
        </ul>
        <h3>Почтовые адреса для корреспонденции ВТБ-регистратору:</h3>
        <p>127137, Российская Федерация, г. Москва, а/я 54; <br>127015, Российская Федерация, г. Москва, ул. Правды,
          д. 23, корп. 10.</p>
      </div>
      <h2 class="utp-doc-header">
        <hr>
      </h2>
      <div class="utp-reviews-list row">
        <div class="utp-review-col col-xs-12 col-lg-5 col-sm-6">
          <div class="utp-review-item">
            <a @click="modalOn('GKPabYSx8LI')" class="utp-review-video-item">
              <span class="video-title"></span>
              <span class="video-screen">
										<img src="../../../assets/images/docs/sample_russia.jpg" alt="">
									</span>
            </a>
          </div>
        </div>
        <div class="utp-review-col col-xs-12 col-lg-5 col-sm-6">
          <div class="utp-review-item">
            <a @click="modalOn('A29-vQsW964')" class="utp-review-video-item">
              <span class="video-title"></span>
              <span class="video-screen">
										<img src="../../../assets/images/docs/sample_no_russia.jpg" alt="">
									</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <video-modal
      :modal="modal"
      @closeModal="modalOff"
  />
</template>

<script>
import VideoModal from "@/components/UI/VideoModal";
import {ref} from "vue";

export default {
  name: "docs-section-2",
  components: {VideoModal},

  setup() {
    const modal = ref({
          show: false,
          url: ""
        }
    )

    function modalOn(url) {
      modal.value.show = true
      modal.value.url = url
    }

    const modalOff = () => {
      modal.value.show = false
      modal.value.url = ""
    }

    return {modal, modalOn, modalOff}
  }
}
</script>

<style scoped>

</style>