export default {
    data() {
        return {
            UTM: {
                medium: null,
                source: null,
                campaign: null,
                content: null,
                term: null
            }
        }
    },
    methods: {
        getUtm() {
            this.UTM.medium = this.$route.query.utm_medium || null
            this.UTM.source = this.$route.query.utm_source || null
            this.UTM.campaign = this.$route.query.utm_campaign || null
            this.UTM.content = this.$route.query.utm_content || null
            this.UTM.term = this.$route.query.utm_term || null
            this.$store.commit('utm/setUtm', this.UTM)
        }
    },
    created() {
        this.getUtm()
    }
}
