<template>
  <section class="section s-bg-light-08 bg-cover pt-30 pb-45 background-bg2-jpg">
    <div class="container">
      <header class="container-el">
        <blockquote><p class="h3">Мы создаём сервис с дружественным интерфейсом, который объединяет в себе
          все необходимые инструменты для автоматизации и ведения бизнеса через
          интернет и полностью избавляет от любых технических сложностей.</p></blockquote>

      </header><!-- .section-header -->
      <div class="text-center">
        <h2 class="text-danger">Наш подход к созданию системы</h2>
        <p class="h4">Система UTP1 это плод нашего многолетнего опыта ведения бизнеса через интернет. Конечно, мы
          понимаем, что у каждого свой взгляд на подход к ведению бизнеса, и он уникален. Именно поэтому при
          планировании инструментов сервиса пообщались со многими ведущими интернет-предпринимателями, чтобы учесть все
          нюансы. Многие из них, в последствии, стали нашими инвесторами, среди первых это:</p>
        <div class="utp-team-list utp-team-list-custom row mt-30">

          <div class="utp-team-col col-xs-12 col-lg-3 col-md-3 col-sm-6">
            <div class="utp-team-item">
              <div class="utp-team-img">
                <img src="../../../../assets/images/utp1/other/01_01.png" alt="">
              </div>
              <h4 class="utp-team-name">Евгений Вергус</h4>
            </div>
          </div>

          <div class="utp-team-col col-xs-12 col-lg-3 col-md-3 col-sm-6">
            <div class="utp-team-item">
              <div class="utp-team-img">
                <img src="../../../../assets/images/utp1/other/01_02.png" alt="">
              </div>
              <h4 class="utp-team-name">Вячеслав Вопилкин</h4>
            </div>
          </div>

          <div class="utp-team-col col-xs-12 col-lg-3 col-md-3 col-sm-6">
            <div class="utp-team-item">
              <div class="utp-team-img">
                <img src="../../../../assets/images/utp1/other/01_03.png" alt="">
              </div>
              <h4 class="utp-team-name">Сергей Копыленко</h4>
            </div>
          </div>
          <div class="utp-team-col col-xs-12 col-lg-3 col-md-3 col-sm-6">
            <div class="utp-team-item">
              <div class="utp-team-img">
                <img src="../../../../assets/images/utp1/other/01_04.png" alt="">
              </div>
              <h4 class="utp-team-name">Дмитрий Воробьев</h4>
            </div>
          </div>

        </div>

        <div class="h2 text-danger">И многие другие! Вы можете стать на ряду с ними!</div>
      </div>
    </div><!-- .container -->
  </section>
</template>

<script>
export default {
  name: "section-4"
}
</script>

<style scoped>
.background-bg2-jpg {
  background-image: url(../../../../assets/images/utp1/bg/bg2.jpg);
}
</style>