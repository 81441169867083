import axios from "axios";
import {ref} from 'vue';
import useTokens from "../useTokens";
import {useStore} from "vuex";

export default function useImages() {
    const store = useStore()
    const imagesLoader = ref(false)
    const imagesException = ref(null)

    const getSettingsAvatar = async () => {
        imagesException.value = null
        imagesLoader.value = true
        const header = await authHeader()
        await axios.get(
            `https://src.utp1.com/images/settings/avatar`,
            {headers: header}
        )
            .then(response => {
                store.commit("avatar/setAvatar", response.data)
            })
            .catch(async e => {
                if (e.response.status === 401) {
                    await updateTokens()
                    await getSettingsAvatar()
                }
            })
            .finally(() => imagesLoader.value = false)
    }

    const {authHeader, updateTokens} = useTokens()

    return {
        imagesLoader,
        imagesException,
        getSettingsAvatar
    }

}
