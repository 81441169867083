<template>
  <section class="section bg-cover s-bg-light-07 background" id="section-12">
    <div class="container">
      <header class="section-header">
        <h2>Почему инвестиции в UTP1 самые надёжные?!</h2>
      </header><!-- .section-header -->
      <h3 class="text-center">Мы являемся официальным юридическим лицом, осуществляем свою деятельность строго в
        соответствии с Законодательством, у нас есть офис в Санкт-Петербурге, и мы участвуем в ряде государственных
        программ поддержки бизнеса (всю эту информацию Вы можете проверить).</h3>
      <div class="row pt-30 pb-0">
        <div class="col-md-3 col-sm-6">
          <div class="block-icon">
            <div class="block-icon-image">
              <img src="../../../assets/images/invest/ic/ic16.png" alt="">
            </div>
            <h4 class="block-icon-title">Быстрый возврат инвестиций</h4>
            <p class="block-icon-desc">Вам не надо ждать, когда система будет запущена, Вы можете сразу начать серьёзно
              зарабатывать</p>
          </div>
        </div><!-- .col-xx-n -->
        <div class="col-md-3 col-sm-6">
          <div class="block-icon">
            <div class="block-icon-image">
              <img src="../../../assets/images/invest/ic/ic17.png" alt="">
            </div>
            <h4 class="block-icon-title">Соответствие текущим трендам</h4>
            <p class="block-icon-desc">Принимая решение о создании системы UTP1 мы несколько лет изучали рынок и
              смотрели на новые тенденции</p>
          </div>
        </div><!-- .col-xx-n -->
        <div class="col-md-3 col-sm-6">
          <div class="block-icon">
            <div class="block-icon-image">
              <img src="../../../assets/images/invest/ic/ic18.png" alt="">
            </div>
            <h4 class="block-icon-title">Невозможность создания аналогов</h4>
            <p class="block-icon-desc">Описывая систему мы никогда и никому не разглашаем ключевые аспекты, которые при
              запуске UTP1 изменят мир</p>
          </div>
        </div><!-- .col-xx-n -->
        <div class="col-md-3 col-sm-6">
          <div class="block-icon">
            <div class="block-icon-image">
              <img src="../../../assets/images/invest/ic/ic19.png" alt="">
            </div>
            <h4 class="block-icon-title">Полное обеспечение инвестиций</h4>
            <p class="block-icon-desc">Все инвестиции обеспечиваются конкретными разработками, с которомы Вы уже можете
              ознакомиться</p>
          </div>
        </div><!-- .col-xx-n -->
      </div><!-- .row -->
    </div><!-- .container -->
  </section>
</template>

<script>
export default {
  name: "invest-section-12"
}
</script>

<style scoped>
.background {
  background-image: url(../../../assets/images/invest/bg/bg4.jpg);
}
</style>