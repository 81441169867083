<template>
  <section class="section s-bg-grey" id="section-3">
    <div class="container">
      <header class="section-header pb-40">
        <h2>Стать инвестором UTP1 и начать зарабатывать!</h2>
      </header><!-- .section-header -->

      <div class="price-list price-list-four price-list-special">
        <div class="price-list-items">
          <div class="price-list-item">
            <h3 class="price-list-header">Партнёр</h3>
            <ul class="price-list-options">
              <li data-label="Средний доход в месяц">50 000 <i class="fa fa-ruble-sign"></i></li>
              <li data-label="Средние ежегодные дивиденды">30 000 <i class="fa fa-ruble-sign"></i></li>
              <li data-label="Период окупаемости">~ 2 недели</li>
              <li data-label="Долей компании">1 доля (пакет акций)</li>
              <li data-label="Комиссия 1-го уровня">30% с дохода</li>
              <li data-label="Комиссия 2-го уровня">5% с дохода</li>
              <li data-label="Франшиза"><i class="fa fa-check text-success"></i></li>
              <li data-label="Материалы"><i class="fa fa-check text-success"></i></li>
              <li data-label="Обучение"><i class="fa fa-check text-success"></i></li>
              <li data-label="Персональный менеджер"><i class="fa fa-times text-danger"></i></li>
              <li data-label="Внеочередная поддержка"><i class="fa fa-times text-danger"></i></li>
              <li data-label="Настройка рекламы"><i class="fa fa-times text-danger"></i></li>
              <li data-label="Рекламный бюджет"><i class="fa fa-times text-danger"></i></li>
              <li data-label="Коучинг с Петром Громовым"><i class="fa fa-times text-danger"></i></li>
              <li data-label="Стоимость участия" class="price-list-benefit">
                <span>50 000</span> <i class="fa fa-ruble-sign"></i>
              </li>
            </ul>
            <div class="price-list-action">
<!--              <a href="https://vip.utp1.com/order/utp1_partner?<?php echo $utm;?>" target="_blank"
                 class="btn btn-primary btn-xl">Выбрать</a>     -->
              <a class="btn btn-primary btn-xl non-click">Выбрать</a>
            </div>
          </div>
          <div class="price-list-item active">
            <h3 class="price-list-header">Дистрибьютор</h3>
            <ul class="price-list-options">
              <li data-label="Средний доход в месяц">150 000 <i class="fa fa-ruble-sign"></i></li>
              <li data-label="Средние ежегодные дивиденды">90 000 <i class="fa fa-ruble-sign"></i></li>
              <li data-label="Период окупаемости">~ 2 – 4 недели</li>
              <li data-label="Долей компании">3 доли (пакета акций)</li>
              <li data-label="Комиссия 1-го уровня">40% с дохода</li>
              <li data-label="Комиссия 2-го уровня">20% с дохода</li>
              <li data-label="Франшиза"><i class="fa fa-check text-success"></i></li>
              <li data-label="Материалы"><i class="fa fa-check text-success"></i></li>
              <li data-label="Обучение"><i class="fa fa-check text-success"></i></li>
              <li data-label="Персональный менеджер"><i class="fa fa-check text-success"></i></li>
              <li data-label="Внеочередная поддержка"><i class="fa fa-times text-danger"></i></li>
              <li data-label="Настройка рекламы"><i class="fa fa-times text-danger"></i></li>
              <li data-label="Рекламный бюджет"><i class="fa fa-times text-danger"></i></li>
              <li data-label="Коучинг с Петром Громовым"><i class="fa fa-times text-danger"></i></li>
              <li data-label="Стоимость участия" class="price-list-benefit">
                <span>100 000</span> <i class="fa fa-ruble-sign"></i>
              </li>
            </ul>
            <div class="price-list-action">
<!--              <a href="https://vip.utp1.com/order/utp1_franchise?<?php echo $utm;?>" target="_blank"
                 class="btn btn-danger btn-xl">Выбрать</a>  -->
              <a class="btn btn-danger btn-xl non-click">Выбрать</a>
            </div>
          </div>
          <div class="price-list-item">
            <h3 class="price-list-header">ПИ Light</h3>
            <ul class="price-list-options">
              <li data-label="Средний доход в месяц">300 000 + <i class="fa fa-ruble-sign"></i></li>
              <li data-label="Средние ежегодные дивиденды">300 000 <i class="fa fa-ruble-sign"></i></li>
              <li data-label="Период окупаемости">~ 4 недели</li>
              <li data-label="Долей компании">На 150 000 <i class="fa fa-ruble-sign"></i></li>
              <li data-label="Комиссия 1-го уровня">50% с дохода</li>
              <li data-label="Комиссия 2-го уровня">25% с дохода</li>
              <li data-label="Франшиза"><i class="fa fa-check text-success"></i></li>
              <li data-label="Материалы"><i class="fa fa-check text-success"></i></li>
              <li data-label="Обучение"><i class="fa fa-check text-success"></i></li>
              <li data-label="Персональный менеджер"><i class="fa fa-check text-success"></i></li>
              <li data-label="Внеочередная поддержка"><i class="fa fa-check text-success"></i></li>
              <li data-label="Настройка рекламы"><i class="fa fa-check text-success"></i></li>
              <li data-label="Рекламный бюджет"><i class="fa fa-times text-danger"></i></li>
              <li data-label="Коучинг с Петром Громовым"><i class="fa fa-times text-danger"></i></li>
              <li data-label="Стоимость участия" class="price-list-benefit">
                <span>250 000</span> <i class="fa fa-ruble-sign"></i>
              </li>
            </ul>
            <div class="price-list-action">
<!--              <a href="https://vip.utp1.com/order/utp1_invest_light?<?php echo $utm;?>" target="_blank"
                 class="btn btn-primary btn-xl">Выбрать</a>   -->
              <a class="btn btn-primary btn-xl non-click">Выбрать</a>
            </div>
          </div>
          <div class="price-list-item">
            <h3 class="price-list-header">Инвестор</h3>
            <ul class="price-list-options">
              <li data-label="Средний доход в месяц">300 000 + <i class="fa fa-ruble-sign"></i></li>
              <li data-label="Средние ежегодные дивиденды">1 200 000 <i class="fa fa-ruble-sign"></i></li>
              <li data-label="Период окупаемости">~ 4 - 8 недель</li>
              <li data-label="Долей компании">На 600 000 <i class="fa fa-ruble-sign"></i></li>
              <li data-label="Комиссия 1-го уровня">50% с дохода</li>
              <li data-label="Комиссия 2-го уровня">25% с дохода</li>
              <li data-label="Франшиза"><i class="fa fa-check text-success"></i></li>
              <li data-label="Материалы"><i class="fa fa-check text-success"></i></li>
              <li data-label="Обучение"><i class="fa fa-check text-success"></i></li>
              <li data-label="Персональный менеджер"><i class="fa fa-check text-success"></i></li>
              <li data-label="Внеочередная поддержка"><i class="fa fa-check text-success"></i></li>
              <li data-label="Настройка рекламы"><i class="fa fa-check text-success"></i></li>
              <li data-label="Рекламный бюджет"><i class="fa fa-check text-success"></i></li>
              <li data-label="Коучинг с Петром Громовым"><i class="fa fa-check text-success"></i></li>
              <li data-label="Стоимость участия" class="price-list-benefit">
                <span>1 000 000</span> <i class="fa fa-ruble-sign"></i>
              </li>
            </ul>
            <div class="price-list-action">
<!--              <a href="https://vip.utp1.com/order/utp1_invest?<?php echo $utm;?>" target="_blank"
                 class="btn btn-primary btn-xl">Выбрать</a>     -->
              <a class="btn btn-primary btn-xl non-click">Выбрать</a>
            </div>
          </div>
        </div>
      </div><!-- .price-list -->

    </div><!-- .container -->
  </section>
</template>

<script>
export default {
  name: "invest-section-3"
}
</script>

<style scoped>

</style>