<template>
  <section class="section">
    <div class="container">
      <header class="section-header">
        <h2>Как разрабатываются разделы?<br> Рассмотрим несколько примеров:</h2>
      </header><!-- .section-header -->
      <div class="developing-sections">
        <ul class="list-ordered list-circle list-lg list-danger">
          <li>Для разработки Call-центра Пётр Громов лично в течении более месяца обзванивал все заказы (вне зависимости
            от стоимости), чтобы выработать максимально удобный (интуитивно-понятный) и практичный интерфейс как для
            владельцев бизнеса, так и для сотрудников Call-центра.
          </li>
          <li>Интегрированный в систему блог является результатом ведения блога и его постоянной подстройкой и
            модернизации. В результате чего, с одной стороны, интерфейс не содержит ничего лишнего, с другой стороны,
            разработано 3 типа страниц (вместо стандартных двух), что в разы упрощает управление контентом.
          </li>
          <li>Система автоматизации обучения является симбиозом ФГОСТовских систем Министерства образования Российской
            Федерации (второе высшее образование Петра Громова связано с дистанционным обучением) и упрощенных систем
            автоматизации онлайн тренингов. Тем самым мы взяли всё необходимое и сделали максимально простую систему.
          </li>
        </ul>
        <div class="text-center">
          <h2>Наш главный принцип, при разработке того или иного функционала:</h2>
          <h2 class="text-danger header-special-xs">«Всё, что нужно, и ничего лишнего»</h2>
        </div>
      </div>
    </div><!-- .container -->
  </section>
</template>

<script>
export default {
  name: "section-5"
}
</script>

<style scoped>

</style>