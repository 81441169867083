<template>
  <div class="modal fade utp-modal-1 in dialog-style modal-open" v-if="show" @click="modalClose">
    <div @click.stop class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <button @click="modalClose" type="button" class="close" data-dismiss="modal">
            <span>&times;</span>
          </button>
          <h3 class="modal-title text-danger">UTP1 для крупных, средних и малых компаний</h3>
        </div>
        <div class="modal-body">
          <div class="row pt-0 pb-0">
            <div class="col-md-4 col-sm-12">
              <div class="block-icon">
                <div class="block-icon-image">
                  <img src="../../../../assets/images/utp1/ic/1_4.png" alt="">
                </div>
                <h5 class="block-icon-title">Производителям</h5>
                <p class="block-icon-desc">Мы помогаем продавать свои продукты напрямую, тем самым расширяя
                  аудиторию</p>
              </div>
            </div><!-- .col-xx-n -->
            <div class="col-md-4 col-sm-12">
              <div class="block-icon">
                <div class="block-icon-image">
                  <img src="../../../../assets/images/utp1/ic/1_5.png" alt="">
                </div>
                <h5 class="block-icon-title">Дистрибьюторам</h5>
                <p class="block-icon-desc">Используя систему UTP1 Вы сможете снизить свои затраты и увеличить охват</p>
              </div>
            </div><!-- .col-xx-n -->
            <div class="col-md-4 col-sm-12">
              <div class="block-icon">
                <div class="block-icon-image">
                  <img src="../../../../assets/images/utp1/ic/1_6.png" alt="">
                </div>
                <h5 class="block-icon-title">Службам доставки</h5>
                <p class="block-icon-desc">За счёт инфраструктуры Вы сможете брать больше заказов, увеличивая
                  прибыль</p>
              </div>
            </div><!-- .col-xx-n -->
          </div><!-- .row -->
          <div class="row pt-0 pb-0">
            <div class="col-md-4 col-sm-12">
              <div class="block-icon">
                <div class="block-icon-image">
                  <img src="../../../../assets/images/utp1/ic/1_1.png" alt="">
                </div>
                <h5 class="block-icon-title">Расширение рынка</h5>
                <p class="block-icon-desc">Мы связываем тех, у кого есть продукты и услуги, с теми, кто умеет находить
                  клиентов</p>
              </div>
            </div><!-- .col-xx-n -->
            <div class="col-md-4 col-sm-12">
              <div class="block-icon">
                <div class="block-icon-image">
                  <img src="../../../../assets/images/utp1/ic/1_2.png" alt="">
                </div>
                <h5 class="block-icon-title">Выход On-line</h5>
                <p class="block-icon-desc">Мы поможем Вам вывести Ваш бизнес в интернет и организовать продажи в
                  сети</p>
              </div>
            </div><!-- .col-xx-n -->
            <div class="col-md-4 col-sm-12">
              <div class="block-icon">
                <div class="block-icon-image">
                  <img src="../../../../assets/images/utp1/ic/1_3.png" alt="">
                </div>
                <h5 class="block-icon-title">Отсутствие границ</h5>
                <p class="block-icon-desc">С нами Вы сможете выйти на мировой рынок забыв об языковом барьере</p>
              </div>
            </div><!-- .col-xx-n -->
          </div><!-- .row -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "modal-1",
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  setup(_, {emit}) {
    const modalClose = () => {
      emit('update:show', false)
    }
    return {modalClose}
  }
}
</script>

<style scoped>

</style>