export const auth = {
    state: () => ({
        name: "",
        userId: null,
        auth: false
    }),
    getters: {},
    mutations: {
        auth(state, basic) {
            state.name = basic.name
            state.userId = basic.id
            state.auth = true
        },
        unAuth(state) {
            state.name = ""
            state.auth = false
        }
    },
    actions: {},
    namespaced: true
}
