import {ref} from 'vue';

export default function useError(exception) {

    const errorActive = ref(false)
    const errorMessage = ref("")

    const error = () => {
        if (exception.value === "") {
            errorActive.value = false
            errorMessage.value = ""
        } else {
            errorActive.value = true

            if (exception.value === "No login") errorMessage.value = "Отсутствует логин"
            else if (exception.value === "No password") errorMessage.value = "Отсутствует пароль"
            else if (exception.value === "No name") errorMessage.value = "Отсутствует имя"
            else if (exception.value === "No email") errorMessage.value = "Отсутствует e-mail"
            else if (exception.value === "No phone") errorMessage.value = "Отсутствует телефон"

            else if (exception.value === "No access") errorMessage.value = "Неверное имя пользователя или пароль"

            else if (exception.value === "Use email") errorMessage.value = "Пользователь с таким e-mail уже есть"
            else if (exception.value === "Use phone") errorMessage.value = "Пользователь с таким телефоном есть"

            else if (exception.value === "Invalid login") errorMessage.value = "Некорректный логин"
            else if (exception.value === "Invalid name") errorMessage.value = "Некорректное имя"
            else if (exception.value === "Invalid email") errorMessage.value = "Некорректный e-mail"
            else if (exception.value === "Invalid phone") errorMessage.value = "Некорректный телефон"

            else if (exception.value === "Timeout") errorMessage.value = "Мы уже исправляем эту ошибку"

            else if (exception.value === "Exception") errorMessage.value = "Проверьте данные и попробуйте ещё"
            else errorMessage.value = "Неизвестная ошибка, попробуйте позже"
        }
    }

    return {
        errorActive,
        errorMessage,
        error
    }

}
