<template>
  <section class="section s-bg-light-09 bg-cover background-bg6-jpg">
    <div class="container">
      <header class="section-header">
        <h2>F.A.Q.</h2>
      </header><!-- .section-header -->

      <div class="container-el">

        <h3 class="text-danger"><strong>1. Когда будет запуск системы? Что реализовано на данный момент?</strong></h3>
        <h4>На данный момент уже запущен начальный функционал (регистрация в системе с подтверждением e-mailа, вход,
          восстановление и изменение пароля, управление данными пользователя) и написан ряд модулей для работы с
          магазином (продукты, заказы и оплаты (банковскими картами и электронными кошельками) в соответствии с
          54-ФЗ) и рассылкой (управление контактами и группами, создание и отправка массовой e-mail рассылки).</h4>
        <h4>Более подробная информация размещена в разделе <a href="https://utp1.com/stages/" target="_blank">«Этапы
          создания»</a>.</h4>
        <h3 class="text-danger"><strong>2. Что делать до запуска системы? Можно ли уже сейчас начать
          зарабатывать?</strong></h3>
        <h4>Сейчас мы активно привлекаем потенциальных инвесторов для создания нашего проекта. И, каждый кто им станет –
          получает возможность участвовать в закрытой партнерской программе проекта и зарабатывать на привлечении новых
          инвесторов (люди со стороны не могут на этом зарабатывать).</h4>
        <h3 class="text-danger"><strong>3. Какие будут тарифы? Можно ли подключиться со скидкой по предзаказу?</strong>
        </h3>
        <h4>Стоимость тарифов является коммерческой тайной и не подлежит разглашению. Стоит иметь ввиду, что
          система рассчитана на премиум сегмент, со всеми исходящими от сюда выводами. Хотя мы будем
          сотрудничать так же с социальными компаниями и StartUpами. <strong>На данный момент подключиться
            нельзя,</strong> <span class="text-danger"><strong>НО</strong>, прямо сейчас можно получить тариф Business в пожизненное пользование без оплаты, если Вы станете
акционером проекта или получить тариф PRO <strong>на пожизненных условиях</strong>, если станете прямым инвестором.</span>
        </h4>
      </div>

    </div>
  </section>
</template>

<script>
export default {
  name: "section-12"
}
</script>

<style scoped>
.background-bg6-jpg {
  background-image: url(../../../../assets/images/utp1/bg/bg6.jpg);
}
</style>