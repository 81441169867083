// noinspection SpellCheckingInspection

import {onMounted, ref} from "vue";

export default function useRedirect(email) {

    const button = ref(false)

    function redirect() {
        if (
            email.value.indexOf('@mail.ru') !== -1 ||
            email.value.indexOf('@internet.ru') !== -1 ||
            email.value.indexOf('@bk.ru') !== -1 ||
            email.value.indexOf('@inbox.ru') !== -1 ||
            email.value.indexOf('@list.ru') !== -1
        ) {
            window.location.href = 'https://e.mail.ru/inbox/'
        } else if (
            email.value.indexOf('@yandex.ru') !== -1 ||
            email.value.indexOf('@yandex.ua') !== -1 ||
            email.value.indexOf('@ya.ru') !== -1 ||
            email.value.indexOf('@yandex.com') !== -1 ||
            email.value.indexOf('@yandex.kz') !== -1 ||
            email.value.indexOf('@yandex.by') !== -1
        ) {
            window.location.href = 'https://mail.yandex.ru/'
        } else if (
            email.value.indexOf('@gmail.com') !== -1
        ) {
            window.location.href = 'https://mail.google.com/'
        } else if (
            email.value.indexOf('@rambler.ru') !== -1 ||
            email.value.indexOf('@lenta.ru') !== -1 ||
            email.value.indexOf('@autorambler.ru') !== -1 ||
            email.value.indexOf('@myrambler.ru') !== -1 ||
            email.value.indexOf('@ro.ru') !== -1 ||
            email.value.indexOf('@rambler.ua') !== -1
        ) {
            window.location.href = 'https://mail.rambler.ru/'
        } else if (
            email.value.indexOf('@utp1.com') !== -1 ||
            email.value.indexOf('@utp1.ru') !== -1 ||
            email.value.indexOf('@consultagency.ru') !== -1 ||
            email.value.indexOf('@infobiz1.tv') !== -1 ||
            email.value.indexOf('@infob1.ru') !== -1 ||
            email.value.indexOf('@bootbiz.ru') !== -1
        ) {
            window.location.href = 'https://mail.yandex.ru/'
        } else {
            this.router.push('')
        }
    }

    const buttonRedirect = () => {
        button.value = !(email.value.indexOf('@mail.ru') === -1 &&
            email.value.indexOf('@internet.ru') === -1 &&
            email.value.indexOf('@bk.ru') === -1 &&
            email.value.indexOf('@inbox.ru') === -1 &&
            email.value.indexOf('@list.ru') === -1 &&

            email.value.indexOf('@yandex.ru') === -1 &&
            email.value.indexOf('@yandex.ua') === -1 &&
            email.value.indexOf('@ya.ru') === -1 &&
            email.value.indexOf('@yandex.com') === -1 &&
            email.value.indexOf('@yandex.kz') === -1 &&
            email.value.indexOf('@yandex.by') === -1 &&

            email.value.indexOf('@gmail.com') === -1 &&

            email.value.indexOf('@rambler.ru') === -1 &&
            email.value.indexOf('@lenta.ru') === -1 &&
            email.value.indexOf('@autorambler.ru') === -1 &&
            email.value.indexOf('@myrambler.ru') === -1 &&
            email.value.indexOf('@ro.ru') === -1 &&
            email.value.indexOf('@rambler.ua') === -1 &&

            email.value.indexOf('@utp1.com') === -1 &&
            email.value.indexOf('@utp1.ru') === -1 &&
            email.value.indexOf('@consultagency.ru') === -1 &&
            email.value.indexOf('@infobiz1.tv') === -1 &&
            email.value.indexOf('@infob1.ru') === -1 &&
            email.value.indexOf('@bootbiz.ru') === -1)
    }

    onMounted(buttonRedirect)

    return {
        redirect, buttonRedirect, button
    }

}
