<template>
  <div class="utp-doc-category">
    <div class="container">
      <h2 class="utp-doc-header">
        <span class="first">Проверка</span>
        <span class="second">Проверка подлинности документов</span>
        <hr>
      </h2>
      <div class="container-lg">
        <h3>Актуальная выписка из ЕГРЮЛ (ЕГРИП)</h3>
        <p>Для экономии Вашего времени инструкция предоставлена сразу со ссылками, но Вы можете выйти на эти
          страницы самостоятельно, используя поисковые системы, например <a href="https://www.google.com/?hl=ru"
                                                                            target="_blank">Google</a> или <a
              href="https://yandex.ru" target="_blank">Yandex</a>.</p>
        <ul class="list-ordered list-circle list-lg list-danger">
          <li>
            Заходите на сайт налоговой: <a href="https://www.nalog.ru" target="_blank">nalog.ru</a>;
          </li>
          <li>
            переходите в раздел <a href="https://www.nalog.ru/rn78/about_fts/el_usl/" target="_blank">сервисы</a>;
          </li>
          <li>
            выбираете <a href="https://egrul.nalog.ru/index.html" target="_blank">предоставление сведений из ЕГРЮЛ /
            ЕГРИП</a>;
          </li>
          <li>
            указываете ОГРН: 1187847233496 (для ИП: 313784726300110);
          </li>
          <li>
            выбираете регион <em>"78 - Санкт-Петербург"</em> (не обязательно);
          </li>
          <li>
            запрашиваете актуальную выписку на текущий момент.
          </li>
        </ul>
      </div>
      <h2 class="utp-doc-header">
        <hr>
      </h2>
      <div class="container-lg">
        <h3>Решение ЦБ РФ в отношении ценных бумаг</h3>
        <p>Для экономии Вашего времени инструкция предоставлена сразу со ссылками, но Вы можете выйти на эти
          страницы самостоятельно, используя поисковые системы, например <a href="#" target="_blank">Google</a> или
          <a href="#" target="_blank">Yandex</a>.</p>
        <ul class="list-ordered list-circle list-lg list-danger">
          <li>
            Заходите на сайт ЦБ РФ: <a href="https://www.cbr.ru" target="_blank">cbr.ru</a>;
          </li>
          <li>
            переходите в раздел <a href="http://www.cbr.ru/rbr/" target="_blank">решения Банка России</a>;
          </li>
          <li>
            выбираете подраздел <a href="http://www.cbr.ru/rbr/insideDKO/" target="_blank">решения Банка России в
            отношении финансовых инструментов</a>;
          </li>
          <li>
            указываете структурное подразделение <em>"Северо-Западное главное управление Центрального банка
            Российской Федерации"</em>;
          </li>
          <li>
            отмечаете дату с 26.11.2018 по 26.11.2018;
          </li>
          <li>
            запрашиваете данные и находите информацию об АО "УТП1".
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "docs-section-4"
}
</script>

<style scoped>

</style>