<template>
  <header-utp1>Этапы создания</header-utp1>
  <main class="utp-main" style="min-height: 300px;">
    <div class="container">

      <div class="timeline">

        <div class="item past">
          <div class="item-tooltip">Переход с классического в Интернет-бизнес</div>
          <div class="item-content">
            <h3>3 марта 2012 года</h3>
            <p>Пётр Громов создаёт Phoenix Consult Agency и 1 июля проводит свой первый вебинар. 20 сентября 2013 года
              регистрируется в качестве Индивидуального предпринимателя</p>
          </div>
        </div>

        <div class="item past">
          <div class="item-tooltip">Получение необходимых знаний и опыта в интернет-бизнесе <br>для реализации такой
            сложной
            задачи, как система UTP1
          </div>
          <div class="item-content">
            <h3>16 октября 2014 года</h3>
            <p>Запуск Интернет-телеканал InfoBiz1.TV для проведения вебинаров, и профессиональной видеостудии</p>
          </div>
        </div>

        <div class="item past">
          <div class="item-tooltip">Создание концепции системы</div>
          <div class="item-content">
            <h3>Май 2015 года</h3>
            <p>Приходит идея создания сервиса, придумывается название, и уже 17 июня покупается домен UTP1.COM.
              Оценочная стоимость проекта 100 000 000 рублей</p>
          </div>
        </div>

        <div class="item past">
          <div class="item-tooltip">Сбор функциональных требований</div>
          <div class="item-content">
            <h3>15 февраля 2017 года</h3>
            <p>После детальной проработки проводится прямой эфир с презентацией доработанный идеи, именно этот день и
              считается днём рождения проекта</p>
          </div>
        </div>

        <div class="item past">
          <div class="item-tooltip">Проработка дизайна и верстка <br>визуальной составляющей системы</div>
          <div class="item-content">
            <h3>27 февраля 2018 года</h3>
            <p>Запускается демонстрационная версия, на которой каждый может ознакомиться с планируемым
              функционалом</p>
          </div>
        </div>

        <div class="item past">
          <div class="item-tooltip">Активная фаза привлечения инвестиций</div>
          <div class="item-content">
            <h3>10 июля 2018 года</h3>
            <p>После публикации демонстрационной версии оценочная стоимость проекта вырастает в 3 раза, до 300 000 000
              рублей</p>
          </div>
        </div>

        <div class="item past">
          <div class="item-tooltip">Создание архитектуры Back-enda <br>MVP* и технической документации</div>
          <div class="item-content">
            <h3>21 августа 2018 года</h3>
            <p>Регистрируется Акционерное общество "Универсальная трейдинговая платформа" (АО "УТП1")</p>
          </div>
        </div>

        <div class="item past">
          <div class="item-tooltip">Начало практической реализации системы</div>
          <div class="item-content">
            <h3>12 сентября 2018 года</h3>
            <p>Команда разработчиков из ИТМО приступает к реализации первой версии Back-enda MVP системы</p>
          </div>
        </div>

        <div class="item past">
          <div class="item-tooltip">Передача акций инвесторам</div>
          <div class="item-content">
            <h3>23 ноября 2018 года</h3>
            <p>Банк России принял решение о государственной регистрации выпуска и отчёта об итогах выпуска акций</p>
          </div>
        </div>

        <div class="item past">
          <div class="item-tooltip">У акционеров появляется возможность <br>изнутри наблюдать за процессом реализации
          </div>
          <div class="item-content">
            <h3>30 декабря 2018 года</h3>
            <p>Введение в эксплуатацию первичного функционала, можно производить регистрацию, создавать продукты и
              контакты</p>
          </div>
        </div>

        <div class="item past">
          <div class="item-tooltip">Данный функционал был доступен пользователям до начала апреля 2022 года, после чего,
            в соответствии с принципом, описанным Фредериком Бруксом в книге Мифический человеко-месяц: «Планируйте
            выбросить первую версию - вам всё равно придётся это сделать» она была полностью удалена для возможности
            реализации обновлённой версии, соответствующей всем заявленным требованиям
          </div>
          <div class="item-content">
            <h3>2019 год</h3>
            <p>После успешного проведения тестов (с 16 мая, более 60 успешных транзакций), 22 мая официально запускается
              раздел «Магазин». 21 августа вводится в эксплуатацию раздел «Рассылка» (отправка первой массовой e-mail -
              рассылки средствами системы UTP1). 28 декабря организована первая массовая рассылка ВКонтакте средствами
              системы UTP1, запущена ALPHA-версии приложения «UTP1» ВКонтакте. С 31 декабря весь этот функционал может
              использоваться любым пользователем, прошедшим регистрацию</p>
          </div>
        </div>

        <div class="item past">
          <div class="item-tooltip">Получение необходимых знаний и опыта во всех сферах (программная инженерия,
            проектирование, архитектура, управление, Back-end, Front-end, DevOps, фреймворки и др.)
          </div>
          <div class="item-content">
            <h3>Май 2020 года</h3>
            <p>Петр Громов становится старшим разработчиком. С этого момента начинается разработка обновлённой версии
              системы UTP1 (переписывается 100% кода).</p>
          </div>
        </div>

        <div class="item past">
          <div class="item-tooltip">Внедрение системы контроля версий, собственных общих библиотек, подключаемых в
            качестве зависимостей, <br>паттернов, спецификаций и иных передовых подходов
          </div>
          <div class="item-content">
            <h3>2021 год</h3>
            <p>Написание микросервисов для работы с пользователями (регистрация, вход, изменение и сброс данных),
              магазином (продукты, заказы и оплаты (банковскими картами и электронными кошельками) в соответствии с
              54-ФЗ), рассылкой (управление контактами и группами, создание и отправка массовой e-mail рассылки)</p>
          </div>
        </div>

        <div class="item past">
          <div class="item-tooltip">Внесение изменений в Устав для более точного соответствия деятельности и оптимизации
            взаимодействия между Обществом и держателями акций
          </div>
          <div class="item-content">
            <h3>30 июня 2021 год</h3>
            <p>Проведение первого годового общего собрания акционеров с участием подавших на тот момент документов
              инвесторов (далее собрания будут проводится минимум раз в год)</p>
          </div>
        </div>

        <div class="item past">
          <div class="item-tooltip">Обновление всей документации (в соответствии с ГОСТами и методологиями программной
            инженерии) и архитектуры (главным архитектором стал Петр Громов. И именно его доклад по архитектуре стал
            победителем на Конгрессе молодых учёных)
          </div>
          <div class="item-content">
            <h3>Начало 2022 года</h3>
            <p>Написание скриптов автоматического деплоя (не привязанных к серверу), обновлённого Front-endа (на новом
              фреймворке), подключение написанных ранее микросервисов, настройка сервера (и хотя сейчас сервера
              находятся в Германии, в любой момент может быть произведена миграция в Россию без потери данных в течение
              менее 1-го рабочего дня)</p>
          </div>
        </div>

        <div class="item past">
          <div class="item-tooltip">Права на UTP1.com защищены Законом</div>
          <div class="item-content">
            <h3>5 мая 2022 года</h3>
            <p>Регистрация товарного знака (знака обслуживания) UTP1.com в Государственном реестре товарных знаков и
              знаков обслуживания Российской Федерации</p>
          </div>
        </div>

        <div class="item past">
          <div class="item-tooltip">Возможность регистрации в обновлённой системе</div>
          <div class="item-content">
            <h3>18 мая 2022 года</h3>
            <p>Запуск начального функционала (регистрация в системе с подтверждением e-mailа, вход, восстановление и
              изменение пароля, управление данными пользователя)</p>
          </div>
        </div>

        <div class="item past">
          <div class="item-tooltip">Теперь личный кабинет адаптирован в том числе под мобильные устройства (занялись мы
            этим именно в этот момент, потому что для внедрения нового дизайна надо полностью переписывать FrontEnd. И
            лучше переписать немного, и весь новый функционал сразу внедрять в концепции нового дизайна, нежели потом
            всё переделывать и потратить в 2 раза больше времени)
          </div>
          <div class="item-content">
            <h3>24 июня 2022 года</h3>
            <p>Презентация полностью переписанной вёрстки адаптивного личного кабинета (прежний дизайн был придуман 5
              лет назад, и уже слегка устарел. За основу новой версии был взят Bootstrap 5.2.0 (на момент внедрения
              находился в beta версии), т.е. самое современное решение. Ещё более важный момент заключается в том, что
              прежняя реализация не имела мобильной версии)</p>
          </div>
        </div>

        <div class="item past">
          <div class="item-tooltip">Был зачислен 17 августа 2020 года (после сдачи 4 августа вступительных экзаменов, на
            100 баллов из 100). Во время обучения выступал на конференциях и является победителем в номинации «За лучший
            доклад магистранта» на Конгрессе молодых учёных
          </div>
          <div class="item-content">
            <h3>30 июня 2022 года</h3>
            <p>После успешной защиты ВКР (14 июня 2022 года), с учётом исключительно отличных (5 «A») оценок на
              протяжении всего процесса обучения, Петр Громов получает «Красный» диплом с отличием и средним балмом 5.0
              о высшем образовании (магистр) в области IT-технологий Университета ИТМО (Мегафакультет компьютерных
              технологий и управления, Факультет программной инженерии и компьютерной техники)</p>
          </div>
        </div>

        <div class="item future">
          <div class="item-tooltip">Запуск массовой e-mail рассылки. Начало подготовки рассылки ВКонтакте
          </div>
          <div class="item-content">
            <h3>2 декабря 2022 года</h3>
            <p>С этого момента систему можно реально использовать по назначению. Да, пока это только рассылка на e-mail,
              но зато пока полностью бесплатно и без ограничений</p>
          </div>
        </div>

        <div class="item current active">
          <div class="item-content">
            <div class="active-content">Мы сейчас находимся здесь!</div>
          </div>
        </div>

        <div class="item future">
          <div class="item-tooltip">Возможность полноценного использования системы (функционала пока не так много, но
            его может быть достаточно для покрытия базовых потребностей)
          </div>
          <div class="item-content">
            <h3>Зима 2023 года</h3>
            <p>Запуск MVP, включающего в себя разделы «Магазин» (управление продуктами и заказами, приём платежей
              банковскими картами в соответствии с 54-ФЗ, приём переводов на электронные кошельки) и «Рассылка» (разовая
              массовая рассылка на e-mail и ВКонтакте, генерация формы подписки)</p>
          </div>
        </div>

        <div class="item future">
          <div class="item-tooltip">Автоматизация взаимодействия с подписчиками и привлечение новых подписчиков с
            использованием партнёрской программы
          </div>
          <div class="item-content">
            <h3>Лето 2023 года</h3>
            <p>Введение в эксплуатацию раздела «Сетевой маркетинг» (партнёрская программа), активация расширенной
              статистики и подключение модуля автоматической рассылки</p>
          </div>
        </div>

        <div class="item future">
          <div class="item-tooltip">Официальная реалиция проекта</div>
          <div class="item-content">
            <h3>Осень 2023 года - весна 2024 года</h3>
            <p>Запуск разделов «Сайт» (страницы, блог, виджеты) и «Обучение». Регистрация программного обеспечения в
              соответствии с Законодательством Российской Федерации (внесение в реестр программы для ЭВМ)</p>
          </div>
        </div>

        <div class="item future">
          <div class="item-tooltip">Предварительные планы, подлежащие корректировке</div>
          <div class="item-content">
            <h3>Лето - осень 2024 год</h3>
            <p>Внедрение дополнительного функционала, системы автоматизации привлечения, обновление политики и
              приоритетов в соответствии с текущим развитием рынка</p>
          </div>
        </div>

      </div><!-- .timeline -->

      <div class="utp-timeline-footer">
        <div class="alert alert-info text-center">
          <p>Обращаем Ваше внимание на то, что сроки введения в эксплуатацию могут быть изменены по объективным и/или не
            зависящим от нас причинам. Допускается изменение приоритетов в реализации того или иного функционала (исходя
            из заинтересованности акционеров и пользователей). Проект является высокотехнологичным с большой долей
            инноваций, поэтому не всегда представляется возможным абсолютно точно предугадать сроки. Благодарим за
            понимание!</p>
        </div>
      </div><!-- .utp-timeline-footer -->

      <p>* MVP (Minimum viable product) - Минимально жизнеспособный продукт</p>

    </div>
  </main>
</template>

<script>
import HeaderUtp1 from "@/components/HeaderUtp1";

export default {
  name: "stages",
  components: {HeaderUtp1}
}
</script>

<style scoped>

</style>