<template>
  <div id="section-1" class="section pt-30 pb-45">
    <div class="container">
      <header class="section-header">
        <h2>Кому будет полезна глобальная система UTP1</h2>
      </header><!-- .section-header -->
      <div class="row">
        <div class="col-md-4 col-sm-6">
          <div class="service-item background-1-jpg">
            <header class="service-item-header">
              <p class="service-item-title">Крупные, средние<br>и малые компании</p>
            </header>
            <div class="service-item-footer">
              <a @click="utpModal1" class="btn btn-danger btn-tabfocus" data-toggle="bootmodal">Подробнее</a>
            </div>
          </div>
        </div><!-- .col-xx-n -->
        <div class="col-md-4 col-sm-6">
          <div class="service-item background-2-jpg">
            <header class="service-item-header">
              <p class="service-item-title">Начинающие<br>предприниматели</p>
            </header>
            <div class="service-item-footer">
              <a @click="utpModal2" class="btn btn-danger" data-toggle="bootmodal">Подробнее</a>
            </div>
          </div>
        </div><!-- .col-xx-n -->
        <div class="col-md-4 col-sm-6">
          <div class="service-item background-3-jpg">
            <header class="service-item-header">
              <p class="service-item-title">Специалисты в области<br>продаж и привлечения</p>
            </header>
            <div class="service-item-footer">
              <a @click="utpModal3" class="btn btn-danger" data-toggle="bootmodal">Подробнее</a>
            </div>
          </div>
        </div><!-- .col-xx-n -->
        <div class="col-md-4 col-sm-6">
          <div class="service-item background-4-jpg">
            <header class="service-item-header">
              <p class="service-item-title">Инфобизнесмены,<br>продюсеры и коучи</p>
            </header>
            <div class="service-item-footer">
              <a @click="utpModal4" class="btn btn-danger" data-toggle="bootmodal">Подробнее</a>
            </div>
          </div>
        </div><!-- .col-xx-n -->
        <div class="col-md-4 col-sm-6">
          <div class="service-item background-5-jpg">
            <header class="service-item-header">
              <p class="service-item-title">Инвесторы, партнёры,<br>участники MLM</p>
            </header>
            <div class="service-item-footer">
              <a @click="utpModal5" class="btn btn-danger" data-toggle="bootmodal">Подробнее</a>
            </div>
          </div>
        </div><!-- .col-xx-n -->
        <div class="col-md-4 col-sm-6">
          <div class="service-item background-6-jpg">
            <header class="service-item-header">
              <p class="service-item-title">Конечные<br>потребители</p>
            </header>
            <div class="service-item-footer">
              <a @click="utpModal6" class="btn btn-danger" data-toggle="bootmodal">Подробнее</a>
            </div>
          </div>
        </div><!-- .col-xx-n -->
      </div><!-- .row -->
    </div><!-- .container -->
  </div>


  <!--  Modals-->
  <modal-1
      v-model:show=utpModal1Show
  />
  <modal-2
      v-model:show=utpModal2Show
  />
  <modal-3
      v-model:show=utpModal3Show
  />
  <modal-4
      v-model:show=utpModal4Show
  />
  <modal-5
      v-model:show=utpModal5Show
  />
  <modal-6
      v-model:show=utpModal6Show
  />
  <!--  End modals-->

</template>

<script>
import Modal1 from "@/components/Pages/Home/Modals/Modal1";
import Modal2 from "@/components/Pages/Home/Modals/Modal2";
import Modal3 from "@/components/Pages/Home/Modals/Modal3";
import Modal4 from "@/components/Pages/Home/Modals/Modal4";
import Modal5 from "@/components/Pages/Home/Modals/Modal5";
import Modal6 from "@/components/Pages/Home/Modals/Modal6";
import {ref} from "vue";

export default {
  name: "section-1",
  components: {Modal6, Modal5, Modal4, Modal3, Modal2, Modal1},
  setup() {
    const utpModal1Show = ref(false)
    const utpModal1 = () => {
      utpModal1Show.value = true
    }
    const utpModal2Show = ref(false)
    const utpModal2 = () => {
      utpModal2Show.value = true
    }
    const utpModal3Show = ref(false)
    const utpModal3 = () => {
      utpModal3Show.value = true
    }
    const utpModal4Show = ref(false)
    const utpModal4 = () => {
      utpModal4Show.value = true
    }
    const utpModal5Show = ref(false)
    const utpModal5 = () => {
      utpModal5Show.value = true
    }
    const utpModal6Show = ref(false)
    const utpModal6 = () => {
      utpModal6Show.value = true
    }

    return {
      utpModal1Show, utpModal1, utpModal2Show, utpModal2, utpModal3Show, utpModal3,
      utpModal4Show, utpModal4, utpModal5Show, utpModal5, utpModal6Show, utpModal6,
    }
  }
}
</script>

<style scoped>
.background-1-jpg {
  background-image: url(../../../../assets/images/utp1/ic/1.jpg);
}

.background-2-jpg {
  background-image: url(../../../../assets/images/utp1/ic/2.jpg);
}

.background-3-jpg {
  background-image: url(../../../../assets/images/utp1/ic/3.jpg);
}

.background-4-jpg {
  background-image: url(../../../../assets/images/utp1/ic/4.jpg);
}

.background-5-jpg {
  background-image: url(../../../../assets/images/utp1/ic/5.jpg);
}

.background-6-jpg {
  background-image: url(../../../../assets/images/utp1/ic/6.jpg);
}
</style>