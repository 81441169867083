import axios from "axios";
import {ref} from 'vue';

export default function useGetIp() {

    const geoIpLookupIo = ref({
        ip: "0.0.0.0",
        isp: null, // SkyNet LLC
        org: null, // SkyNet Networks
        hostname: null, // 188.243.182.210.pool.sknt.ru
        latitude: null, // 60.032
        longitude: null, // 30.285
        postal_code: null, // ""
        city: "Moscow",
        country_code: "RU",
        country_name: "Russia",
        continent_code: "EU",
        continent_name: "Europe",
        region: "Moscow",
        district: null, // Primorsky District
        timezone_name: "Europe\/Moscow",
        connection_type: null, // Cellular
        asn_number: null, // 35807
        asn_org: null, // SkyNet Ltd.
        asn: null, // AS35807 - SkyNet Ltd.
        currency_code: "RUB",
        currency_name: "Russian Ruble",
        success: null, // true
        premium: null // false
    })
    const getIp = async () => {
        await axios.get(`https://json.geoiplookup.io/`)
            .then(response => {
                geoIpLookupIo.value = response.data
            })
            .catch()
    }

    return {getIp, geoIpLookupIo}

}
