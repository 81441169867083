<template>
  <section class="section" id="section-13">
    <div class="container">
      <header class="section-header">
        <h2>FAQ (Ответы на часто задаваемые вопросы)</h2>
      </header><!-- .section-header -->
      <div class="row">
        <div class="col-md-4 col-sm-12">
          <div class="block-icon">
            <div class="text-center">
              <img src="../../../assets/images/invest/ic/ic20.jpg" class="img-responsive" alt="">
            </div>
            <h4 class="block-icon-title">Количество инвесторов</h4>
            <p class="block-icon-desc">Это относительное число и зависит от того, по каким программам и в какие периоды
              будут привлекаться инвесторы. <strong>Задача получить определённое количество чистых инвестиций</strong>.
              Для реализации необходимо привлечь до 4&nbsp;006 инвесторов</p>
          </div>
        </div><!-- .col-xx-n -->
        <div class="col-md-4 col-sm-12">
          <div class="block-icon">
            <div class="text-center">
              <img src="../../../assets/images/invest/ic/ic21.jpg" class="img-responsive" alt="">
            </div>
            <h4 class="block-icon-title">Финансирование системы</h4>
            <p class="block-icon-desc">6%&nbsp;-&nbsp;подоходный налог по УСН, 5%&nbsp;-&nbsp;комиссия платёжной
              системы, 5%&nbsp;-&nbsp;комиссия банка, 5%&nbsp;-&nbsp;call-центр. Из оставшихся (79%): 40%&nbsp;-&nbsp;комиссия
              1-го уровня, 20%&nbsp;-&nbsp;комиссия 2-го уровня, <strong>40%&nbsp;-&nbsp;на создание самой системы
                UTP1</strong></p>
          </div>
        </div><!-- .col-xx-n -->
        <div class="col-md-4 col-sm-12">
          <div class="block-icon">
            <div class="text-center">
              <img src="../../../assets/images/invest/ic/ic22.jpg" class="img-responsive" alt="">
            </div>
            <h4 class="block-icon-title">Распределение долей</h4>
            <p class="block-icon-desc">В зависимости от выбранной программы инвестирования происходит <strong>следующим
              образом</strong>: &laquo;Прямой инвестор&raquo; - 200 акций, &laquo;Прямой инвестор Light&raquo; - 50
              акций, &laquo;Дистрибьютор&raquo; - 3 пакета по 3 акции = 9 акций, &laquo;Партнёр&raquo; - 3 акции</p>
          </div>
        </div><!-- .col-xx-n -->
      </div><!-- .row -->
      <p class="text-center">
        <a href="#section-3" class="btn btn-el btn-danger btn-scroll">Стать акционером!</a>
      </p>
    </div><!-- .container -->
  </section>
</template>

<script>
export default {
  name: "invest-section-13"
}
</script>

<style scoped>

</style>