<template>
  <section class="section">
    <div class="container">
      <h4>Вы получите колоссальный опыт, это очень здорово. При этом Вам, скорее всего, придется раз шесть ошибиться. Вы
        потеряете примерно 50 – 100 тысяч рублей. Но зато приобретете столько опыта! Главное, не останавливаться, иначе
        не получите результат</h4>
      <h3>Но если Вы хотите создать своё дело гарантированно, качественно и быстро, зарегистрировавшись всего в одном
        сервисе, не тратя сил, времени и денег на интеграции, подключайтесь к системе UTP1. Мы знаем, как это
        делается!</h3>

      <div class="row">
        <div class="col-md-6 mt-30">
          <img src="../../../../assets/images/utp1/other/03_00.png" alt="" class="img-responsive w-500 ml-auto mr-auto">

        </div>
        <div class="col-md-6 mt-30">
          <ul class="list-icon list-danger">
            <li>есть надёжные сервисы, но очень сложные и без всего необходимого функционала, требующие дополнительных
              специалистов и интеграций;
            </li>
            <li>есть сервисы с достаточно полным инструментарием, но подводящие в самый неподходящий момент и технически
              сложные, так что снова необходим дополнительный специалист;
            </li>
            <li>есть интуитивно понятные сервисы, но без всего необходимого функционала и не всегда надёжные.</li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "section-9"
}
</script>

<style scoped>

</style>