<template>
  <div class="modal fade utp-modal-1 in dialog-style modal-open" v-if="showGosa" @click="modalClose">
    <div @click.stop class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button @click="modalClose" type="button" class="close" data-dismiss="modal">
            <span>&times;</span>
          </button>
          <h3 class="modal-title text-danger">Итоги ГОСА 2022 (за 2021 год)</h3>
        </div>
        <div class="modal-body">
          <div class="row pt-0 pb-0">
            <div class="container-sm">
              <p>30 июня 2022 года состоялось общее годовое собрание акционеров АО «УТП1» по итогам работы за 2021 год.</p>
              <p>Ознакомиться с отчётом об итогах голосования: <strong><a @click="report">ОЗНАКОМИТЬСЯ!</a></strong></p>
              <p>Более подробная информация размещена в личном блоге Петра Громова: <strong><a @click="blog">ПЕРЕЙТИ!</a></strong></p>
            </div>
          </div><!-- .row -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "modal-gosa",
  props: {
    showGosa: {
      type: Boolean,
      default: false
    }
  },
  setup(_, {emit}) {
    const modalClose = () => {
      emit('update:showGosa', false)
    }
    const report = () => {
      window.open('https://files.utp1.com/docs/utp1.com/agms/2021_2022/report.pdf', '_blank')
    }
    const blog = () => {
      window.open('https://consultagency.ru/itogi-gosa-2022-za-2021-god/', '_blank')
    }
    return {modalClose, report, blog}
  }
}
</script>

<style scoped>

</style>