<template>
  <footer class="footer">

		<span class="delimiter delimiter-from-bottom z-index-3">
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 190 400 20">
				<polygon fill="#212121" points="0,190 400,210 0,210 "></polygon>
			</svg>
		</span>

    <div class="footer-top">
      <ul class="social-menu social-menu-circle social-menu-light social-menu-lg">
        <li>
          <a @click="vk">
            <i class="fab fa-vk"></i>
          </a>
        </li>
        <li>
          <a @click="telegram">
            <i class="fab fa-telegram"></i>
          </a>
        </li>
        <li>
          <a @click="youTube">
            <i class="fab fa-youtube"></i>
          </a>
        </li>
        <!--        <li>
                  <a @click="instagram">
                    <i class="fab fa-instagram"></i>
                  </a>
                </li>
                <li>
                  <a @click="facebook">
                    <i class="fab fa-facebook"></i>
                  </a>
                </li>-->
      </ul><!-- .social-menu -->
    </div><!-- .footer-top -->
    <nav class="navbar-footer">
      <div class="container">
        <ul class="footer-menu">
          <li>
            <a @click="copyright">© 2015 - {{ new Date().getFullYear() }}
              <strong>UTP1.com</strong> Все права защищены</a>
          </li>
          <li>
            <a @click="offer">Публичная оферта</a>
          </li>
          <li>
            <a @click="privacy">Политика конфиденциальности</a>
          </li>
          <li>
            <a class="non-click">Партнерская программа</a>
          </li>
        </ul>
      </div><!-- .container -->
    </nav><!-- .navbar-footer -->
    <div class="footer-bottom">
      <div class="container">
        <p>АО «УТП1»; <span>ОГРН: 1187847233496,</span> <span>ИНН / КПП: 7811705340 / 781101001.</span> <span
            class="footer-contact"><span><i class="fa fa-phone"></i> +7 921 875-00-70,</span> <span><i
            class="fa fa-envelope"></i> info@consultagency.ru</span></span></p>
      </div><!-- .container -->
    </div><!-- .footer-bottom -->
  </footer>
  <!-- End Footer -->

  <!-- Scroll to top Button -->
  <div @click="$router.push('')" class="btn-totop">
    <i class="fas fa-chevron-up"></i>
  </div>

  <!-- VK Widget -->
  <div id="vk_community_messages"></div>
</template>

<script>
import router from "@/router";

export default {
  name: "footer-utp1",
  setup() {
    const copyright = () => {
      router.push('/')
    }
    const offer = () => {
      router.push('/docs')
    }
    const privacy = () => {
      window.open('https://utp1.com/privacy', '_blank')
    }
    const affiliate = () => {
      window.open('https://infob1.ru/', '_blank')
    }

    const vk = () => {
      window.open('https://vk.com/infobiz1_tv', '_blank')
    }
    const telegram = () => {
      window.open('https://t.me/utp_1', '_blank')
    }
    const youTube = () => {
      window.open('https://www.youtube.com/user/narpetri?sub_confirmation=1', '_blank')
    }
    const instagram = () => {
      window.open('https://www.instagram.com/petr.gromov/', '_blank')
    }
    const facebook = () => {
      window.open('https://www.facebook.com/infobiz1tv/', '_blank')
    }

    return {copyright, offer, privacy, affiliate, vk, telegram, youTube, instagram, facebook}

  }
}
</script>

<style scoped>

</style>
