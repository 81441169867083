export const utm = {
    state: () => ({
        utm: {
            medium: null,
            source: null,
            campaign: null,
            content: null,
            term: null,
            setUtm: false
        }
    }),
    getters: {},
    mutations: {
        setUtm(state, utm) {
            if (!state.utm.setUtm) {
                state.utm.medium = utm.medium
                state.utm.source = utm.source
                state.utm.campaign = utm.campaign
                state.utm.content = utm.content
                state.utm.term = utm.term
                state.utm.setUtm = true
            }
        }
    },
    actions: {},
    namespaced: true
}
