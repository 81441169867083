<template>
  <section class="section pb-0" id="section-1">
    <div class="container">
      <header class="section-header">
        <h2>Инвестиции в систему UTP1</h2>
      </header><!-- .section-header -->
      <div class="card card-p">
        <div class="row">
          <div class="col-sm-4 col-sm-push-8 text-center-xs">
            <img src="../../../assets/images/invest/products/utp1_invest.png" alt="Product Image" class="img-responsive">
          </div>
          <div class="col-sm-8 col-sm-pull-4">
            <h2>Программа «Прямые инвестиции (Инвестор)»</h2>
            <ul class="list-ordered list-circle list-success">
              <li>Акций на 600 000 рублей (0,2% стоимости компании);</li>
              <li>50% дохода со всех Ваших продаж (партнёрская комиссия первого уровня);</li>
              <li>25% дохода со всех продаж привлечённых Вами инвесторов (второй&nbsp;уровень);</li>
              <li>Эксклюзивные права на франшизу</li>
              <li>Необходимые материалы и обучение</li>
              <li>Персональный менеджер и внеочередная поддержка</li>
              <li>Настройка рекламной компании</li>
              <li>Предоставление тестового рекламного бюджета</li>
              <li>Персональная коучинг-сессия с Петром Громовым</li>
            </ul>
          </div>
        </div><!-- .row -->
        <hr class="hr-special">
        <div class="row">
          <div class="col-sm-4 text-center-xs">
            <img src="../../../assets/images/invest/products/utp1_invest.png" alt="Product Image" class="img-responsive">
          </div>
          <div class="col-sm-8">
            <h2>Программа «Прямые инвестиции Light (ПИ Light)»</h2>
            <ul class="list-ordered list-circle list-success">
              <li>Акций на 150 000 рублей (0,05% стоимости компании);</li>
              <li>50% дохода со всех Ваших продаж (партнёрская комиссия первого&nbsp;уровня);</li>
              <li>25% дохода со всех продаж привлечённых Вами инвесторов (второй&nbsp;уровень);</li>
              <li>Эксклюзивные права на франшизу</li>
              <li>Необходимые материалы и обучение</li>
              <li>Персональный менеджер и внеочередная поддержка</li>
              <li>Настройка рекламной компании</li>
            </ul>
          </div>
        </div><!-- .row -->

      </div>
    </div><!-- .container -->
  </section>
</template>

<script>
export default {
  name: "invest-section-1"
}
</script>

<style scoped>

</style>