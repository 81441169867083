import {onMounted, ref} from "vue";

export default function useGetDataExtractor() {

    const dataExtractor = ref({
        timezone: "UTC_P3",
        language: "RU",
        os: "",
        osVersion: "",
        browser: ""
    })

    // noinspection JSUnresolvedVariable
    const module = {
        header: [navigator.userAgent, window.opera],
        dataOs: [
            {name: 'IPADOS', value: 'iPad', version: 'OS'},
            {name: 'IOS', value: 'iPhone', version: 'OS'},
            {name: 'ANDROID', value: 'Android', version: 'Android'},
            {name: 'WIN_MOB', value: 'Windows Phone', version: 'OS'},
            {name: 'WIN', value: 'Win', version: 'NT'},
            {name: 'MAC', value: 'Mac', version: 'OS X'},
            {name: 'LINUX', value: 'Linux', version: 'rv'},
        ],
        dataBrowser: [
            {name: 'EXPLORER', value: 'MSIE', version: 'MSIE'},
            {name: 'CHROME', value: 'Chrome', version: 'Chrome'},
            {name: 'OPERA', value: 'Opera', version: 'Opera'},
            {name: 'SAFARI', value: 'Safari', version: 'Version'},
            {name: 'FIREFOX', value: 'Firefox', version: 'Firefox'},
            {name: 'FIREFOX', value: 'Mozilla', version: 'Mozilla'},
        ],
        init: function () {
            const agent = this.header.join(' '),
                os = this.matchItem(agent, this.dataOs),
                browser = this.matchItem(agent, this.dataBrowser);
            return {os: os, browser: browser};
        },
        matchItem: function (string, data) {
            // noinspection SpellCheckingInspection
            let i,
                j = 0,
                regex,
                regexv,
                match,
                matches,
                version;
            for (i = 0; i < data.length; i += 1) {
                regex = new RegExp(data[i].value, 'i');
                match = regex.test(string);
                if (match) {
                    regexv = new RegExp(data[i].version + '[- /:;]([\\d._]+)', 'i');
                    matches = string.match(regexv);
                    version = '';
                    if (matches) {
                        if (matches[1]) {
                            matches = matches[1];
                        }
                    }
                    if (matches) {
                        matches = matches.split(/[._]+/);
                        for (j = 0; j < matches.length; j += 1) {
                            if (j === 0) {
                                version += matches[j] + '.';
                            } else {
                                version += matches[j];
                            }
                        }
                    } else {
                        version = '0';
                    }
                    return {
                        name: data[i].name,
                        version: parseFloat(version)
                    };
                }
            }
            return {name: 'OTHER', version: 0};
        }
    };
    let e = module.init();

    function getTimezoneCode() {
        let timeZone;
        timeZone = new Date().toString().replace(/^.*GMT([+-]?)(\d{2})(\d{2}).*$/g, "UTC_$1$2$3")
            .replace('+', 'P')
            .replace('-', 'M')
            .replace('00', '')
            .replace('00', '')
        if (timeZone.length === 5) return timeZone.substring(0, 3)
        else if (timeZone[5] === '0') return timeZone.replace('0', '')
        else return timeZone
    }

    function getDataExtractor() {
        dataExtractor.value.timezone = getTimezoneCode()
        dataExtractor.value.language = navigator.language.substring(3, 5).toUpperCase() ||
            navigator.language.substring(0, 2).toUpperCase()
        dataExtractor.value.os = e.os.name
        dataExtractor.value.osVersion = e.os.version
        dataExtractor.value.browser = e.browser.name
    }

    onMounted(getDataExtractor)

    return {dataExtractor}

}
