<template>
  <section class="section section-steps s-bg-light-09 bg-cover background-bg3-jpg">
    <div class="container">
      <header class="section-header">
        <h2>На каком этапе развития бизнеса<br class="hidden-xs hidden-sm"> наш сервис будет полезен</h2>
      </header><!-- .section-header -->
      <div class="steps-container">

        <img src="../../../../assets/images/utp1/other/02_00.png" alt="" class="img-step">
        <div class="step-item step-item-5">
          <span>Лучший в своей нише?</span>
        </div>
        <div class="step-item step-item-4">
          <span>Признанный эксперт?</span>
        </div>
        <div class="step-item step-item-3">
          <span>Уже есть результаты?</span>
        </div>
        <div class="step-item step-item-2">
          <span>Предпринимаете попытки?</span>
        </div>
        <div class="step-item step-item-1">
          <span>Думаете создать своё дело?</span>
        </div>

      </div>
      <div class="container-el">
        <h3 class="text-danger">Наш сервис полезен при ведении бизнеса через интернет на любом этапе развития!</h3>
        <ul class="list-icon list-danger">
          <li>Поможем с нуля начать своё дело в интернете;</li>
          <li>Превратим бизнес из рутины в удовольствие;</li>
          <li>В разы увеличим результаты и поможем масштабироваться.</li>
        </ul>
        <h4>Для тех, кто уже ведёт свой бизнес через другие сервисы, подразумевается переход на лояльных условиях с
          переносом всех легально собранных баз и существенных данных.</h4>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "section-6"
}
</script>

<style scoped>
.background-bg3-jpg {
  background-image: url(../../../../assets/images/utp1/bg/bg3.jpg);
}
</style>