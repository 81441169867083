import axios from "axios";
import {ref} from 'vue';

export default function useRegister(register) {

    const registerLoader = ref(false)
    const registerException = ref("")
    const hideEmail = ref("")
    const registered = ref(false)

    const registerUtp1 = async () => {
        registerException.value = ""
        if (register.value.name === "") registerException.value = "No name"
        else if (register.value.email === "") registerException.value = "No email"
        else if (register.value.phone === "") registerException.value = "No phone"
        else {
            registerLoader.value = true
            await axios.post(`https://api.utp1.com/users/login/register`, register.value)
                .then(response => {
                    register.value.email = ""
                    register.value.name = ""
                    register.value.phone = ""
                    hideEmail.value = response.data.email
                    registered.value = true
                })
                .catch(e => {
                    try {
                        registerException.value = e.response.data.error
                    } catch {
                        registerException.value = "Exception"
                    }
                })
                .finally(() => registerLoader.value = false)
        }
    }

    return {
        registerUtp1,
        registerLoader,
        registerException,
        hideEmail,
        registered
    }

}
