import {createRouter, createWebHistory} from 'vue-router'
import Home from '../views/Home.vue'
import NotFound from "../views/NotFound";
import Team from "@/views/Team";
import About from "@/views/About";
import Reviews from "@/views/Reviews";
import Invest from "@/views/Invest";
import Join from "@/views/Join";
import Docs from "@/views/Docs";
import Stages from "@/views/Stages";

const routes = [
    {
        path: '/',
        name: 'Главная страница',
        component: Home
    },
    {
        path: '/team',
        name: 'Команда',
        component: Team
    },
    {
        path: '/reviews',
        name: 'Отзывы',
        component: Reviews
    },
    {
        path: '/about',
        name: 'Об авторе',
        component: About
    },
    {
        path: '/invest',
        name: 'Присоединиться',
        component: Invest
    },
    {
        path: '/join',
        name: 'Специальное предложение',
        component: Join
    },
    {
        path: '/docs',
        name: 'Документация',
        component: Docs
    },
    {
        path: '/stages',
        name: 'Этапы создания',
        component: Stages
    },
    {
        path: '/:pathMatch(.*)*',
        name: '404 NotFound',
        component: NotFound,
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    scrollBehavior() {
        document.getElementById('app').scrollIntoView();
    },
    routes
})

router.beforeEach((to) => {
    window.document.title = to.name ? to.name + ' | UTP1' : 'UTP1.com'
})

export default router
