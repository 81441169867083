<template>
  <section class="section s-bg-light-09 bg-cover background-bg5-jpg">
    <div class="container">
      <header class="section-header">
        <h2>Как мы пришли к идее создания системы UTP1:</h2>
      </header><!-- .section-header -->

      <header class="container-el">
        <blockquote>
          <p class="h3">Я, как не многие, очень хорошо знаю, что такое ведение бизнеса в интернете. Когда необходимо
            между собой интегрировать различные сервисы, в которых черт ногу сломит. А, они еще не всегда
            адекватно интегрируются, зато все стоят денег.</p>
          <p class="h3">Мне пришлось во всём разобраться, более того, у меня есть слаженная команда специалистов, мы
            оплачиваем все сервисы. А как быть начинающим предпринимателям?</p>
          <p class="h3">15 февраля 2017 года мы начали разрабатывать Международную систему UTP1, лучший сервис
            автоматизации всех бизнес-процессов в интернете.</p>
          <p class="h3">Я уже много лет занимаюсь интернет бизнесом и досконально изучил весь рынок, поэтому могу с
            уверенностью сказать, что предложенное нами решение не имеет аналогов как в России, так и за Рубежом</p>
          <p class="h3">Я считаю, что абсолютно любой человек может заниматься бизнесом, для этого ему достаточно
            совершить осознанный выбор. И задача, которую я перед собой ставлю, заключается в том, чтобы помочь каждому,
            кто хочет заниматься своим делом в интернете. Да, сейчас это может быть достаточно сложно с технической
            стороны, но тогда эту преграду необходимо преодолеть!</p></blockquote>
        <p class="text-right gromov-sign"><span>Пётр Громов</span></p>

      </header>

    </div>
  </section>
</template>

<script>
export default {
  name: "section-10"
}
</script>

<style scoped>
.background-bg5-jpg {
  background-image: url(../../../../assets/images/utp1/bg/bg5.jpg);
}
</style>