<template>
  <section class="section hidden" id="section-4">
  </section>
</template>

<script>
export default {
  name: "invest-section-4"
}
</script>

<style scoped>

</style>