<template>
  <section class="section s-bg-light-09 bg-cover background-bg4-jpg">
    <div class="container">
      <header class="section-header">
        <h2>Можно самостоятельно автоматизировать все бизнес-процессы. Для этого необходимо:</h2>
      </header><!-- .section-header -->
      <div class="row">
        <div class="col-md-6">
          <ul class="list-ordered list-circle list-danger">
            <li>зарегистрироваться на ряде рассыльщиков:
              <ul class="list-letter">
                <li>E-mail;</li>
                <li>ВКонтакте;</li>
                <li>Facebook;</li>
                <li>PUSH;</li>
                <li>и, желательно, другие;</li>
              </ul>
            </li>
            <li>интегрировать их между собой:
              <ul class="list-letter">
                <li>для этого, скорее всего,<br> придётся нанять специалиста;</li>
                <li>но базы всё равно будут разные;</li>
              </ul>
            </li>
            <li>создать необходимые сайты и страницы:
              <ul class="list-letter">
                <li>лучше всего нанять специалиста (двух),<br> которые нарисуют адаптивный дизайн<br> и сверстают
                  качественные страницы;
                </li>
                <li>можно использовать готовые генераторы,<br> но качество и конверсия<br> будут сильно страдать,<br>
                  так как без знания HTML и CSS<br> сделать действительно качественный адаптивный<br> сайт не получится;
                </li>
                <li>для создания сайтов и страниц необходимо купить хостинг и домен;</li>
              </ul>
            </li>
          </ul>

        </div>
        <div class="col-md-6">
          <ul class="list-ordered list-circle list-danger list-start-3">
            <li>купить и установить плагины, повышающие конверсию:
              <ul class="list-letter">
                <li>пупапы и комбекеры;</li>
                <li>таймеры;</li>
                <li>комментарии;</li>
                <li>обратная связь;</li>
                <li>и многие другие;</li>
              </ul>
            </li>
            <li>подключить магазин с дополнительным функционалом:
              <ul class="list-letter">
                <li>приём платежей;</li>
                <li>управление заказами;</li>
                <li>передача заказов Call-центру;</li>
              </ul>
            </li>
            <li>создать блог:
              <ul class="list-letter">
                <li>установить на свой хостинг;</li>
                <li>прикрутить дизайн (лучше заказать у дизайнера);</li>
                <li>настроить функционал (лучше воспользоваться помощью профессионалов);</li>
                <li>перенастроить функционал (под себя, с первого раза, однозначно, не получится);</li>
                <li>доработать дизайн и оптимизировать для CEO;</li>
              </ul>
            </li>
            <li>начать заниматься интернет-бизнесом:
              <ul class="list-letter">
                <li>привлечь первых потенциальных клиентов;</li>
                <li>отправить им серию писем (её надо будет разработать и загрузить в сервис);</li>
                <li>c. закрыть продажи и получить первую прибыль.</li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "section-8"
}
</script>

<style scoped>
.background-bg4-jpg {
  background-image: url(../../../../assets/images/utp1/bg/bg4.jpg);
}
</style>