<template>
  <nav class="navbar navbar-scroll navbar-bootbiz navbar-fixed-top bg-dark-07">
    <div class="container">
      <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar-collapse"
              aria-expanded="false">
        <i class="fa fa-bars"></i>
      </button>
      <div class="navbar-logo">
        <a @click="$router.push('/')">
					<span class="logo-img">
						<img src="../assets/images/logo.png" alt="UTP1.COM">
					</span>
          <span class="logo-text">Система <strong>UTP1</strong></span>
        </a>
      </div><!-- .logo -->
      <div class="collapse navbar-collapse navbar-right" id="navbar-collapse">
        <ul class="nav navbar-nav">
          <li :class="section==='team'? 'active' : ''"><a @click="$router.push('/team')">Команда</a></li>
          <li :class="section==='about'? 'active' : ''"><a @click="$router.push('/about')">Об авторе</a></li>
          <li :class="section==='reviews'? 'active' : ''"><a @click="$router.push('/reviews')">Отзывы</a></li>
          <li :class="section==='invest'? 'active' : ''"><a @click="$router.push('/invest')">Присоединиться</a></li>
          <li :class="section==='docs'? 'active' : ''"><a @click="$router.push('/docs')">Документация</a></li>
          <li :class="section==='stages'? 'active' : ''"><a @click="$router.push('/stages')">Этапы создания</a></li>
          <li><a @click="modal" class="btn-navbar" data-toggle="modal" data-target=".modal-login">Вход</a></li>
        </ul>
      </div><!-- .navbar-collapse -->
    </div><!-- .container -->
  </nav>
  <login-modal
      :show="show"
  />
<!--  <modal-gosa
      v-model:showGosa="showGosa"
  />-->
</template>

<script>
import {ref, watch} from "vue";
import LoginModal from "@/components/UI/LoginModal";
import {useRoute} from "vue-router";
import ModalGosa from "@/components/Pages/ModalGOSA";

export default {
  name: "navbar-utp1",
  components: {ModalGosa, LoginModal},
  setup() {
    const route = useRoute()
    const path = () => {
      return route.path.substring(1).split('/')[0].toLowerCase()
    }
    const section = ref(path())
    watch(route, () => {
      section.value = path()
    })
    const show = ref(false)
    const showGosa = ref(true)
    const modal = () => {
      show.value = true
    }
    return {show, modal, section, showGosa}
  }
}
</script>

<style scoped>

</style>