<template>
  <section class="section">
    <div class="container">
      <div class="container-el">
        <h2 class="text-danger text-center mw-700 ml-auto mr-auto">Откуда берут начала сложности ведения бизнеса через
          интернет в текущих реалиях:</h2>
        <p>Некогда развивающиеся CRM системы для инфобизнеса, завоевав своих клиентов, снизили темпы
          роста, тем самым стали сильно отставать от быстро развивающегося рынка. Любой рынок, он как эскалатор,
          едущий вниз. Для того, чтобы только находиться на месте, необходимо идти вверх, а чтобы развиваться, надо
          бежать. А стоит хотя бы немного замедлиться, сразу начинаешь отставать. Как результат, конверсии падают,
          страницы, созданные с помощью генераторов, неадекватно выглядят на многих устройствах и экранах,
          появляется необходимости в ещё больших количествах интеграций, и <strong>Вы в этом не виноваты</strong>.</p>
        <h2 class="text-danger text-center mw-700 ml-auto mr-auto mt-30">Давайте рассмотрим один из множества примеров,
          с которыми можно столкнуться:
        </h2>
        <p>Сейчас можно настроить автоворонки не только e-mail, но также в социальных сетях или месседжерах.
          Мало того, что надо вручную несколько раз набирать и настраивать одно и то же, так может случиться, что по
          одному каналу человек получает уже 2-ой или 3-ий этап, а по другому каналу он ещё застрял на первом, в
          итоге заваливаете его кучей писем и сообщений, в результате получаете негатив в Вашу сторону.</p>
        <p>Или человек у Вас что-то купил, указав e-mail, а Вы продолжаете отправлять ему письма на другие
          каналы с предложением купить. А потом делаете распродажу, например, урезанного комплекта, и его видят в
          том числе те, кто купил полную версию. Снова негатив в Ваш адрес. <strong>А ведь это лишь всего один
            пример</strong>.</p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "section-7"
}
</script>

<style scoped>

</style>