<template>
  <section class="section">
    <div class="container">
      <header class="section-header">
        <h2>Какую выгоду мы принесём клиентам</h2>
      </header><!-- .section-header -->
      <div class="row">
        <div class="col-md-3 col-sm-6">
          <div class="block-icon">
            <div class="block-icon-image">
              <img src="../../../../assets/images/utp1/ic/ic7.png" alt="">
            </div>
            <h4 class="block-icon-title">ON-LINE МАГАЗИН</h4>
            <p class="block-icon-desc">Множество способов приёма оплат 24 на 7. «Умная» автоматизация Call-центра</p>
          </div>
        </div><!-- .col-xx-n -->
        <div class="col-md-3 col-sm-6">
          <div class="block-icon">
            <div class="block-icon-image">
              <img src="../../../../assets/images/utp1/ic/ic8.png" alt="">
            </div>
            <h4 class="block-icon-title">«УМНЫЕ» РАССЫЛКИ</h4>
            <p class="block-icon-desc">Отправка e-mail, VK, FB и др., визуализация автоматической или запусковой серии
              писем</p>
          </div>
        </div><!-- .col-xx-n -->
        <div class="col-md-3 col-sm-6">
          <div class="block-icon">
            <div class="block-icon-image">
              <img src="../../../../assets/images/utp1/ic/ic9.png" alt="">
            </div>
            <h4 class="block-icon-title">КАЧЕСТВЕННЫЕ САЙТЫ</h4>
            <p class="block-icon-desc">Создание адаптивных сайтов и страниц с максимальной конверсией за пару кликов</p>
          </div>
        </div><!-- .col-xx-n -->
        <div class="col-md-3 col-sm-6">
          <div class="block-icon">
            <div class="block-icon-image">
              <img src="../../../../assets/images/utp1/ic/ic10.png" alt="">
            </div>
            <h4 class="block-icon-title">МАРКЕТИНГ</h4>
            <p class="block-icon-desc">Все передовые технологии, повышающие продажи, уже встроены в систему</p>
          </div>
        </div><!-- .col-xx-n -->
      </div><!-- .row -->

      <div class="row row-sm-clear-left">
        <div class="col-md-3 col-sm-6">
          <div class="block-icon">
            <div class="block-icon-image">
              <img src="../../../../assets/images/utp1/ic/ic11.png" alt="">
            </div>
            <h4 class="block-icon-title">АНАЛИТИКА</h4>
            <p class="block-icon-desc">Сквозная аналитика с возможностью отслеживания и управления всеми цифрами</p>
          </div>
        </div><!-- .col-xx-n -->
        <div class="col-md-3 col-sm-6">
          <div class="block-icon">
            <div class="block-icon-image">
              <img src="../../../../assets/images/utp1/ic/ic12.png" alt="">
            </div>
            <h4 class="block-icon-title">Специалисты</h4>
            <p class="block-icon-desc">Возможность делегирования бизнес-процессов профессионалам в один клик</p>
          </div>
        </div><!-- .col-xx-n -->
        <div class="col-md-3 col-sm-6">
          <div class="block-icon">
            <div class="block-icon-image">
              <img src="../../../../assets/images/utp1/ic/ic13.png" alt="">
            </div>
            <h4 class="block-icon-title">ПАРТНЁРКА</h4>
            <p class="block-icon-desc">Широкая сеть партнёров, работающих под процент, или собственная MLM-структура</p>
          </div>
        </div><!-- .col-xx-n -->
        <div class="col-md-3 col-sm-6">
          <div class="block-icon">
            <div class="block-icon-image">
              <img src="../../../../assets/images/utp1/ic/ic14.png" alt="">
            </div>
            <h4 class="block-icon-title">Поддержка 24 / 7</h4>
            <p class="block-icon-desc">Даже при очень простом и интуитивном интерфейсе мы готовы помочь в любое
              время</p>
          </div>
        </div><!-- .col-xx-n -->
      </div><!-- .row -->
    </div><!-- .container -->
  </section>
</template>

<script>
export default {
  name: "section-3"
}
</script>

<style scoped>

</style>