<template>
  <header-utp1>Команда</header-utp1>
  <main class="utp-main">
    <div class="utp-team">

      <div class="utp-team-category">
        <div class="container">
          <h2 class="utp-team-header">
            <span class="first">Управление</span>
            <span class="second">Генеральный Директор</span>
            <hr>
          </h2>
          <div class="utp-team-list row">

            <div class="utp-team-col col-xs-12 col-lg-4 col-sm-6">
              <div class="utp-team-item utp-team-item-large">
                <div class="utp-team-img">
                  <img src="../assets/images/team/petr-gromov.jpg" alt="">
                </div>
                <h3 class="utp-team-name">Пётр Вениаминович Громов</h3>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div class="utp-team-category">
        <div class="container">
          <h2 class="utp-team-header">
            <span class="first">Разработчики</span>
            <span class="second">Ведущие разработчики</span>
            <hr>
          </h2>
          <div class="utp-team-list row">

            <div class="utp-team-col col-xs-12 col-lg-3 col-md-4 col-sm-5">
              <div class="utp-team-item">
                <div class="utp-team-img">
                  <img src="../assets/images/team/kirill-trezubov.jpg" alt="">
                </div>
                <h4 class="utp-team-name">Кирилл Трезубов</h4>
                <p class="utp-team-post">Back-end разработчик</p>
              </div>
            </div>

            <div class="utp-team-col col-xs-12 col-lg-3 col-md-4 col-sm-5">
              <div class="utp-team-item">
                <div class="utp-team-img">
                  <img src="../assets/images/team/kamila-sarbagysheva.jpg" alt="">
                </div>
                <h4 class="utp-team-name">Камила Сарбагышева</h4>
                <p class="utp-team-post">Front-end разработчик</p>
              </div>
            </div>

            <div class="utp-team-col col-xs-12 col-lg-3 col-md-4 col-sm-5">
              <div class="utp-team-item">
                <div class="utp-team-img">
                  <img src="../assets/images/team/ion-burdianov.jpg" alt="">
                </div>
                <h4 class="utp-team-name">Ион Бурдиянов</h4>
                <p class="utp-team-post">Дизайнер</p>
              </div>
            </div>

            <div class="utp-team-col col-xs-12 col-lg-3 col-md-4 col-sm-5">
              <div class="utp-team-item">
                <div class="utp-team-img">
                  <img src="../assets/images/team/arkadii-kliucev.jpg" alt="">
                </div>
                <h4 class="utp-team-name">Аркадий Ключев</h4>
                <p class="utp-team-post">Архитектор системы</p>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div class="utp-team-category">
        <div class="container">
          <h2 class="utp-team-header">
            <span class="first">Отдел продаж</span>
            <span class="second">Отдел продаж</span>
            <hr>
          </h2>
          <div class="utp-team-list row">

            <div class="utp-team-col col-xs-12 col-lg-3 col-md-4 col-sm-6">
              <div class="utp-team-item">
                <div class="utp-team-img">
                  <img src="../assets/images/team/sergey-copilenko.jpg" alt="">
                </div>
                <h4 class="utp-team-name">Сергей Копыленко</h4>
                <p class="utp-team-post">Директор по инновационному развитию</p>
              </div>
            </div>

            <div class="utp-team-col col-xs-12 col-lg-3 col-md-4 col-sm-6">
              <div class="utp-team-item">
                <div class="utp-team-img">
                  <img src="../assets/images/team/kirill-sibirkin.jpg" alt="">
                </div>
                <h4 class="utp-team-name">Кирилл Сибиркин</h4>
                <p class="utp-team-post">Менеджер<br> по Lead Generation</p>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div class="utp-team-category">
        <div class="container">
          <h2 class="utp-team-header">
            <span class="first">Акционеры</span>
            <span class="second">Некоторые известные инвесторы</span>
            <hr>
          </h2>
          <div class="utp-team-list row">

            <div class="utp-team-col col-xs-12 col-lg-3 col-md-4 col-sm-6">
              <div class="utp-team-item">
                <div class="utp-team-img">
                  <img src="../assets/images/utp1/other/01_01.png" alt="">
                </div>
                <h4 class="utp-team-name">Евгений Вергус</h4>
              </div>
            </div>

            <div class="utp-team-col col-xs-12 col-lg-3 col-md-4 col-sm-6">
              <div class="utp-team-item">
                <div class="utp-team-img">
                  <img src="../assets/images/utp1/other/01_02.png" alt="">
                </div>
                <h4 class="utp-team-name">Вячеслав Вопилкин</h4>
              </div>
            </div>

            <div class="utp-team-col col-xs-12 col-lg-3 col-md-4 col-sm-6">
              <div class="utp-team-item">
                <div class="utp-team-img">
                  <img src="../assets/images/utp1/other/01_04.png" alt="">
                </div>
                <h4 class="utp-team-name">Дмитрий Воробьев</h4>
              </div>
            </div>

          </div>
        </div>
        <div class="container">
          <h2 class="utp-team-header">
            <span class="first">И многие другие!</span>
            <span class="second">Вы можете стать на ряду с ними!</span>
            <br>
          </h2>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import HeaderUtp1 from "@/components/HeaderUtp1";

export default {
  name: "team",
  components: {HeaderUtp1}
}
</script>

<style scoped>

</style>