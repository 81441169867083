<template>
  <section class="section" id="section-9">
    <div class="container">
      <header class="section-header">
        <h2>Отличительная особенность от инфопродуктов:</h2>
      </header><!-- .section-header -->
      <div class="row">
        <div class="col-md-6 col-sm-6">
          <ul class="list-icon list-circle list-success">
            <li>По нашим наблюдениям все, что сейчас есть на рынке про заработок в интернет — это обучающие курсы,
              тренинги, коучинги и т.д.
            </li>
            <li>Но, даже в самых эффективных программах, только максимум 10% участников получают заявленный результат.
            </li>
            <li>Чаще всего вы просто отдаете деньги автору программы, а сами остаетесь ни с чем.</li>
          </ul>
        </div>
        <div class="col-md-6 col-sm-6">
          <ul class="list-icon list-circle list-times list-danger">
            <li>В нашем случае вы в любом случае будете получать прибыль. Вопрос только через какое время.<br><br></li>
            <li>Если вы будете активно продвигать проект, то начнете возвращать инвестиции - уже через 2 недели.<br><br>
            </li>
            <li>Если, вы вообще ничего не будете делать, то через год все равно вернете свои инвестиции и далее будете
              получать пассивный доход.
            </li>
          </ul>
        </div>
      </div>

      <h3 class="pt-30 text-light text-center">Получается хотите Вы или нет - рано или чуть позже - все равно начнете
        получать стабильный доход через интернет. И такой гарантии Вам не даст ни один инфобизнесмен, а мы даём!</h3>
    </div>
  </section>
</template>

<script>
export default {
  name: "invest-section-9"
}
</script>

<style scoped>

</style>