<template>
  <section class="section">
    <div class="container">

      <div class="row mb-80">
        <div class="col-md-6 col-md-push-6 text-center-xs">
          <a href="https://utp1.com/beta/?page=invoices" target="_blank" class="utp-beta-link mt-30">
            <img src="../../../../assets/images/utp1/other/05_01.jpg" alt="" class="img-responsive">
          </a>
        </div>
        <div class="col-md-6 col-md-pull-6">
          <h3 class="text-danger"><strong>Продажи и умный Call-центр</strong></h3>
          <p class="font-size-16">Прием оплат вручную это конечно хорошо, но, если у Вашего бизнеса небольшие обороты.
            А, представьте, что у Вас поток заявок 100 в день. Успеете ли Вы каждую обработать вручную?<br>
            Даже 20 заявок в день это не простая задача.</p>
          <h4><strong>Мы предлагаем:</strong></h4>
          <ul class="font-size-16">
            <li>Прием оплат в автоматическом режиме 24 на 7.</li>
            <li>В любой момент времени Вы знаете, сколько денег Вам пришло, кто не оплатил и так далее</li>
            <li>Возможность принимать рекуррентные платежи, когда каждый месяц с карточки клиента автоматически
              списывается оплата за участие в Вашем проекте
            </li>
            <li>Умный Call-центр, когда Вы или Ваши менеджеры по продажам могут отследить, в какой стадии оплаты
              находится заказ. И, автоматически напоминать Вам кому и когда надо позвонить.
            </li>
          </ul>
        </div>
      </div><!-- .row -->

      <div class="row mb-80">
        <div class="col-md-6 text-center-xs">
          <a href="https://utp1.com/beta/?page=blog" target="_blank" class="utp-beta-link">
            <img src="../../../../assets/images/utp1/other/05_02.jpg" alt="" class="img-responsive">
          </a>
        </div>
        <div class="col-md-6">
          <h3 class="text-danger"><strong>Сайты</strong></h3>
          <h4><strong>Мы предлагаем:</strong></h4>
          <ul class="font-size-16">
            <li>Создание, как многостраничного сайта, так и одностраничного (landing page) за пару кликов мышки.</li>
            <li>Вам не надо разбираться в кодах и быть дизайнером.</li>
            <li>Все страницы Вашего сайта будут сразу адаптивны под любое устройство</li>
          </ul>
        </div>
      </div><!-- .row -->

      <div class="row mb-80">
        <div class="col-md-6 col-md-push-6 text-center-xs">
          <a href="https://utp1.com/beta/?page=newsletter-auto" target="_blank" class="utp-beta-link mt-30">
            <img src="../../../../assets/images/utp1/other/05_03.jpg" alt="" class="img-responsive">
          </a>
        </div>
        <div class="col-md-6 col-md-pull-6">
          <h3 class="text-danger"><strong>Интегрированная рассылка (E-mail, ВКонтакте, Facebook, мессенджеры, SMS и
            PUSH), умные автоворонки</strong></h3>
          <p class="font-size-16">Сейчас кроме e-mail рассылки появились рассылки в социальных сетях и в мессенджерах.
            Практически невозможно объединить все эти рассылки в одну систему.</p>
          <h4><strong>Мы предлагаем:</strong></h4>
          <ul class="font-size-16">
            <li>Умные интегрированные рассылки в тот канал, который открывает и читает Ваш подписчик. Тем самым
              увеличивается открываемость Ваших сообщений и кликабельность по ссылкам;
            </li>
            <li>Умные и наглядные воронки продаж. Сейчас подписчики очень невнимательны. 30% даже не открывают первого
              письма для получения подарка. С помощью наших автоворонок можно настроить все так, чтобы подписчик пока не
              посмотрит первое
              видео из Вашей серии - второе не получит.
            </li>
          </ul>
        </div>
      </div><!-- .row -->

      <div class="row mb-80">
        <div class="col-md-6 text-center-xs">
          <a href="https://utp1.com/beta/?page=blog" target="_blank" class="utp-beta-link">
            <img src="../../../../assets/images/utp1/other/05_04.jpg" alt="" class="img-responsive">
          </a>
        </div>
        <div class="col-md-6">
          <h3 class="text-danger"><strong>Маркетинг</strong></h3>
          <p class="font-size-16">Здесь вообще все сложно. Есть очень много различных инструментов и сервисов
            увеличивающих продажи Ваших продуктов.<br>
            Такие как: PopUp окна, таймеры, PUSH рассылки, встроенные виджеты...<br>
            И это все отдельные сервисы, которые нужно оплачивать. В месяц набегает довольно кругленькая сумма</p>
          <h4><strong>Мы предлагаем:</strong></h4>
          <ul class="font-size-16">
            <li>Все необходимые инструменты для увеличения продаж, конверсий и активности Вашей аудитории - в одном
              месте.
            </li>
            <li>Это очень удобно!</li>
          </ul>
        </div>
      </div><!-- .row -->

      <div class="row mb-80">
        <div class="col-md-6 col-md-push-6 text-center-xs">
          <a href="https://utp1.com/beta/?page=analytics" target="_blank" class="utp-beta-link mt-30">
            <img src="../../../../assets/images/utp1/other/05_05.jpg" alt="" class="img-responsive">
          </a>
        </div>
        <div class="col-md-6 col-md-pull-6">
          <h3 class="text-danger"><strong>Аналитика</strong></h3>
          <p class="font-size-16">Зачем вообще нужна аналитика в сервисах - есть же Яндекс Метрика, Google Analytics
            Да есть. Но, с помощью этих инструментов мы можем отслеживать только первичные показатели.<br>
            Сейчас все усложнилось. Подписчик может купить через год, может купить не один продукт, может прийти с
            одного источника рекламы, потом попасть в другой, третий и купить. Весь этот путь невозможно отследить</p>
          <h4><strong>Мы предлагаем:</strong></h4>
          <ul class="font-size-16">
            <li>Сквозную аналитику по всем шагам ваших подписчиков.</li>
            <li>Вы будете знать не только откуда пришел подписчик и сколько он принес Вам денег за первый месяц, но и
              сколько он Вам стоил и сколько принес денег за все время
            </li>
          </ul>
        </div>
      </div><!-- .row -->

      <div class="row mb-80">
        <div class="col-md-6 text-center-xs">
          <a href="https://utp1.com/beta/?page=employees" target="_blank" class="utp-beta-link">
            <img src="../../../../assets/images/utp1/other/05_06.jpg" alt="" class="img-responsive">
          </a>
        </div>
        <div class="col-md-6">
          <h3 class="text-danger"><strong>Партнерская программа</strong></h3>
          <p class="font-size-16">Привлекать трафик — это очень важная задача любого бизнеса.<br>
            Если у Вас хорошо выстроены продажи, но совершенно нет трафика - Вы ничего не заработаете.<br>
            Трафик можно привлекать двумя способами.<br>
            Первый с помощью рекламы<br>
            Второй с помощью партнеров, которые рекомендуют Ваши продукты за % с оплаты Ваших товаров</p>
          <h4><strong>Мы предлагаем:</strong></h4>
          <ul class="font-size-16">
            <li>Многоуровневую партнерскую программу, которую Вы можете настроить под себя.</li>
            <li>Плюс есть возможность создания МЛМ структуры</li>
          </ul>
        </div>
      </div><!-- .row -->

      <div class="row mb-80">
        <div class="col-md-6 col-md-push-6 text-center-xs">
          <a href="https://utp1.com/beta/?page=marketing" target="_blank" class="utp-beta-link mt-30">
            <img src="../../../../assets/images/utp1/other/05_07.jpg" alt="" class="img-responsive">
          </a>
        </div>
        <div class="col-md-6 col-md-pull-6">
          <h3 class="text-danger"><strong>Честные взаимопиары, покупки выпусков рассылок</strong></h3>
          <p class="font-size-16">Самый простой способ привлечения трафика — это взаимопиар (если у Вас есть база
            подписчиков) или покупка выпуска рассылки. Проблема в том, что в этом направлении очень много обмана: Автор
            вообще может не сделать рассылку или сделает ее по “мертвой” базе</p>
          <h4><strong>Мы предлагаем:</strong></h4>
          <ul class="font-size-16">
            <li>Открытую статистику и рейтинг авторов рассылок. Таким образом Вы легко сможете посчитать сколько
              подписчиков Вы получите за свои деньги
            </li>
          </ul>
        </div>
      </div><!-- .row -->

      <div class="row mb-80">
        <div class="col-md-6 text-center">
          <a href="https://utp1.com/beta/?page=employees" target="_blank" class="utp-beta-link">
            <img src="../../../../assets/images/utp1/other/05_08.jpg" alt="" class="img-responsive">
          </a>
        </div>
        <div class="col-md-6">
          <h3 class="text-danger"><strong>Встроенная геймификация</strong></h3>
          <p class="font-size-16">Это очень мощный инструмент. Просто учиться не интересно.<br>
            Идеальное обучение - в игровой форме.<br>
            А, когда есть приз за который можно побороться - это сильно увеличивает активность Ваших подписчиков</p>
          <h4><strong>Мы предлагаем:</strong></h4>
          <ul class="font-size-16">
            <li>Возможность учитывать все действия Ваших подписчиков и автоматичское начисление им баллов за эти
              действия.
            </li>
            <li>За наибольшее количество баллов Вы можете вручать призы.</li>
            <li>Также, будет возможность обменять баллы на рубли и получить скидку на Ваши обучающие курсы на количество
              набранных бонусных рублей.
            </li>
          </ul>
        </div>
      </div><!-- .row -->

      <p>Обращаем Ваше внимание, социальные сети Meta запрещены на территории Российской Федерации</p>

    </div><!-- .container -->
  </section>
</template>

<script>
export default {
  name: "section-13"
}
</script>

<style scoped>

</style>