<template>
  <div class="modal fade utp-modal-3 in dialog-style modal-open" v-if="show" @click="modalClose">
    <div @click.stop class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <button @click="modalClose" type="button" class="close" data-dismiss="modal">
            <span>&times;</span>
          </button>
          <h3 class="modal-title text-danger">UTP1 для специалистов в области продаж и привлечения</h3>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-4 col-sm-12">
              <div class="block-icon">
                <div class="block-icon-image">
                  <img src="../../../../assets/images/utp1/ic/3_1.png" alt="">
                </div>
                <h5 class="block-icon-title">Отдел продаж</h5>
                <p class="block-icon-desc">Нам нужны специалисты по Lead&nbsp;Generation, Lead&nbsp;Conversion и Account&nbsp;Management</p>
              </div>
            </div><!-- .col-xx-n -->
            <div class="col-md-4 col-sm-12">
              <div class="block-icon">
                <div class="block-icon-image">
                  <img src="../../../../assets/images/utp1/ic/3_2.png" alt="">
                </div>
                <h5 class="block-icon-title">Техподдержка</h5>
                <p class="block-icon-desc">Мы стремимся дать максимальный сервис, и предлагаем Вам нам помочь</p>
              </div>
            </div><!-- .col-xx-n -->
            <div class="col-md-4 col-sm-12">
              <div class="block-icon">
                <div class="block-icon-image">
                  <img src="../../../../assets/images/utp1/ic/3_3.png" alt="">
                </div>
                <h5 class="block-icon-title">IT специалисты</h5>
                <p class="block-icon-desc">Мы не стоим на месте, и нам всегда требуются квалифицированные кадры</p>
              </div>
            </div><!-- .col-xx-n -->
          </div><!-- .row -->
          <div class="row">
            <div class="col-md-4 col-sm-12">
              <div class="block-icon">
                <div class="block-icon-image">
                  <img src="../../../../assets/images/utp1/ic/3_4.png" alt="">
                </div>
                <h5 class="block-icon-title">Полная свобода</h5>
                <p class="block-icon-desc">Работая у нас Вы можете сами решать, когда и где работать, а когда и где
                  отдыхать</p>
              </div>
            </div><!-- .col-xx-n -->
            <div class="col-md-4 col-sm-12">
              <div class="block-icon">
                <div class="block-icon-image">
                  <img src="../../../../assets/images/utp1/ic/3_5.png" alt="">
                </div>
                <h5 class="block-icon-title">Достойная оплата</h5>
                <p class="block-icon-desc">Только Вы решаете, сколько хотите зарабатывать, и управляете своим
                  доходом</p>
              </div>
            </div><!-- .col-xx-n -->
            <div class="col-md-4 col-sm-12">
              <div class="block-icon">
                <div class="block-icon-image">
                  <img src="../../../../assets/images/utp1/ic/3_6.png" alt="">
                </div>
                <h5 class="block-icon-title">Карьерный рост</h5>
                <p class="block-icon-desc">Мы следим за Вашими достижениями и предлагаем всё более интересные задачи</p>
              </div>
            </div><!-- .col-xx-n -->
          </div><!-- .row -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "modal-3",
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  setup(_, {emit}) {
    const modalClose = () => {
      emit('update:show', false)
    }
    return {modalClose}
  }
}
</script>

<style scoped>

</style>