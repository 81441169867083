<template>
  <section class="section s-bg-grey" id="section-7">
    <div class="container">
      <header class="section-header">
        <h2>Дополнительные бонусы для прямых инвесторов:</h2>
      </header><!-- .section-header -->
      <div class="card card-p">
        <div class="row">
          <div class="col-sm-4 col-sm-push-8 text-center-xs">
            <img src="../../../assets/images/invest/products/utp1_bonus_5.png" alt="Product Image" class="img-responsive">
          </div>
          <div class="col-sm-8 col-sm-pull-4">
            <h2>Бизнес-школа InfoBiz1.TV<br>[полная версия]</h2>
            <ul class="list-icon">
              <li>2 интенсива по управлению реальностью</li>
              <li>Пошаговые задания для старта бизнеса</li>
              <li>Возможность выбрать любую бизнес-модель</li>
              <li>Обучение созданию конкурентных продуктов</li>
              <li>ХИТ продаж, СОТНИ выпускников</li>
            </ul>
          </div>
        </div><!-- .row -->
        <hr class="hr-special">
        <div class="row">
          <div class="col-sm-4 text-center-xs">
            <img src="../../../assets/images/invest/products/utp1_bonus_6.png" alt="Product Image" class="img-responsive">
          </div>
          <div class="col-sm-8">
            <h2>Реалити-шоу по СтартАпу<br>[V.I.P. версия]</h2>
            <ul class="list-icon">
              <li>Анализ самой продвинутой видеоворонки</li>
              <li>Наблюдение за процессами в реальном времени</li>
              <li>Просто повторяйте действия и получате результаты</li>
              <li>Разбор нашего кейса на 13&nbsp;000&nbsp;000 рублей</li>
              <li>Возможность погрузиться в мир интернет-бизнеса</li>
            </ul>
          </div>
        </div><!-- .row -->

        <hr class="hr-special">
        <div class="row">
          <div class="col-sm-4 col-sm-push-8 text-center-xs">
            <img src="../../../assets/images/invest/products/utp1_bonus_7.png" style="height:350px;" alt="Product Image"
                 class="img-responsive">
          </div>
          <div class="col-sm-8 col-sm-pull-4">
            <h2>Простая настройка таргетированной рекламы 2.0<br> Сергей Копыленко</h2>
            <ul class="list-icon">
              <li>Легко научитесь привлекать целевой трафик из ВК</li>
              <li>Грамотно писать рекламные посты</li>
              <li>Правильно тестировать рекламные объявления</li>
              <li>Получать подписчиков при минимальных затратах</li>
              <li>Никогда не будет проблем с трафиком</li>
            </ul>
          </div>
        </div><!-- .row -->
        <hr class="hr-special">
        <div class="row">
          <div class="col-sm-4 text-center-xs">
            <img src="../../../assets/images/invest/products/utp1_bonus_8.png" style="height:350px;" alt="Product Image"
                 class="img-responsive">
          </div>
          <div class="col-sm-8">
            <h2>Видеокурс «Фэйсбук Машина»<br> Сергей Копыленко</h2>
            <ul class="list-icon">
              <li>Видеоуроки по правильному оформлению страниц Фб</li>
              <li>Простая настройка рекламы в Фб</li>
              <li>Как сделать так, чтобы Фб сам искал вашу ЦА</li>
              <li>Анализ и снижение стоимости</li>
              <li>Аудитория, у которой есть деньги</li>
            </ul>
          </div>
        </div><!-- .row -->

      </div>
    </div><!-- .container -->
  </section>
</template>

<script>
export default {
  name: "invest-section-7"
}
</script>

<style scoped>

</style>