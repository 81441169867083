<template>
  <form-group-text-utp1
      v-model="register.name"
      :label="name"
  >Введите Ваше имя:
  </form-group-text-utp1>
  <form-group-text-utp1
      v-model="register.email"
      :label="email"
  >Введите Ваш e-mail:
  </form-group-text-utp1>
  <form-group-text-utp1
      v-model="register.phone"
      :label="phone"
  >Телефон в международном формате:
  </form-group-text-utp1>
  <checkbox-utp1 v-model="checkboxChecked">Обработка персональных данных</checkbox-utp1>
  <div class="form-group-buttons">
    <button type="button" @click="utp1Register" :disabled=!checkboxChecked
            :class="checkboxChecked? 'btn btn-primary btn-lg btn-block' : 'btn btn-primary btn-lg btn-block disabled'">
      Зарегистрироваться
    </button>
  </div>
  <div class="form-group-footer">
    <a @click="tabLogin" class="link-tab-login">Войти</a>
  </div>
</template>

<script>
import FormGroupTextUtp1 from "@/components/FormGroupTextUtp1";
import {ref} from "vue";

export default {
  name: "register-form",
  components: {FormGroupTextUtp1},
  props: {
    register: Object
  },
  emits: ['utp1Register', 'tabLogin'],
  setup(_, {emit}) {
    const name = {
      placeholder: "Ваше имя",
      focus: true
    }
    const email = {
      placeholder: "Ваш e-mail",
      focus: false
    }
    const phone = {
      placeholder: "Ваш телефон",
      focus: false
    }
    const checkboxChecked = ref(false)
    const utp1Register = () => {
      emit('utp1Register')
    }
    const tabLogin = () => {
      emit('tabLogin')
    }
    return {name, email, phone, checkboxChecked, utp1Register, tabLogin}
  }
}
</script>

<style scoped>

</style>