<template>
  <div class="modal fade utp-modal-6 in dialog-style modal-open" v-if="show" @click="modalClose">
    <div @click.stop class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <button @click="modalClose" type="button" class="close" data-dismiss="modal">
            <span>&times;</span>
          </button>
          <h3 class="modal-title text-danger">UTP1 для конечных потребителей</h3>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-4 col-sm-12">
              <div class="block-icon">
                <div class="block-icon-image">
                  <img src="../../../../assets/images/utp1/ic/6_1.png" alt="">
                </div>
                <h5 class="block-icon-title">On-line мегамаркет</h5>
                <p class="block-icon-desc">Покупайте вещи и продукты питания напрямую от поставщика не переплачивая</p>
              </div>
            </div><!-- .col-xx-n -->
            <div class="col-md-4 col-sm-12">
              <div class="block-icon">
                <div class="block-icon-image">
                  <img src="../../../../assets/images/utp1/ic/6_2.png" alt="">
                </div>
                <h5 class="block-icon-title">Бесплатная доставка</h5>
                <p class="block-icon-desc">Мы развиваем сеть, чтобы Вы могли получать продукты домой быстро и
                  бесплатно</p>
              </div>
            </div><!-- .col-xx-n -->
            <div class="col-md-4 col-sm-12">
              <div class="block-icon">
                <div class="block-icon-image">
                  <img src="../../../../assets/images/utp1/ic/6_5.png" alt="">
                </div>
                <h5 class="block-icon-title">Доска объявлений</h5>
                <p class="block-icon-desc">Мы выявили недочёты существующих сейчас сервисов и избавились от них</p>
              </div>
            </div><!-- .col-xx-n -->
          </div><!-- .row -->
          <div class="row">
            <div class="col-md-4 col-sm-12">
              <div class="block-icon">
                <div class="block-icon-image">
                  <img src="../../../../assets/images/utp1/ic/6_3.png" alt="">
                </div>
                <h5 class="block-icon-title">Трудоустройство</h5>
                <p class="block-icon-desc">Пройдите обучение и получите высокооплачиваемую удалённую работу</p>
              </div>
            </div><!-- .col-xx-n -->
            <div class="col-md-4 col-sm-12">
              <div class="block-icon">
                <div class="block-icon-image">
                  <img src="../../../../assets/images/utp1/ic/6_4.png" alt="">
                </div>
                <h5 class="block-icon-title">Обучение бизнесу</h5>
                <p class="block-icon-desc">Вы можете пройти обучение и стать участником системы в качестве
                  предпринимателя</p>
              </div>
            </div><!-- .col-xx-n -->
            <div class="col-md-4 col-sm-12">
              <div class="block-icon">
                <div class="block-icon-image">
                  <img src="../../../../assets/images/utp1/ic/6_6.png" alt="">
                </div>
                <h5 class="block-icon-title">Сервис фриланса</h5>
                <p class="block-icon-desc">Используя систему UTP1 Вы можете без риска продавать свои услуги через
                  интернет</p>
              </div>
            </div><!-- .col-xx-n -->
          </div><!-- .row -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "modal-6",
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  setup(_, {emit}) {
    const modalClose = () => {
      emit('update:show', false)
    }
    return {modalClose}
  }
}
</script>

<style scoped>

</style>