<template>
  <!--suppress JSUnresolvedVariable -->
  <div v-if="$store.state.auth.auth" class="utp-home-bio">
    <auth-form/>
  </div><!-- .utp-home-bio -->

  <div v-else class="utp-home-form">
    <ul class="nav nav-tabs nav-justified" role="tablist">
      <li :class="tab? 'active' : '' ">
        <a @click="tabLogin">Вход</a>
      </li>
      <li :class="!tab? 'active' : '' ">
        <a @click="tabRegister">Регистрация</a>
      </li>
    </ul>
    <!-- Tab panes -->
    <div class="tab-content">
      <div class="tab-pane fade in active">
        <form class="utp-register-form">
          <label v-if="errorActive" class="text-danger">{{ errorMessage }}</label>

          <div v-if="tab">
            <login-form
                v-model:entry="entry"
                @utp1Login="utp1Login"
                @tabRegister="tabRegister"
            />
          </div>

          <div v-else-if="!registered">
            <register-form
                v-model:register="register"
                @utp1Register="utp1Register"
                @tabLogin="tabLogin"
            />
          </div>

          <div v-else>
            <registered-form
                v-model:hide-email="hideEmail"
                v-model:registered-button="registeredButton"
                @redirect="redirect"
            />
          </div>

        </form>
      </div>
    </div>
  </div><!-- .utp-home-form -->
</template>

<script>
import FormGroupTextUtp1 from "@/components/FormGroupTextUtp1";
import FormGroupPasswordUtp1 from "@/components/FormGroupPasswordUtp1";
import {onMounted, ref, watch} from "vue";
import {useStore} from "vuex";
import useGetDataExtractor from "@/hooks/useGetDataExtractor";
import useError from "@/hooks/useError";
import {useLogin} from "@/hooks/rest/useLogin";
import useRegister from "@/hooks/rest/useRegister";
import useRedirect from "@/hooks/useRedirect";
import AuthForm from "@/components/UI/Form/AuthForm";
import LoginModal from "@/components/UI/LoginModal";
import LoginForm from "@/components/UI/Form/LoginForm";
import RegisteredForm from "@/components/UI/Form/RegisteredForm";
import RegisterForm from "@/components/UI/Form/RegisterForm";
import useGetIp from "@/hooks/rest/useGetIp";

export default {
  name: "form-utp1",
  components: {RegisterForm, RegisteredForm, LoginForm, LoginModal, AuthForm, FormGroupPasswordUtp1, FormGroupTextUtp1},
  setup() {
    const store = useStore()
    let tab = ref(true)
    const tabLogin = () => {
      tab.value = true
      updateExceptionValue()
    }
    const tabRegister = () => {
      tab.value = false
      updateExceptionValue()
    }
    const updateExceptionValue = () => {
      exception.value = ""
      error()
    }
    const entry = ref({
      login: "",
      password: "",
      remember: false
    })
    const register = ref({
      name: "",
      email: "",
      phone: "",
      registerIp: "",
      affiliate: null,
      timezone: null,
      language: null,
      os: null,
      osVersion: null,
      browser: null,
      medium: null,
      source: null,
      campaign: null,
      content: null,
      term: null,
      country: "",
      region: "",
      city: "",
      latitude: null,
      longitude: null
    })
    const registeredButton = ref("Перейти в свой почтовый ящик")
    const exception = ref("")

    const setUtm = () => {
      register.value.medium = store.state.utm.utm.medium
      register.value.source = store.state.utm.utm.source
      register.value.campaign = store.state.utm.utm.campaign
      register.value.content = store.state.utm.utm.content
      register.value.term = store.state.utm.utm.term
    }

    const setDataExtractor = () => {
      register.value.timezone = dataExtractor.value.timezone
      register.value.language = dataExtractor.value.language
      register.value.os = dataExtractor.value.os
      register.value.osVersion = dataExtractor.value.osVersion
      register.value.browser = dataExtractor.value.browser
    }

    const setIp = () => {
      register.value.registerIp = geoIpLookupIo.value.ip
      register.value.country = geoIpLookupIo.value.country_name
      register.value.region = geoIpLookupIo.value.region
      register.value.city = geoIpLookupIo.value.city
      register.value.latitude = geoIpLookupIo.value.latitude
      register.value.longitude = geoIpLookupIo.value.longitude
    }

    const utp1Login = () => {
      loginUtp1()
      exception.value = loginException.value
      error()
    }

    const utp1Register = async () => {
      await getIp()
      await setIp()
      await registerUtp1()
      exception.value = registerException.value
      error()
    }

    const {dataExtractor} = useGetDataExtractor()
    const {loginUtp1, loginException} = useLogin(entry.value)
    const {registerUtp1, registerException, hideEmail, registered} = useRegister(register)
    const {getIp, geoIpLookupIo} = useGetIp()
    const {error, errorMessage, errorActive} = useError(exception)
    const {redirect, buttonRedirect, button} = useRedirect(hideEmail)

    onMounted(setUtm)
    onMounted(setDataExtractor)

    watch(loginException, () => {
      exception.value = loginException.value
      error()
    })

    watch(registerException, () => {
      exception.value = registerException.value
      error()
    })

    watch(hideEmail, () => {
      buttonRedirect()
      if (!button.value) {
        registeredButton.value = ""
      }
    })

    return {
      tab, tabLogin, tabRegister,
      entry, utp1Login,
      register, utp1Register, registeredButton,
      errorMessage, errorActive,
      hideEmail, registered, redirect
    }

  }
}
</script>

<style scoped>

</style>
