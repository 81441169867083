<template>
  <form-group-text-utp1
      v-model="entry.login"
      :label="login"
  >Введите свой логин:
  </form-group-text-utp1>
  <form-group-password-utp1
      v-model="entry.password"
      :label="password"
  >Введите пароль:
  </form-group-password-utp1>
  <checkbox-utp1 v-model="entry.remember">Запомнить меня на этом устройстве</checkbox-utp1>
  <div class="form-group-buttons">
    <button type="button" @click="utp1Login" class="btn btn-primary btn-lg">Войти</button>
    <button @click="tabRegister" class="btn btn-danger btn-lg pull-right btn-tab-login">Регистрация
    </button>
  </div>
  <div class="form-group-footer">
    <a @click="recover">Забыли пароль</a>
  </div>
</template>

<script>
import FormGroupTextUtp1 from "@/components/FormGroupTextUtp1";
import FormGroupPasswordUtp1 from "@/components/FormGroupPasswordUtp1";

export default {
  name: "login-form",
  components: {FormGroupPasswordUtp1, FormGroupTextUtp1},
  props: {
    entry: Object
  },
  emits: ['utp1Login', 'tabRegister'],
  setup(_, {emit}) {
    const login = {
      placeholder: "Логин",
      focus: true
    }
    const password = {
      placeholder: "Пароль",
      focus: false
    }
    const utp1Login = () => {
      emit('utp1Login')
    }
    const tabRegister = () => {
      emit('tabRegister')
    }
    const recover = () => {
      window.open('https://login.utp1.com/recover', '_blanc')
    }
    return {login, password, utp1Login, tabRegister, recover}
  }
}
</script>

<style scoped>

</style>