<template>
  <div class="utp-doc-category">
    <div class="container">
      <h2 class="utp-doc-header">
        <span class="first">Информация</span>
        <span class="second">Общая информация</span>
        <hr>
      </h2>
      <div class="container-lg">
        <h3><strong>Акционерное общество «Универсальная трейдинговая платформа»</strong> (АО «УТП1»)<br>
          ОГРН: <em><strong>1187847233496</strong></em><br>
          ИНН / КПП: <em><strong>7811705340 / 781101001</strong></em><br>
          Юридический адрес (совпадает с фактическим): <em><strong>192148, Россия, Санкт-Петербург, ул. Седова, д.
            37, лит. «А», офис 719</strong></em><br>
          Телефон: <em><strong>+7 921 875-00-70</strong></em><br>
          E-mail: <em><strong>info@consultagency.ru</strong></em><br>
          <strong>Банковские реквизиты:</strong><br>
          Номер счёта: <em>40702810210000822207</em><br>
          Банк получателя: <em>АО "ТИНЬКОФФ БАНК"</em><br>
          БИК банка получателя: <em>044525974</em><br>
          Корр. счёт: <em>30101810145250000974</em><br>
          ИНН банка: <em>7710140679</em></h3>

        <h2>Оплата</h2>

        <h4>Оплата товаров и услуг производится через платежную систему PayU. Возможна оплата в несколько этапов
          посредством внесения задатка.</h4>

        <p><strong>Принимаемые методы оплаты:</strong><br>
          <em>Карты: <strong>Visa, MasterCard, МИР</strong><br>
            Электронные кошельки: <strong>Яндекс.Деньги, WEB.money, QIWI, PayPal</strong><br>
            Наличные: <strong>Связной, Евросеть</strong><br>
            Интернет-банкинг: <strong>Альфа.Клик</strong></em></p>

        <p>Реализуемые товары и услуги: <a @click="$router.push('/invest')">utp1.com/invest</a></p>

        <h2>Доставка</h2>

        <p><strong>Электронный товар высылается сразу после поступления денег на счет в системе
          PayU.</strong><br><br>

          Физический товар будет выслан в течение недели с момента поступления денег на счёт в системе PayU. Сроки
          доставки каждого товара индивидуальны, подробнее информация о сроках доступна в описании товара (как
          правило, 2-3 недели с момента отправки). Информация о времени и месте проведения тренингов будет сообщена
          при регистрации и оплате соответствующего тренинга персональным менеджером по телефону и по e-mail'у.
          После отправки товара на e-mail будет выслано уведомление с номером почтового отправления!<br><br>

          Гарантированная почтовая доставка: <em>Россия (отправление 1 класса), Украина, Беларусь, Израиль, Латвия,
            Литва, США, Турция, Германия, Казахстан, Киргизия, Азербайджан, Молдова, Эстония, Узбекистан,
            Таджикистан, Испания, Чехия, Англия, Бельгия, Венгрия, Канада, Болгария, Швеция, Португалия, Ирландия,
            Исландия, Япония, Нидерланды, Италия, Новая Зеландия, Греция, Корея, Грузия.</em></p>

        <h2>Возврат денежных средств:</h2>

        <p>Потребитель вправе, в соответствии с Законом РФ от 07.02.1992 N 2300-1 <em>«О защите прав
          потребителей»</em>, в течение 14 (четырнадцати) дней (если дополнительно не оговорено иное) с момента
          внесения всей суммы требовать возврата стоимости Объекта реализации за вычетом фактически понесённых
          расходов, если предложенные в Объекте реализации методики у него не работают. При этом, Потребитель обязан
          предоставить отчет, подтверждающий внедрение методик и отсутствии результатов. Так же, Потребитель должен
          возместить Исполнителю расходы, связанные с перечислением средств на реквизиты Потребителя, доставке.
          Конкретные случаи, когда и на каких условиях Потребитель имеет право отказаться от предоставления
          оплаченных услуг и потребовать возврата денежных средств, зависят от Объекта Реализации, и подробно
          описаны в Договоре Оферты: <a href="https://files.utp1.com/docs/dogovor-oferty.pdf" target="_blank">dogovor-oferty.pdf</a>.
        </p>

        <h2>Безопасность передачи конфиденциальной информации держателей карт</h2>

        <p>При выборе данной формы оплаты заказа на сайте <a href="https://utp1.com" target="_blank">utp1.com</a> Вы
          будете автоматически перенаправлены на платежную форму процессингового центра PayU, для внесения данных
          Вашей банковской карты.<br><br>

          Все данные, введенные Вами на платежной форме процессингового центра PayU, полностью защищены в
          соответствии с требованиями стандарта безопасности PCI DSS. Мы получаем информацию только о совершенном
          Вами платеже.<br><br>

          На указанный Вами при оформлении платежа адрес электронной почты, будет отправлено сообщение об
          авторизации платежа.<br><br>

          Сразу после совершения платежа вы будете перенаправлены обратно на наш сайт. Информация о вашем платеже
          может идти до нас от 5 секунд до нескольких минут. В случае, если по вашему мнению произошла задержка в
          обработке заказа, Вам <strong>необходимо обратиться в офис компании по телефону +7 921 875-00-70</strong>
        </p>

        <h2 class="utp-doc-header">
          <hr>
        </h2>

        <img src="../../../assets/images/docs/all_pay.png" class="img-responsive alignnone" alt="">

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "docs-section-5"
}
</script>

<style scoped>

</style>