<template>
  <header-utp1>Об авторе</header-utp1>
  <main class="utp-main utp-main-light">
    <div class="container">
      <div class="utp-wrap">
        <div class="container-lg">
          <div class="embed-responsive embed-responsive-16by9 embed-youtube">
            <iframe class="embed-responsive-item"
                    src="https://www.youtube.com/embed/PyFPZW6S7Zk?autoplay=&loop=0&showinfo=0&theme=light&color=red&controls=0&modestbranding=1&start=0&fs=0&iv_load_policy=3&wmode=transparent&rel=0"></iframe>
          </div><!-- .embed-responsive -->

          <div class="entry-content entry-typo">
            <h2>Пётр Вениаминович Громов</h2>
            <ul>
              <li>Учредитель и держатель контрольного пакета акций <strong>АО «UTP1»</strong>, лучшей ЭКО системы
                автоматизации ВСЕХ бизнес-процессов в интернете;
              </li>
              <li>имеет <strong>несколько высших образований</strong>, в том числе «Красный» диплом (с отличем и средним
                балом 5.0) магистра в области IT-технологий (Университет ИТМО, Мегафакультет компьютерных технологий и
                управления, Факультет программной инженерии и компьютерной техники);
              </li>
              <li>владелец интернет-телеканала <strong>InfoBiz1.TV</strong>;</li>
              <li>генеральный директор консалтингового агентства <strong>Phoenix Consult Agency</strong>;</li>
              <li>руководитель профессиональной <strong>видеостудии</strong>;</li>
              <li>разработчик инновационной вебинарной площадки нового поколения;</li>
              <li>создатель первой в Мире бизнес-школы, обучающий материал которой <strong>научно доказан</strong>
                математическими методами;
              </li>
              <li>идейный вдохновитель проекта по созданию своего дела в интернете и трансформации стиля жизни, <strong>LifeRestart.Ru</strong>;
              </li>
              <li>главный <strong>герой ряда фильмов</strong> про бизнес и мотивацию;</li>
              <li>один из успешных Интернет-бизнесменов России и СНГ, опыт ведения
                предпринимательской деятельности с 2007 года;
              </li>
              <li>военный, <strong>Участник боевых действий</strong> на территории Северного Кавказа;</li>
              <li>так же был владельцем и исполнительным директором Детективно-охранного предприятия, и художественным
                руководителем музыкального проекта;
              </li>
              <li>женат, <strong>воспитывает сына и дочь</strong>.</li>
            </ul>
            <blockquote><p>Я считаю, что абсолютно любой человек может заниматься бизнесом, для этого ему достаточно
              совершить осознанный выбор. И задача, которую я перед собой ставлю, заключается в том, чтобы помочь
              каждому, кто хочет заниматься своим делом в интернете. Да, сейчас это может быть достаточно сложно с
              технической стороны, но тогда эту преграду необходимо преодолеть!</p></blockquote>
            <hr/>
            <h3>Карьера и бизнес</h3>
            <p>Родился 2 июля 1989 года в Ленинградской области (СССР), с детства живёт в Санкт-Петербурге. Впервые о
              своём бизнесе задумался ещё в 9-м классе, когда, вместе с друзьями, <strong>13 ноября 2005 года</strong>
              организовал музыкальный коллектив NeoНика, и уже <strong>в 2007-м</strong> году добивается первых
              финансовых результатов.</p>
            <p><strong>2 февраля 2009 года</strong> открывает новый бизнес, и становится владельцем и исполнительным
              директором Детективно-охранного агентства, которым фактически и руководил, вплоть до ухода в армию в
              ноябре 2010 года.</p>
            <p><strong>23 ноября 2010 года</strong> уходит служить в армию, Отдельную Бригаду Оперативного Назначения ВВ
              МВД РФ (сейчас, &#8211; РосГвардия), <strong>18 апреля 2011 года</strong> становится Участником Боевых
              Действий на Северном Кавказе. За время службы в армии был снайпером, командиром отделения и заместителем
              командира взвода. Из личной характеристики:</p>
            <blockquote>
              <h6><em>«Ветеран боевых действий. Принимал участие в контр-террористических операциях на территории
                Северо-Кавказского региона (республика Ингушетия). Выполнял задачи по охране общественного порядка и
                обеспечению общественной безопасности при проведении XV Международного Санкт-Петербургского
                Экономического Форума в аэропорту «Пулково», а также футбольных матчей и иных массовых мероприятий в
                городе Санкт-Петербурге. Награжден знаком за отличие в службе «200 лет ВВ» Командующим войсками СЗРК ВВ
                МВД РФ, грамотами от командира бригады, имеет благодарности от командира батальона и командира
                роты.»</em></h6>
            </blockquote>
            <p>После возвращения из армии, <strong>3 марта 2012 года</strong>, открывает Phoenix Consult Agency, а
              <strong>1 июля</strong> того же года проводит свой первый вебинар</p>
            <p><strong>4 июля 2014 года</strong> получил диплом о высшем образовании в области физико-математических
              образовательных наук</p>
            <p><strong>16 октября 2014 года</strong> запускает первое в мире телевидение успеха, InfoBiz1.TV, которое в
              последствии стало инновационной
              вебинарной площадкой нового поколения, на базе которой создана бизнес-студия</p>
            <p>Начиная <strong>с февраля 2015 года</strong> серьёзно занялся анализом бизнес-процессов по выявлению
              закономерностей, и доказательством универсальной формулы, по которой строятся отношения между любыми двумя
              элементами, истинность математических выкладок подтверждена доктором физико-математических наук</p>
            <p><strong>14 мая 2015 года</strong> запускает пилотную версию НОУ ДПО «Школа InfoBiz1.TV», с использованием
              новых технологий и открытий, которая <strong>11 октября 2015 года</strong>, после бэта-тестирования, стала
              доступна для всех желающих</p>
            <p>Во время беседы с одним из израильских коллег, <strong>в начале 2015 года</strong>, приходит идея
              создания Универсальной Трейдинговой Платформы для того, чтобы у каждого была возможность реализации
              высокотехнологичного бизнеса.</p>
            <blockquote><p>Сейчас, чтобы запустить своё дело в интернете, предприниматель должен быть экспертом не
              только в маркетинге, но и в программировании. Когда в 2012-м году я пришёл в интернет-бизнес, мне очень
              помогло то, что имея математическое образование, я быстро смог разобраться с техническими моментами. А что
              делать тем, кто далёк от этого? И с тех пор, увы, ничего не поменялось&#8230;</p></blockquote>
            <p><strong>15 февраля 2017</strong> года в прямом эфире телеканала InfoBiz1.TV объявляет о запуске нового
              проекта, UTP1 (Universal trading platform), который представляет из себя высокотехнологичный
              инвестиционный бизнес, целью которого и является запуск данной платформы</p>
            <p><strong>30 июня 2022</strong> года получил «Красный» диплом (с отличем и средним баллом 5.0) магистра в
              области IT-технологий (Университет ИТМО, Мегафакультет компьютерных технологий и управления, Факультет
              программной инженерии и компьютерной техники). Во время обучения выступал на конференциях и является
              победителем в номинации «За лучший доклад магистранта» на Конгрессе молодых учёных</p>
            <hr/>
            <h3>Семья</h3>
            <p>Во время одного из путешествий, в конце августа 2012 года, познакомился с девушкой (Тунис,
              непосредственно в Средеземном море), и как оказалось, она тоже была из Санкт-Петербурга</p>
            <p>По возвращению в родной город продолжили общение, 1 января 2013 года было сделано предложение, и вскоре
              поженились, свадьба была <strong>1 марта 2013 года</strong></p>
            <p><strong>4 апреля 2015 года</strong> родился первенец, сын Николай</p>
            <p><strong>2 февраля 2017 года</strong> родилась дочка, Мелания</p>
            <hr/>
            <h3>Хобби и увлечения</h3>
            <p>В 1996 году (7 лет) родители впервые отвезли на море, и с тех пор Пётр очень <strong>любит
              путешествовать</strong>. За это время он посетил многие страны мира, совмещая работу и хобби. В одном из
              <a href="https://youtu.be/4uUS8YyDjvg" target="_blank">видео</a>, записанных в Норвегии, Пётр признался,
              что путешествуя, он тратит меньше денег, чем зарабатывает, но большую часть времени всё-таки отдыхает.</p>
            <p>Во время первого курса университета (2007 / 2008) регулярно прогуливал физкультуру, и, чтобы получить
              зачёт, вынужден был отрабатывать пропущенные пары. Среди предложенных вариантов была тяжелая атлетика,
              тогда-то Пётр и начал заниматься <strong>Пауэрлифтинг</strong>ом и <strong>Бодибилдинг</strong>ом
              (некоторые записи с тренировок даже попали в его фильмы). Так же перед армией занимался Дзюдо, после армии
              перешёл на <strong>Тайский Бокс</strong>.</p>
            <p>В школьные годы увлекался музыкой, играл на скрипке (даже отучился 6 лет в музыкальной школе), в старших
              классах был вокалистом молодёжной рок-группы. Выступал в школьном, а позже факультетском театре. Сейчас
              любит смотреть кино, <strong>читать книги</strong> и играть в шахматы, а музыку обычно слушает только за
              рулём.</p>
            <p>Имея возможность ездить с водителем, предпочитает самостоятельно управлять машиной, объясняя это тем, что
              <strong>любит водить автомобиль</strong>, а будучи пассажиром чувствует себя не так комфортно, так как не
              может контролировать ситуацию. И хотя у него автомобиль, который достаточно часто используют с водителем,
              ключи от него никому не даёт, и за руль не пускает.</p>
            <hr/>
            <h3>Достижения и образование:</h3>
            <ol>
              <li><strong>Победитель и финалист</strong> различных отечественных и зарубежных интеллектуальных олимпиад
                и конкурсов, в том числе является победителем в номинации «За лучший доклад магистранта» на Конгрессе
                молодых учёных;
              </li>
              <li><strong>в 2011 году стал ветераном боевых действий</strong> (21 год) и с тех пор уже получает «пенсию»
                (ЕДВ) и имеет ряд льгот от Государства;
              </li>
              <li>в 2014 году получил диплом<strong> специалист</strong>а<strong> в области
                физико-математических </strong>образовательных<strong> наук</strong> и тогда же поступил в магистратуру;
              </li>
              <li>в 2022 году получил <strong>«Красный» диплом магистра с отличием и средним баллом 5.0</strong> в
                области IT-технологий;
              </li>
              <li>успешно прошёл обучение у ряда отечественных и зарубежных маркетологов, заочно прошёл курс miniMBA;
              </li>
              <li>является практиком, <strong>постоянно учится и внедряет</strong> в свою жизнь и бизнес новые
                полученные знания;
              </li>
              <li>женился в 23 года, <strong>в 25 лет стал отцом</strong> (сын Николай), в 27 лет стал дважды отцом
                (дочка Мелания);
              </li>
              <li>в 23 года самостоятельно заработал на новую иномарку (с одного запуска), в 26 лет поменял личный
                автомобиль на люксовую иномарку (Mercedes-Benz S500 4matic).
              </li>
            </ol>
            <h4><img class="size-thumbnail wp-image-115 alignleft"
                     src="../assets/images/about/mercedes-200x112.jpeg" alt="" width="200"
                     height="112"
                     srcset="../assets/images/about/mercedes-200x112.jpeg 200w, ../assets/images/about/mercedes-450x253.jpeg 450w"
                     sizes="(max-width: 200px) 100vw, 200px"/>Mercedes-Benz S500 4matic</h4>
            <p><em>У меня полноприводный Мерседес с объёмом двигателя 5,5 литра (да, это тот самый легендарный огромный
              люксовый седан, который даже не дают в тест-драйв, потому что это флагман, берущий сотню за 5 секунд)</em>
            </p>
            <h4><img class="alignright size-thumbnail wp-image-116"
                     src="../assets/images/about/travels-200x112.jpeg" alt="" width="200"
                     height="112"
                     srcset="../assets/images/about/travels-200x112.jpeg 200w, ../assets/images/about/travels-450x253.jpeg 450w"
                     sizes="(max-width: 200px) 100vw, 200px"/>Посетил многие страны мира</h4>
            <p><em>Я занимался своим делом во многих странах мира (Россия, Финляндия, Болгария, Германия, Польша,
              Швеция, Тунис, Эстония, Египет, Латвия, Норвегия, Дания, и другие), сотрудничал с США и
              Великобританией</em></p>
            <blockquote>
              <p class="pt-5 pb-5 text-light text-center" style="text-align: left;">И это далеко не всё&#8230; Когда я
                куда-то лечу, я лечу бизнес-классом, когда я иду в ресторан, я иду в лучший ресторан, а посещая
                мероприятия, я покупаю V.I.P. места. И говорю я Вам сейчас об этом не для того, чтобы похвастаться, а
                чтобы показать, что Вы так тоже можете. Мне нравится такая жизнь, и я Вас жду в ней!</p>
            </blockquote>

          </div><!-- .entry-content -->
        </div>
      </div><!-- .utp-wrap -->
    </div><!-- .container -->
  </main>
</template>

<script>
import HeaderUtp1 from "@/components/HeaderUtp1";

export default {
  name: "about",
  components: {HeaderUtp1}
}
</script>

<style scoped>

</style>