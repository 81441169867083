<template>
  <section class="section s-bg-grey pb-0" id="section-6">
    <div class="container">
      <header class="section-header">
        <h2>Бонусы для всех вариантов участия:</h2>
      </header><!-- .section-header -->
      <div class="card card-p">
        <div class="row">
          <div class="col-sm-4 col-sm-push-8 text-center-xs">
            <img src="../../../assets/images/invest/products/utp1_bonus_1.png" alt="Product Image" class="img-responsive">
          </div>
          <div class="col-sm-8 col-sm-pull-4">
            <h2>Своё дело на партнёрках<br> [V.I.P. версия]</h2>
            <ul class="list-icon">
              <li>От 50&nbsp;000 рублей в месяц уже через 14 дней</li>
              <li>Своё собственное дело без создания продуктов</li>
              <li>Для получения результата требуется минимум усилий</li>
              <li>Возможность зарабатывать до 300&nbsp;000 рублей</li>
              <li>ВАЖНО! Обучение своему делу, а не заработку</li>
            </ul>
          </div>
        </div><!-- .row -->
        <hr class="hr-special">
        <div class="row">
          <div class="col-sm-4 text-center-xs">
            <img src="../../../assets/images/invest/products/utp1_bonus_2.png" alt="Product Image" class="img-responsive">
          </div>
          <div class="col-sm-8">
            <h2>Интенсив по старту своего дела<br> [полная версия]</h2>
            <ul class="list-icon">
              <li>11 часов информации БЕЗ воды</li>
              <li>Детальный разбор бизнес-моделей</li>
              <li>Линейка продуктов и воронка продаж</li>
              <li>Эксклюзивная информация по привлечению</li>
              <li>BONUS: Основные тренды</li>
            </ul>
          </div>
        </div><!-- .row -->
        <hr class="hr-special">

        <div class="row">
          <div class="col-sm-4 col-sm-push-8 text-center-xs">
            <img src="../../../assets/images/invest/products/utp1_bonus_3.png" alt="Product Image" style="height:350px;"
                 class="img-responsive">
          </div>
          <div class="col-sm-8 col-sm-pull-4">
            <h2>YouTube для продвижения партнёрских программ и привлечения бесплатных подписчиков</h2>
            <ul class="list-icon">
              <li>Какие видео записывать, чтобы получать трафик</li>
              <li>Как оптимизировать видео, чтобы они выходили в ТОП</li>
              <li>О чем говорить в видео, чтобы они приносили доход</li>
              <li>Секретные фишки быстрого выхода в ТОП</li>
              <li>Секреты быстрого набора подписчиков</li>
            </ul>
          </div>
        </div><!-- .row -->
        <hr class="hr-special">
        <div class="row">
          <div class="col-sm-4 text-center-xs">
            <img src="../../../assets/images/invest/products/utp1_bonus_4.png" style="height:350px;" alt="Product Image"
                 class="img-responsive">
          </div>
          <div class="col-sm-8">
            <h2>Видеокурс «Инстаграм наше всё»<br>Сергей Копыленко</h2>
            <ul class="list-icon">
              <li>Видеоуроки по привлекательному оформлению</li>
              <li>Фишки быстрого набора подписчиков</li>
              <li>Видеоуроки по настройке недорогой рекламы</li>
              <li>Правильная работа с историями</li>
              <li>Как увеличивать охват постов и обходить умную ленту</li>
            </ul>
          </div>
        </div><!-- .row -->

      </div>
    </div><!-- .container -->
  </section>
</template>

<script>
export default {
  name: "invest-section-6"
}
</script>

<style scoped>

</style>