<template>
  <div class="modal fade video-modal in dialog-style modal-open" v-if="modal.show" @click="modalClose">
<!--    <button data-fancybox-close="" class="fancybox-button fancybox-button&#45;&#45;close" title="Close">
      <svg viewBox="0 0 40 40">
        <path d="M10,10 L30,30 M30,10 L10,30"></path>
      </svg>
    </button>-->
    <div @click.stop class="modal-dialog modal-lg">
      <div class="">
        <div class="modal-body video-modal">
          <div class="embed-responsive embed-responsive-16by9 embed-youtube">
            <iframe class="embed-responsive-item"
                    :src="myUrl"></iframe>
          </div><!-- .row -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {ref, watch} from "vue";

export default {
  name: "video-modal",
  props: {
    modal: Object
  },
  setup(props, {emit}) {
    const modalClose = () => {
      emit('closeModal')
    }

    const myUrl = ref("")

    watch(props.modal, () => {
      if (props.modal.url !== "") {
        myUrl.value = "https://www.youtube.com/embed/" +
            props.modal.url +
            "?autoplay=1&loop=0&showinfo=0&theme=light&color=red&controls=0&modestbranding=1&start=0&fs=0&iv_load_policy=3&wmode=transparent&rel=0"
      } else {
        myUrl.value = ""
      }
    })

    return {modalClose, myUrl}
  }
}
</script>

<style scoped>
.video-modal {
  padding: 22px 0 0 0;
  border: 0;
}
</style>