<template>
  <section class="section bg-cover s-bg-light-08 background" id="section-8">
    <div class="container">
      <header class="section-header">
        <h2>Несколько моментов, которые Вам будут интересны</h2>
      </header><!-- .section-header -->
      <blockquote><p class="h3">Вы должны понимать, что мы заинтересованы в Вашей прибыли не меньше Вашего. Мы не
        работаем с конечным пользователем. Поэтому от того, какие будут у Вас результаты, зависит то, как быстро мы
        сможем запустить систему UTP1. И, поверьте, мы сделаем всё, чтобы запустить её как можно быстрее. Это я Вам
        обещаю, Ваш Пётр Громов.</p></blockquote>
      <div class="row pt-30 pb-0">
        <div class="col-md-3 col-sm-6">
          <div class="block-icon">
            <div class="block-icon-image">
              <img src="../../../assets/images/invest/ic/ic1.png" alt="">
            </div>
            <h5 class="block-icon-title">Высокая конверсия в подписку</h5>
            <p class="block-icon-desc">Средние показатели по рынку на данный момент составляют 10&nbsp;-&nbsp;20&nbsp;%%,
              у нас этот показатель от&nbsp;30&nbsp;до&nbsp;70&nbsp;%%</p>
          </div>
        </div><!-- .col-xx-n -->
        <div class="col-md-3 col-sm-6">
          <div class="block-icon">
            <div class="block-icon-image">
              <img src="../../../assets/images/invest/ic/ic2.png" alt="">
            </div>
            <h5 class="block-icon-title">Максимальная конверсия в&nbsp;продажу</h5>
            <p class="block-icon-desc">Каждый 10-ый подписчик оформляет заказ, минимум половина из них оплачивает (и это
              средний показатель)</p>
          </div>
        </div><!-- .col-xx-n -->
        <div class="col-md-3 col-sm-6">
          <div class="block-icon">
            <div class="block-icon-image">
              <img src="../../../assets/images/invest/ic/ic3.png" alt="">
            </div>
            <h5 class="block-icon-title">Последующее взаимодействие</h5>
            <p class="block-icon-desc">После того, как будут собраны необходимые инвестиции, Вы продолжите привлекать
              пользователей в систему</p>
          </div>
        </div><!-- .col-xx-n -->
        <div class="col-md-3 col-sm-6">
          <div class="block-icon">
            <div class="block-icon-image">
              <img src="../../../assets/images/invest/ic/ic4.png" alt="">
            </div>
            <h5 class="block-icon-title">Это НЕ&nbsp;пирамида и НЕ&nbsp;сетевой бизнес</h5>
            <p class="block-icon-desc">Мы являемся классическим инвестиционным <a
                href="https://ru.wikipedia.org/wiki/Краудфандинг" target="_blank">краудфаундинговым</a> проектом, с 2-х
              уровневой партнёркой</p>
          </div>
        </div><!-- .col-xx-n -->
      </div><!-- .row -->
      <p class="text-center">
        <a href="#section-3" class="btn btn-el btn-danger btn-scroll">Стать акционером!</a>
      </p>
    </div><!-- .container -->
  </section>
</template>

<script>
export default {
  name: "invest-section-8"
}
</script>

<style scoped>
.background {
  background-image: url(../../../assets/images/invest/bg/bg2.jpg);
}
</style>