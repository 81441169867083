<template>
  <div class="modal fade utp-modal-2 in dialog-style modal-open" v-if="show" @click="modalClose">
    <div @click.stop class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <button @click="modalClose" type="button" class="close" data-dismiss="modal">
            <span>&times;</span>
          </button>
          <h3 class="modal-title text-danger">UTP1 для начинающих предпринимателей</h3>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-4 col-sm-12">
              <div class="block-icon">
                <div class="block-icon-image">
                  <img src="../../../../assets/images/utp1/ic/2_1.png" alt="">
                </div>
                <h5 class="block-icon-title">Создайте landing</h5>
                <p class="block-icon-desc">Даже не имея технических знаний Вы сможете сделать сайт или страницу за
                  вечер</p>
              </div>
            </div><!-- .col-xx-n -->
            <div class="col-md-4 col-sm-12">
              <div class="block-icon">
                <div class="block-icon-image">
                  <img src="../../../../assets/images/utp1/ic/2_2.png" alt="">
                </div>
                <h5 class="block-icon-title">Настройте маркетинг</h5>
                <p class="block-icon-desc">В систему интегрированы маркетинговые решения повышающие конверсию</p>
              </div>
            </div><!-- .col-xx-n -->
            <div class="col-md-4 col-sm-12">
              <div class="block-icon">
                <div class="block-icon-image">
                  <img src="../../../../assets/images/utp1/ic/2_3.png" alt="">
                </div>
                <h5 class="block-icon-title">Нет ограничений</h5>
                <p class="block-icon-desc">С нашей помощью Вы можете реализовывать практически любые товары и услуги</p>
              </div>
            </div><!-- .col-xx-n -->
          </div><!-- .row -->
          <div class="row">
            <div class="col-md-4 col-sm-12">
              <div class="block-icon">
                <div class="block-icon-image">
                  <img src="../../../../assets/images/utp1/ic/2_4.png" alt="">
                </div>
                <h5 class="block-icon-title">Наши сотрудники</h5>
                <p class="block-icon-desc">Вам не надо сразу нанимать сотрудников, Вы можете использовать наши
                  ресурсы</p>
              </div>
            </div><!-- .col-xx-n -->
            <div class="col-md-4 col-sm-12">
              <div class="block-icon">
                <div class="block-icon-image">
                  <img src="../../../../assets/images/utp1/ic/2_5.png" alt="">
                </div>
                <h5 class="block-icon-title">Принимайте платежи</h5>
                <p class="block-icon-desc">Вам не надо заботиться о подключении способов оплаты, всё уже подключено</p>
              </div>
            </div><!-- .col-xx-n -->
            <div class="col-md-4 col-sm-12">
              <div class="block-icon">
                <div class="block-icon-image">
                  <img src="../../../../assets/images/utp1/ic/2_6.png" alt="">
                </div>
                <h5 class="block-icon-title">Службы доставки</h5>
                <p class="block-icon-desc">Мы работаем с различными службами доставки, чтобы Вам было проще и
                  дешевле</p>
              </div>
            </div><!-- .col-xx-n -->
          </div><!-- .row -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "modal-2",
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  setup(_, {emit}) {
    const modalClose = () => {
      emit('update:show', false)
    }
    return {modalClose}
  }
}
</script>

<style scoped>

</style>