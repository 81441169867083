<template>
  <div class="modal fade utp-modal-5 in dialog-style modal-open" v-if="show" @click="modalClose">
    <div @click.stop class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <button @click="modalClose" type="button" class="close" data-dismiss="modal">
            <span>&times;</span>
          </button>
          <h3 class="modal-title text-danger">UTP1 для инвесторов, партнёров и участников MLM</h3>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-4 col-sm-12">
              <div class="block-icon">
                <div class="block-icon-image">
                  <img src="../../../../assets/images/utp1/ic/5_1.png" alt="">
                </div>
                <h5 class="block-icon-title">Свой MLM бизнес</h5>
                <p class="block-icon-desc">В системе UTP1 реализована возможность создания полноценного MLM-бизнеса</p>
              </div>
            </div><!-- .col-xx-n -->
            <div class="col-md-4 col-sm-12">
              <div class="block-icon">
                <div class="block-icon-image">
                  <img src="../../../../assets/images/utp1/ic/5_2.png" alt="">
                </div>
                <h5 class="block-icon-title">Тысячи партнёрок</h5>
                <p class="block-icon-desc">У нас Вы можете в несколько кликов создать своё дело на партнёрских
                  программах</p>
              </div>
            </div><!-- .col-xx-n -->
            <div class="col-md-4 col-sm-12">
              <div class="block-icon">
                <div class="block-icon-image">
                  <img src="../../../../assets/images/utp1/ic/5_3.png" alt="">
                </div>
                <h5 class="block-icon-title">Инвестиции</h5>
                <p class="block-icon-desc">Мы проверяем безопасность инвестиционных проектов и составляем рейтинг
                  доходов</p>
              </div>
            </div><!-- .col-xx-n -->
          </div><!-- .row -->
          <div class="row">
            <div class="col-md-4 col-sm-12">
              <div class="block-icon">
                <div class="block-icon-image">
                  <img src="../../../../assets/images/utp1/ic/5_4.png" alt="">
                </div>
                <h5 class="block-icon-title">Простое управление</h5>
                <p class="block-icon-desc">Вы можете управлять своим заработком из любой точки мира с помощью
                  смартфона</p>
              </div>
            </div><!-- .col-xx-n -->
            <div class="col-md-4 col-sm-12">
              <div class="block-icon">
                <div class="block-icon-image">
                  <img src="../../../../assets/images/utp1/ic/5_5.png" alt="">
                </div>
                <h5 class="block-icon-title">Прозрачные расчёты</h5>
                <p class="block-icon-desc">Вы видите свои доходы, и в любой момент можете бесплатно вывести деньги</p>
              </div>
            </div><!-- .col-xx-n -->
            <div class="col-md-4 col-sm-12">
              <div class="block-icon">
                <div class="block-icon-image">
                  <img src="../../../../assets/images/utp1/ic/5_6.png" alt="">
                </div>
                <h5 class="block-icon-title">Подключение</h5>
                <p class="block-icon-desc">Подключайтесь и начните зарабатывать в интернете без вложений и рисков</p>
              </div>
            </div><!-- .col-xx-n -->
          </div><!-- .row -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "modal-5",
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  setup(_, {emit}) {
    const modalClose = () => {
      emit('update:show', false)
    }
    return {modalClose}
  }
}
</script>

<style scoped>

</style>