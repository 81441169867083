<template>
  <section class="section bg-cover s-bg-light-07 background" id="section-5">
    <div class="container">
      <div class="container-md">
        <img src="../../../assets/images/invest/other/100.png" class="img-responsive alignnone" alt="">
      </div><!-- .container-md -->
      <h4 class="pt-30 pb-30">Мы Вам гарантируем, что передадим все необходимые инструменты (готовые для использования)
        и наработки (обучающие материалы) в области построения своего дела в интернете. Так же гарантируем, что Вы
        получите результат (разумеется, при условии выполнения необходимых действий). И, безусловно, гарантируем возврат
        денег в соответствии с Законодательством (Закон РФ от 07.02.1992 N 2300-1 «О защите прав потребителей») и
        Договором Публичной Оферты</h4>
      <h2 class="text-danger text-center">Так что Вы в любом случае ничем не рискуете!</h2>
    </div><!-- .container -->
    <!-- .container -->
  </section>
</template>

<script>
export default {
  name: "invest-section-5"
}
</script>

<style scoped>
.background {
  background-image: url(../../../assets/images/invest/bg/bg1.jpg);
}
</style>