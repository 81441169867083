<template>
  <div class="utp-home-bio-header">
    <a class="utp-home-bio-notify non-click" disabled>
      <span class="icon-alarm-clock"></span>
      <span class="badge">test</span>
    </a>
    <a class="utp-home-bio-wallet non-click" disabled>
      <i class="icon-wallet"></i>
      <span class="wallet-value">0,0</span>
      <span class="icon-rub">₽</span>
    </a>
  </div>
  <div class="utp-home-bio-body">
    <!--suppress JSUnresolvedVariable -->
    <h5>Здравствуйте, {{ $store.state.auth.name }}</h5>
    <div class="utp-home-bio-gravatar">
      <!--suppress JSUnresolvedVariable -->
      <img v-if="$store.state.avatar.avatar"
           :src="'data:image;base64,' + $store.state.avatar.avatar"
           :alt="$store.state.auth.name">
      <!--suppress JSUnresolvedVariable -->
      <img v-else src="../../../assets/images/gravatar.jpg" :alt="$store.state.auth.name">
    </div>
    <a @click="personalArea" class="btn btn-danger btn-lg btn-block">Личный кабинет</a>
    <a class="btn btn-primary btn-lg btn-block non-click" disabled>Кабинет партнера</a>
  </div>
  <div class="utp-home-bio-footer">
    <a @click="logout">Выйти</a>
  </div>
</template>

<script>
import store from "@/store";
import {onMounted, ref} from "vue";
import useTokens from "@/hooks/useTokens";
import useImages from "@/hooks/rest/useImages";

export default {
  name: "auth-form",
  setup() {
    const name = ref(store.state.auth.name)

    const personalArea = () => {
      window.location.href = `https://admin.utp1.com`
    }

    const {getSettingsAvatar} = useImages()
    const {logout} = useTokens()

    onMounted(getSettingsAvatar)

    return {name, personalArea, logout}
  }
}
</script>

<style scoped>

</style>